import axios from 'axios';
import { ApiUrl } from '../services/ApiRest';
import React, { Component } from 'react'
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from 'react-router-dom'
import Cookies from 'universal-cookie';

const cookie = new Cookies();
const urlAdministrativo = ApiUrl + "admins/"; //pasar id del estudiante
const idAdministrativo = cookie.get("idAdministrativo");    //declaramos una variable para usar la cookie del login


export default class SidebarDirectorTitulacion extends Component {

    componentDidMount() {
        // Get que devuelve los datos de un estudiante recibiendo un id
        axios.get(urlAdministrativo + idAdministrativo + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71")
            .then(res => {
                const estudiante = res.data;
                this.setState({ estudiante });
                //console.log(estudiante);
                cookie.set('nombres', estudiante.nombres_administrativo, { path: "/" })
                cookie.set('apellidos', estudiante.apellidos_administrativo, { path: "/" })
            })
    }
    render() {
        return (

            <div id="layoutSidenav_nav" style={{ width: 226 }}>
                <nav className="sb-sidenav accordion sb-sidenav-dark animate__animated animate__fadeInLeft" id="sidenavAccordion">
                    <div className="sb-sidenav-menu">
                        <div className="nav">
                            <div className="sb-sidenav-menu-heading">PERSONAL DE DIRECTOR DE TITULACIÓN</div>
                            <Link to="/vicerrectoradoadm">
                                <div className="nav-link movil" href="#">
                                    <div className="sb-nav-link-icon">
                                        <i className="fas fa-university"></i>
                                    </div>
                                    <b>Página Principal </b>
                                </div>
                            </Link>
                            {/* Opciones del Director de titulación*/}
                            {/* Tipos de titulación*/}
                            <div className="nav-link collapsed puntero" href="#" data-toggle="collapse" data-target="#collapseTiposTitulacion" aria-expanded="false" aria-controls="collapseLayouts">
                                <div className="sb-nav-link-icon">
                                    <i className="fas fa-graduation-cap"></i>                                        </div>
                                TIPOS DE TITULACION
                                <div className="sb-sidenav-collapse-arrow">
                                    <i className="fas fa-angle-down"></i>
                                </div>
                            </div>
                            <div className="collapse" id="collapseTiposTitulacion" aria-labelledby="headingOne" data-parent="#sidenavAccordion">
                                <nav className="sb-sidenav-menu-nested nav">
                                    <Link to="/directortitulacion/tipostitulacion">  <div className="nav-link movil" href="#">Gestión de tipos de titulación</div></Link>
                                </nav>
                            </div>
                                {/*Estados del proceso de titulacion*/}
                                <div className="nav-link collapsed puntero" href="#" data-toggle="collapse" data-target="#collapseEstados" aria-expanded="false" aria-controls="collapseLayouts">
                                <div className="sb-nav-link-icon">
                                    <i className="fas fa-graduation-cap"></i>                                        </div>
                                ESTADOS DE LOS DOCUMENTOS
                                <div className="sb-sidenav-collapse-arrow">
                                    <i className="fas fa-angle-down"></i>
                                </div>
                            </div>
                            <div className="collapse" id="collapseEstados" aria-labelledby="headingOne" data-parent="#sidenavAccordion">
                                <nav className="sb-sidenav-menu-nested nav">
                                    <Link to="/directortitulacion/estadosdocumenttitu">  <div className="nav-link movil" href="#">Gestión de estados del documentos</div></Link>
                                </nav>
                            </div>
                              {/* Gestión de titulación*/}
                              <div className="nav-link collapsed puntero" href="#" data-toggle="collapse" data-target="#collapseAsignarTutorEst" aria-expanded="false" aria-controls="collapseLayouts">
                                <div className="sb-nav-link-icon">
                                    <i className="fas fa-graduation-cap"></i>                                        </div>
                               GESTIÓN DE TITULACIÓN
                                <div className="sb-sidenav-collapse-arrow">
                                    <i className="fas fa-angle-down"></i>
                                </div>
                            </div>
                            <div className="collapse" id="collapseAsignarTutorEst" aria-labelledby="headingOne" data-parent="#sidenavAccordion">
                                <nav className="sb-sidenav-menu-nested nav">
                                    <Link to="/directortitulacion/asigtutorestud">  <div className="nav-link movil" href="#">Asignar tutor-estudiante</div></Link>
                                </nav>
                            </div>
                              {/* Gestión de calificaciones*/}
                              <div className="nav-link collapsed puntero" href="#" data-toggle="collapse" data-target="#collapseTiposCalificaciones" aria-expanded="false" aria-controls="collapseLayouts">
                                <div className="sb-nav-link-icon">
                                    <i className="fas fa-graduation-cap"></i>                                        </div>
                               GESTIÓN DE CALIFICACIONES
                                <div className="sb-sidenav-collapse-arrow">
                                    <i className="fas fa-angle-down"></i>
                                </div>
                            </div>
                            <div className="collapse" id="collapseTiposCalificaciones" aria-labelledby="headingOne" data-parent="#sidenavAccordion">
                                <nav className="sb-sidenav-menu-nested nav">
                                    <Link to="/directortitulacion/tiposcalificaciones">  <div className="nav-link movil" href="#">Tipos de Calificaciones</div></Link>
                                </nav>
                            </div>
                        </div>
                    </div>
                    <div className="sb-sidenav-footer">
                        <div className="small">Usuario:</div>
                        {cookie.get("nombres") + " "}
                        {cookie.get("apellidos")}
                    </div>
                </nav>
            </div>
        )
    }
}

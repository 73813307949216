import axios from 'axios';
import React, { Component } from 'react'
import swal from 'sweetalert';
//import Cookies from 'universal-cookie';
import $ from 'jquery';
import Cookies from 'universal-cookie';
import 'bootstrap/dist/css/bootstrap.min.css';
import { ApiUrl } from '../../services/ApiRest';
import { Table, Button, Container, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup } from 'reactstrap';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';

const cookie = new Cookies();
const baseUrlListarperiodosmatriculadosest = ApiUrl + "listarperiodosmatriculadosest" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const mostrarasignaturasmatriperioestdUrl = ApiUrl + "mostrarasignaturasmatriperiodest" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const baseUrlcarrera = ApiUrl + "listarcarrerasmatriculaestud" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const urlDetalleCursoEst = ApiUrl + "detallcursoest" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const rutaimagen = ApiUrl + "mostarrutaimagencomprobantecolec" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const urlDatosEst = ApiUrl + "datosestudiante" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";

//listar estudiantes matriculados

export default class ListarAsignaturasMatricu extends Component {
    constructor(props) {
        super(props);
        this.state = {
            estudiantes: [],
            listperiodosmatestud: [],
            listasignaturasmatestudperiod: [],
            idCarrera: "",
            nivelEstu: "",
            carrera: [],
            carreramat: [],
            form1: {

            },
            nombreDocente: "",
            fechinicioasig: "",
            fechfinasig: "",
            detall: "",
            ruta: "",


        };
        this.handleChange = this.handleChange.bind(this);
        this.escFunction = this.escFunction.bind(this);
    }

    escFunction(event) {
        if (event.key === "Escape") {
            this.setState({ modalDetalleCurso: false });
        }
    }

    handleChange = async (e) => {
        await this.setState({ [e.target.name]: e.target.value })

    }

    /***********************SELECCIONA  UNA CARRERA-***** */
    handleChangeCarrera = async (e) => {
        await this.setState({ [e.target.name]: e.target.value })
        //listar periodos en los que el estudiante se ha matriculado
        axios
            .post(baseUrlListarperiodosmatriculadosest, {
                idEstudiantein: cookie.get("idEstudiante"),
                idCarrerae: this.state.idCarrera,
            })
            .then(response => {
                const listperiodosmatestud = response.data.data;
                this.setState({ listperiodosmatestud });
                const cant = listperiodosmatestud.length;
                if (listperiodosmatestud == 0) {

                }
            })
    }


    /*********PERIODO**** */

    handleChangePeriodo = async (e) => {
        await this.setState({ [e.target.name]: e.target.value })
        //listar  las materias
        axios
            .post(mostrarasignaturasmatriperioestdUrl, {
                idEstudiante: cookie.get("idEstudiante"),
                idCarrerae: this.state.idCarrera,
                nivelEstue: this.state.nivelEstu,
                idPeriodoM: this.state.idPeriodoMatri

            })
            .then(response => {
                const listasignaturasmatestudperiod = response.data.data;


                const cant = listasignaturasmatestudperiod.length;
                if (cant != 0) {
                    this.setState({ listasignaturasmatestudperiod });
                }

                else {
                }
            })
    }

    //listar estudiantes matriculados 
    componentDidMount = async (e) => {
        document.addEventListener("keydown", this.escFunction, true);
        axios
            .post(urlDatosEst, {
                idEstudiante: cookie.get("idEstudiante"),
            })
            .then(response => {
                ;
                this.setState({ carreramat: response.data.data });
            })
    }

    //Detalle del curso
    ocultarModDetalleCurso = () => {
        this.setState({ modalDetalleCurso: false });
    }

    mostrarModDetalleCurso = (datos) => {
        axios
            .post(urlDetalleCursoEst, {
                idAsignatura: datos.id_asignatura,
                idPeriodoSelect: this.state.idPeriodoMatri,
                idParalelo: datos.paralelo

            })
            .then(response => {
                const detallecurso = response.data.data;
                const cant = detallecurso.length;

                if (cant != 0) {
                    axios.post(rutaimagen, {

                        nombrecomprobante: "usuario/" + detallecurso[0].imagen_perfil_administrativo,
                    })
                        .then(res => {
                            const ruta = res.data.data;
                            this.setState({ ruta });
                        })

                    const nombreDoc = detallecurso[0].nombres_administrativo + " " + detallecurso[0].apellidos_administrativo;
                    const fechainicioasig = detallecurso[0].fecha_inicio_asignatura;
                    const fechafinasig = detallecurso[0].fecha_fin_asignatura;
                    const detalle = detallecurso[0].detalle;

                    this.setState({ nombreDocente: nombreDoc });
                    this.setState({ fechinicioasig: fechainicioasig });
                    this.setState({ fechfinasig: fechafinasig });
                    this.setState({ detall: detalle });
                }
                else {
                    this.setState({ nombreDocente: "" });
                    this.setState({ fechinicioasig: "" });
                    this.setState({ fechfinasig: "" });
                    this.setState({ detall: "" });
                    this.setState({ ruta: "" });

                }
            })
        this.setState({ modalDetalleCurso: true, form1: datos });
    }
    convertirDosDecimales = (nota) => {
        return (nota.toFixed(2))
    }
    render() {
        return (
            <div className="row animate__animated animate__fadeIn">
                <div className="card mb-4">
                    <div className="card-header">
                        ASIGNATURAS POR PERIÓDO
                    </div>
                    <div className="card-body">
                        <div className="table-responsive">
                            <div className="row ">
                                <div className="col-12 col-sm-12 col-lg-1 col-xl-4 " >
                                    <div className="mb-3">
                                        <label className="form-label"><b> <i className="fa fa-calendar"></i> Carrera</b></label>
                                        <select className="form-select" name="idCarrera" value={this.state.value} onChange={this.handleChangeCarrera} aria-label="Default select example">
                                            <option value="undefined">Seleccione la carrera</option>
                                            {this.state.carreramat.map(person => <option key={person.id_carrera} value={person.id_carrera} > {person.descripcion_carrera}</option>)}
                                        </select>
                                    </div>
                                </div>
                                <div className="col-12 col-sm-12 col-lg-1 col-xl-4 " >
                                    <div className="mb-3">
                                        <label className="form-label"><b> <i className="fa fa-calendar"></i> Período</b></label>
                                        <select className="form-select" name="idPeriodoMatri" value={this.state.value} onChange={this.handleChangePeriodo} aria-label="Default select example">
                                            <option value="undefined">Seleccione el periódo</option>
                                            {this.state.listperiodosmatestud.map(person => <option key={person.id_periodo_academico} value={person.id_periodo_academico} > {person.descripcion_periodo_academico}</option>)}
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <table className="table  text-left"  >
                                <thead>
                                    <tr>
                                        <th>Código</th>
                                        <th>Asignatura</th>
                                        <th>#Mat</th>
                                        <th>Nivel</th>
                                        <th>Paralelo</th>
                                        <th>Información</th>
                                        <th>Parcial 1(10)</th>
                                        <th>Parcial 2(10)</th>
                                        <th>Parcial 3(10)</th>
                                        <th>Prom. Parciales(6)</th>
                                        <th>Examen Final(4)</th>
                                        <th>Promedio Acum.(10)</th>
                                        <th>Exam.Susp(10)</th>
                                        <th>Prom. Final(10)</th>
                                        <th>Estado</th>
                                        <th>Asistencia(%)</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {console.log(this.state.listasignaturasmatestudperiod)
                                    }
                                    {this.state.listasignaturasmatestudperiod.map(consola => (
                                        <tr key={consola.id_asignatura}>
                                            <td>{consola.codigo_asignatura}</td>
                                            <td>{consola.descripcion_asignatura}</td>
                                            <td>{consola.tipo_matricula_asignatura}</td>
                                            <td>{consola.nivel_asignatura}</td>
                                            <td>{consola.nombre_paralelo}</td>
                                            <td class="docente">
                                                <button onClick={() => this.mostrarModDetalleCurso(consola)} ><i class="fa fa-user-circle"></i></button>
                                            </td>
                                            <td>{this.convertirDosDecimales(consola.parcial1)}</td>
                                            <td>{this.convertirDosDecimales(consola.parcial2)}</td>
                                            <td>{this.convertirDosDecimales(consola.parcial3)}</td>
                                            <td>
                                                {(() => {

                                                    var suma = consola.parcial1 + consola.parcial2 + consola.parcial3;
                                                    var promparcial = ((suma * 6) / 30);
                                                    promparcial = promparcial.toFixed(2);
                                                    return promparcial;

                                                })()}

                                            </td>
                                            <td>
                                                {(() => {

                                                    var examenfinal = ((consola.examen_final * 4) / 10);
                                                    examenfinal = examenfinal.toFixed(2);
                                                    return examenfinal;

                                                })()}
                                            </td>
                                            <td>
                                                {(() => {

                                                    var suma = consola.parcial1 + consola.parcial2 + consola.parcial3;
                                                    var promparcial = ((suma * 6) / 30);
                                                    var promacum = promparcial + ((consola.examen_final * 4) / 10);
                                                    promacum = promacum.toFixed(2);
                                                    return promacum;

                                                })()}


                                            </td>
                                            <td>{consola.examen_suspension}</td>
                                            <td>
                                                {(() => {

                                                    var examenfinal = ((consola.examen_final * 4) / 10);

                                                    var suma = consola.parcial1 + consola.parcial2 + consola.parcial3;
                                                    var promparcial = ((suma * 6) / 30);
                                                    var promacum = promparcial + examenfinal;
                                                    var promediof = promacum.toFixed(2);

                                                    if (promacum >= 7) {
                                                        var promediof = promacum;
                                                        return promacum.toFixed(2);
                                                    }
                                                    else {
                                                        if (((promacum >= 4) && (promacum < 7)) && (consola.examen_suspension == 0)) {
                                                            var promediof = promacum;
                                                            return promacum.toFixed(2);
                                                        }
                                                        else {

                                                            if (((promacum >= 4) && (promacum < 7)) && (consola.examen_suspension > 0)) {
                                                                var promediof = (promacum + consola.examen_suspension) / 2;
                                                                return promediof.toFixed(2);
                                                            }
                                                            else {
                                                                var promediof = promacum;
                                                                return promacum.toFixed(2);
                                                            }
                                                        }
                                                    }

                                                })()}
                                            </td>
                                            <td>
                                                {(() => {
                                                    switch (consola.equivalencia) {
                                                        case 0: return "REPROBADO";
                                                        case 1: return "APROBADO";
                                                        default: return ""
                                                    }

                                                })()}
                                            </td>
                                            <td>{consola.asistencia}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>

                            {/*Modal de pago total de colegiatura*/}
                            <Modal isOpen={this.state.modalDetalleCurso} >
                                <ModalHeader>

                                </ModalHeader>
                                <ModalBody>
                                    <FormGroup>


                                        <input name="imagen" type="image" src={this.state.ruta} align="center" alt="fotografia" width='50%'
                                            height='50%' ></input>
                                        <br />
                                        <br />

                                        <div className="mb-3 centrar">
                                            <div className="row ">
                                                <div className="col-12 col-sm-12  col-lg-5 col-xl-5 centrar" >
                                                    <div className="mb-3">
                                                        <b> <label className="form-label">Docente</label> </b>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-lg-5 col-xl-5 centrar" >
                                                    <div className="mb-3">

                                                        {this.state.nombreDocente}

                                                    </div>
                                                </div>
                                            </div>


                                            <div className="row ">
                                                <div className="col-12 col-sm-12  col-lg-5 col-xl-5 centrar" >
                                                    <div className="mb-3">
                                                        <b> <label className="form-label">Fecha Inicio</label> </b>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-lg-5 col-xl-5 centrar" >
                                                    <div className="mb-3">

                                                        {this.state.fechinicioasig}

                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row ">
                                                <div className="col-12 col-sm-12  col-lg-5 col-xl-5 centrar" >
                                                    <div className="mb-3">
                                                        <b> <label className="form-label">Fecha Fin</label> </b>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-lg-5 col-xl-5 centrar" >
                                                    <div className="mb-3">

                                                        {this.state.fechfinasig}

                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row ">
                                                <div className="col-12 col-sm-12  col-lg-5 col-xl-5 centrar" >
                                                    <div className="mb-3">
                                                        <b> <label className="form-label">Detalle</label> </b>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-lg-5 col-xl-5 centrar" >
                                                    <div className="mb-3">

                                                        {this.state.detall}

                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </FormGroup>
                                </ModalBody>
                                <ModalFooter>
                                    <button class="btn btn-outline-primary" onClick={this.ocultarModDetalleCurso}>Salir</button>
                                </ModalFooter>
                            </Modal>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

import axios from 'axios';
import ReactPaginate from 'react-paginate'
import React, { Component, useState } from 'react'
import "../../services/ApiRest";
import swal from 'sweetalert';
import { ApiUrl } from '../../services/ApiRest';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import { Modal, ModalHeader, ModalBody, ModalFooter, FormGroup } from 'reactstrap';


const urlListarSedes = ApiUrl + "listarsedes" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const urlListarestud = ApiUrl + "listestudmatriperiodcoo" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const urlListPeriodos = ApiUrl + "listperiodostodos" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const urlListarCarreras = ApiUrl + "listarcarreras" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const urlInfoCarrera = ApiUrl + "infocarrselect" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const urlImagenestudiante = ApiUrl + "mostarrutaimagenestud" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";


let listaauxiliar = [];
const itemsPerPage = 10;

export default class FotografiascarnetEst extends Component {

    constructor(props) {
        super(props)

        this.state = {
            carrera: [],
            sedes: [],
            estud: [],
            listaniveles: [],
            EstudianteBackup: [],
            textBuscar: "",
            descripCarrE: "",
            estado: "",
            listaperiodos: [],
            listasignaturas: [],
            listaEstCoord: [],
            nombrescompletos: "",
            //variables para paginación
            currentItems: null,
            pageCount: 0,
            itemOffset: 0,
            count: 0,
        }
        this.handleChange = this.handleChange.bind(this);
        this.escFunction = this.escFunction.bind(this);
    }
    escFunction(event) {
        if (event.key === "Escape") {
            this.setState({ modalVisualizarImagenEst: false });
        }
    }
    handleChange = async (e) => {
        await this.setState({ [e.target.name]: e.target.value })
        this.setState({ estado: "" });
    }

    componentDidMount() {
        document.addEventListener("keydown", this.escFunction, true);
        axios
            .get(urlListPeriodos)
            .then(response => {
                const listaperiodos = response.data.data;
                const cant = listaperiodos.length;
                if (cant != 0) {
                    this.setState({ listaperiodos });
                }
            })

        axios.get(urlListarSedes)
            .then(respuesta => {
                const sedes = respuesta.data;
                this.setState({ sedes });
            })
        //Listar las carreras
        axios.get(urlListarCarreras)
            .then(res => {
                const carrera = res.data;
                this.setState({ carrera });
            })
    }
    //BUSQUEDA......
    filter(event) {
        console.log(event.target.value);
        //obtener datos de buscar
        var text = event.target.value
        //obtener datos del array
        const data = this.state.estud
        var newData = data.filter(function (item) {
            //variable del objeto
            const itemDataPrimerApell = item.primer_apellido_estudiante.toUpperCase()
            const itemDataPrimerNom = item.primer_nombre_estudiante.toUpperCase()
            const itemDataCedula = item.numero_identificacion_estudiante.toUpperCase()
            const campo = itemDataPrimerApell + " " + itemDataPrimerNom + " " + itemDataCedula;
            //variable del buscador
            const textData = text.toUpperCase()
            //return itemData.indexOf(textData)> -1
            return campo.indexOf(textData) > -1
        })
        if (text == "") {
            newData = this.state.EstudianteBackup;
        }
        this.setState({
            currentItems: newData,
            textBuscar: text,
        })
    }
    //FUNCION DE PAGINACION POR  MEDIO DE UN CLIC
    handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % listaauxiliar.length;
        this.setState((state) => {
            return { itemOffset: newOffset }
        });
        const endOffset = newOffset + itemsPerPage;
        this.setState({ currentItems: listaauxiliar.slice(newOffset, endOffset) });
        this.setState({ EstudianteBackup: listaauxiliar.slice(newOffset, endOffset) });
        this.setState({ pageCount: Math.ceil(listaauxiliar.length / itemsPerPage) });
        this.setState((state) => {
            return { itemOffset: newOffset }
        });
    }

    //Mostrar las asignaturas matriculadas de un estudiante
    mostrarImagen = (registro) => {
        var nombres = registro.primer_nombre_estudiante

        if (registro.segundo_nombre_estudiante != null) {
            var nombres = nombres + " " + registro.segundo_nombre_estudiante
        }
        var apellidos = registro.primer_apellido_estudiante;
        if (registro.segundo_apellido_estudiante != null) {
            apellidos = apellidos + " " + registro.segundo_apellido_estudiante
        }
        var nombrescompletos = apellidos + " " + nombres;
        this.setState({ nombrescompletos });
        axios.post(urlImagenestudiante, {
            idestudiante: registro.id_estudiante,
        })
            .then(respuesta => {
                if (respuesta.data.success == true) {
                     const ruta = respuesta.data.data;
                    this.setState({ ruta });
                }
            });
        this.setState({ modalVisualizarImagenEst: true, form: registro });
    }

    ocultarModalVisualizarImagen = () => {
        this.setState({ modalVisualizarImagenEst: false });
    }
    //Estudiantes matriculados en una coordinación
    handleChangeCoordinacion = async (e) => {
        await this.setState({[e.target.name]: e.target.value})
        axios.post(urlListarestud, {
            idPeriodoS: this.state.idPeriodoSelect,
            idCoordinacionS: this.state.idCoord,
            idCarreraS: this.state.idCarrera,
            idCicloNivelS: this.state.nivelEstu
        })
            .then(respuesta => {

                if (respuesta.data.success == true) {
                    this.setState({ listaEstCoord: respuesta.data.data });
                    listaauxiliar = respuesta.data.data;
                    const estud = respuesta.data.data;
                    this.setState((state) => {
                        return { estud: estud }
                    }
                    );
                    const EstudianteBackup = estud;

                    this.setState((state) => {
                        return { EstudianteBackup: EstudianteBackup }
                    });
                    //PAGINACION
                    this.state.itemOffset = 0;
                    const endOffset = this.state.itemOffset + itemsPerPage;
                    this.setState({ currentItems: listaauxiliar.slice(this.state.itemOffset, endOffset) });
                    this.setState({ EstudianteBackup: listaauxiliar.slice(this.state.itemOffset, endOffset) });
                    this.setState({ pageCount: Math.ceil(listaauxiliar.length / itemsPerPage) });
                    this.setState((state) => {
                        return { itemOffset: state.itemOffset + endOffset }
                    });
                    //FIN  D DE LA PAGINACION
                } else {

                    this.setState({ listaEstCoord: [] });
                    this.setState({ currentItems: null });
                    this.setState({ error: "No existe estudiantes" });
                    swal({
                        text: "¡No existe estudiantes matriculados en la coordinación seleccionada!",
                        icon: "info",
                        button: "Aceptar",
                    });
                }
            })
    }

    handleChangeSelectNivel = async (e) => {
        await this.setState({ [e.target.name]: e.target.value })
        axios
            .post(urlInfoCarrera, {
                idCarreraS: this.state.idCarrera
            })
            .then(response => {
                const codigocarrest = response.data.objcarrea[0].id_carrera;
                const descripcarrest = response.data.objcarrea[0].descripcion_carrera;
                const nivelcarrest = response.data.objcarrea[0].num_niveles;
                this.setState({ codigoCarrE: codigocarrest });
                this.setState({ descripCarrE: descripcarrest });
                this.setState({ numnivelE: nivelcarrest });
                let listaniveles1 = []
                for (let i = 1; i <= nivelcarrest; i++) {
                    let fila = {
                        codnivel: i,
                        valor: i
                    };
                    listaniveles1.push(fila);
                }
                this.setState({ listaniveles: listaniveles1 });
            })
    }
    render() {
        return (
            <div className="row animate__animated animate__fadeIn">
                <ol className="breadcrumb mb-4">
                    <li className="breadcrumb-item active">{ }</li>
                </ol>
                <div className="card shadow p-3 mb-5 bg-white rounded">
                    <div className="card-header back-istmas">
                        <i className="far fa-sticky-note"></i>
                        <b>-Matrículas por Coordinación </b>
                    </div>
                    <div className="card-body">
                        <div class="row">
                            <div className="col-12 col-sm-12 col-lg-1 col-xl-3 " >
                                <div className="mb-3">
                                    <label className="form-label"><b> <i className="fa-magnifying-glass"></i> Buscar:</b></label>
                                    <input type="text" class="form-control col-md-12" value={this.state.text} placeholder="Buscar..." onChange={(text) => this.filter(text)} />
                                </div>
                            </div>
                        </div>
                        <div class="row">

                            <div className="col-12 col-sm-12 col-lg-1 col-xl-3 " >
                                <div className="mb-3">
                                    <label className="form-label"><b> <i className="fa fa-calendar"></i> Período</b></label>
                                    <select className="form-select" name="idPeriodoSelect" id="idPeriodoSelect" value={this.state.value} onChange={this.handleChange} aria-label="Default select example">
                                        <option value="undefined" selected="true" disabled>Seleccione el período</option>
                                        {this.state.listaperiodos.map(periodo => <option key={periodo.id_periodo_academico} value={periodo.id_periodo_academico} > {periodo.descripcion_periodo_academico}</option>)}
                                    </select>
                                </div>
                            </div>

                            <div className="col-12 col-sm-12 col-lg-1 col-xl-3 " >
                                <div className="mb-3">
                                    <label className="form-label"><b>Carrera</b></label>
                                    <select className="form-select" name="idCarrera" value={this.state.value} onChange={this.handleChangeSelectNivel} aria-label="Default select example">
                                        <option value="undefined" selected="true" disabled>Seleccione una carrera</option>
                                        {this.state.carrera.map(person => <option key={person.id_carrera} value={person.id_carrera} > {person.descripcion_carrera}</option>)}
                                    </select>
                                </div>
                            </div>
                            <div className="col-12 col-sm-12 col-lg-1 col-xl-3 ">
                                <div className="mb-3">
                                    <label className="form-label"><b>Nivel</b></label>
                                    <select className="form-select" id="nivelEstu" name="nivelEstu" value={this.state.value} onChange={this.handleChange} aria-label="Default select example">
                                        <option value="undefined" selected="true" disabled>Seleccione </option>
                                        {this.state.listaniveles.map(person => <option key={person.codnivel} value={person.codnivel} > {person.valor}</option>)}
                                    </select>
                                </div>
                            </div>

                            <div className="col-12 col-sm-12 col-lg-1 col-xl-3 ">
                                <div className="mb-3">
                                    <label className="form-label"><b>Coordinación</b></label>
                                    <select className="form-select" id="idCoord" name="idCoord" value={this.state.value} onChange={this.handleChangeCoordinacion} aria-label="Default select example">
                                        <option value="undefined" selected="true" disabled>Seleccione una sede</option>
                                        {this.state.sedes.map(person => <option key={person.id_sede} value={person.id_sede} > {person.descripcion_sede}</option>)}
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="row ">
                            <div className="col-12 col-sm-12 col-lg-11 col-xl-11 centrar" >
                                <div className="card text-center ">
                                    <div className="card-header ">
                                        ESTUDIANTE
                                    </div>
                                    <div className="card-body centrar " style={{ width: "100%" }}>
                                        <div class="table-responsive">
                                            <table className="table  text-left"  >
                                                <thead >
                                                    <tr >
                                                        <th scope="col">Cédula</th>
                                                        <th scope="col">Primer Apellido</th>
                                                        <th scope="col">Segundo Apellido</th>
                                                        <th scope="col">Primer Nombre</th>
                                                        <th scope="col">Segundo Nombre</th>
                                                        <th scope="col">Fotografías</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.state.currentItems &&
                                                        this.state.currentItems.map((consola) => (

                                                            <tr class="list-afiliados" key={this.state.estud.id_estudiante}  >
                                                                <td >{consola.numero_identificacion_estudiante}
                                                                </td>
                                                                <td >{consola.primer_apellido_estudiante}</td>
                                                                <td>{consola.segundo_apellido_estudiante}</td>
                                                                <td>{consola.primer_nombre_estudiante}</td>
                                                                <td>{consola.segundo_nombre_estudiante}</td>
                                                                <td>
                                                                    <button onClick={() => this.mostrarImagen(consola)}  > <i className="puntero fas fa-solid fa-image" ></i></button>
                                                                </td>
                                                            </tr>
                                                        ))}
                                                </tbody>
                                            </table>
                                            <table className="table  text-left" id="tablalistcoord" name="tablalistcoord" hidden>
                                                <thead >
                                                    <tr >
                                                        <th scope="col">Cédula</th>
                                                        <th scope="col">Primer Apellido</th>
                                                        <th scope="col">Segundo Apellido</th>
                                                        <th scope="col">Primer Nombre</th>
                                                        <th scope="col">Segundo Nombre</th>
                                                        <th scope="col">Coordinación</th>

                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.state.listaEstCoord.map(consola => (
                                                        <tr class="list-afiliados" key={this.state.estud.id_estudiante}  >
                                                            <td >{consola.numero_identificacion_estudiante}
                                                            </td>
                                                            <td >{consola.primer_apellido_estudiante}</td>
                                                            <td>{consola.segundo_apellido_estudiante}</td>
                                                            <td>{consola.primer_nombre_estudiante}</td>
                                                            <td>{consola.segundo_nombre_estudiante}</td>
                                                            <td>
                                                                {consola.descripcion_sede}
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>


                                            <Modal size='sm' isOpen={this.state.modalVisualizarImagenEst}>
                                                <ModalHeader>
                                                    <div className="alert alert-success" role="alert" >
                                                        <label className="form-label">{this.state.nombrescompletos}</label><br />

                                                    </div>
                                                    <label className="form-label">{this.state.descripCarrE}</label>
                                                   
                                                </ModalHeader>
                                                <ModalBody  >
                                                    <FormGroup>
                                                        <div className="mb-3 centrar">
                                                            <input name="imagen" type="image" src={this.state.ruta} align="center" alt="Comprobante" width='100%'
                                                                height='100%' ></input>
                                                        </div>
                                                    </FormGroup>
                                                </ModalBody>
                                                <ModalFooter>
                                                    <button class="btn btn-outline-primary" onClick={this.ocultarModalVisualizarImagen}>Salir</button>
                                                </ModalFooter>
                                            </Modal>
                                        </div>

                                        <div class="table-responsive">
                                            <ReactHTMLTableToExcel
                                                id="botonExportarExcel"
                                                className="btn btn-success"
                                                table="tablalistcoord"
                                                filename="matriculascoord"
                                                sheet="Coordinación "
                                                buttonText="Exportar a Excel"
                                            />
                                        </div>

                                    </div>
                                    <div class="table-responsive">
                                        <ReactPaginate
                                            nextLabel="next >"
                                            onPageChange={this.handlePageClick}
                                            pageRangeDisplayed={5}
                                            pageCount={this.state.pageCount}
                                            marginPagesDisplayed={2}
                                            previousLabel="< previous"
                                            pageClassName="page-item"
                                            pageLinkClassName="page-link"
                                            previousClassName="page-item"
                                            previousLinkClassName="page-link"
                                            nextClassName="page-item"
                                            nextLinkClassName="page-link"
                                            breakLabel="..."
                                            breakClassName="page-item"
                                            breakLinkClassName="page-link"
                                            containerClassName="pagination"
                                            activeClassName="active"
                                            renderOnZeroPageCount={null}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <br />
                    </div>
                </div>
            </div>
        )
    }
}

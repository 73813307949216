import axios from 'axios';
import { Modal, ModalHeader, ModalBody, ModalFooter, FormGroup } from 'reactstrap';
import $ from 'jquery';
import React, { Component, useState } from 'react';
import { ApiUrl } from '../../services/ApiRest';
import "./colorbotones.css";
import ReactPaginate from 'react-paginate';
import swal from 'sweetalert';

const urlIngresarEstadoDoctTitulacion = ApiUrl + "insertarestaddocumentostitulacion" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const urlListarRoles = ApiUrl + "listarolesadm" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const urlListarTodosEstadosTitu = ApiUrl + "listarestaddocumentostitulacion" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const urlDatosEstadosDocument = ApiUrl + "datosestaddocumentostitulacion" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const urlModificarEstadoDocument = ApiUrl + "modificarestaddocumentostitulacion" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const urlEliminarEstadoDocumento = ApiUrl + "eliminarestaddocumentostitulacion" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";

let listaauxiliar = [];
const itemsPerPage = 2;

export default class ListEstadosDocumentTitulacion extends Component {
    constructor(props) {
        super(props)

        this.state = {
            listaBackup: [],
            listarolesdoc: [],
            //variables para paginacion
            currentItems: null,
            pageCount: 0,
            itemOffset: 0,
            count: 0,
        }
        this.handleChange = this.handleChange.bind(this);
        this.escFunction = this.escFunction.bind(this);
    }

    escFunction(event) {
        if (event.key === "Escape") {
            this.setState({ modalIngresarEstadoDocumentTitul: false });
            this.setState({ modalModifEstadoDocumentTitul: false });

        }
    }
    handleChange = async (e) => {
        await this.setState({ [e.target.name]: e.target.value })
    }


    componentDidMount() {
        document.addEventListener("keydown", this.escFunction, true);

        axios.get(urlListarRoles)
            .then(response => {
                var listaroles = response.data.data;
                this.setState({ listarolesdoc: listaroles });
            })
        axios.get(urlListarTodosEstadosTitu)
            .then(response => {
                listaauxiliar = response.data.data;
                if (listaauxiliar != null) {
                    const estud = response.data.data;
                    this.setState((state) => {
                        return { estud: estud }
                    }
                    );
                    const ListaBackup = estud;

                    this.setState((state) => {
                        return { ListaBackup: ListaBackup }
                    });
                    //PAGINACION
                    this.setState({ itemOffset: 0 });
                    const endOffset = this.state.itemOffset + itemsPerPage;
                    this.setState({ currentItems: listaauxiliar.slice(this.state.itemOffset, endOffset) });
                    this.setState({ ListaBackup: listaauxiliar.slice(this.state.itemOffset, endOffset) });
                    this.setState({ pageCount: Math.ceil(listaauxiliar.length / itemsPerPage) });
                    this.setState((state) => {
                        return { itemOffset: state.itemOffset + endOffset }
                    });
                    //FIN DE LA PAGINACION
                }
                else {
                    swal({
                        title: "Advertencia",
                        text: "No existe información",
                        icon: "warning",
                    })
                    this.setState({ currentItems: [] });
                }
            })

    }
    //Modal de Ingreso
    mostrarModalIngresar = (registro) => {
        this.setState({ modalIngresarEstadoDocumentTitul: true});
    }
    //Ocultar modal de ingreso
    ocultarModalIngresar = () => {
        this.setState({ modalIngresarEstadoDocumentTitul: false });
    }
    mostrarModalModificar = (estado) => {
        axios.post(urlDatosEstadosDocument, {
            idEstadoDoc: estado.id_estad_documentos_titulacion,
        })
            .then(response => {
                const estadodoc = response.data.data;
                $(function () {
                    $("#idEstadoDocumentMod").val(estado.id_estad_documentos_titulacion);
                    $("#nomEstadoDocumentMod").val(estadodoc[0].nom_estad_documentos_titulacion);
                    $("#descripEstadoDocumentMod").val(estadodoc[0].descr_estad_documentos_titulacion);
                    $("#rolEstadoDocumentMod").val(estadodoc[0].rol_id);
                });
                this.setState({ idEstadoDocumentMod: estado.id_estad_documentos_titulacion });
                this.setState({ nomEstadoDocumentMod: estadodoc[0].nom_estad_documentos_titulacion });
                this.setState({ descripEstadoDocumentMod: estadodoc[0].descr_estad_documentos_titulacion });
                this.setState({ rolEstadoDocumentMod: estadodoc[0].rol_id });
            })
        this.setState({ modalModifEstadoDocumentTitul: true });
    }
    //Ocultar Modal de modificar
    ocultarModalModificar = () => {
        this.setState({ modalModifEstadoDocumentTitul: false });
    }

    filter(event) {
        console.log(event.target.value);
        //obtener datos de buscar
        var text = event.target.value
        //obtener datos del array
        const data = this.state.estud
        var newData = data.filter(function (item) {
            //variable del objeto
            const itemDataNom = item.nom_estad_documentos_titulacion.toUpperCase();
            const itemDataDescrip = item.descr_estad_documentos_titulacion.toUpperCase();
            const campo = itemDataNom + itemDataDescrip;
            //variable del buscador
            const textData = text.toUpperCase()
            return campo.indexOf(textData) > -1

        })
        if (text == "") {
            newData = this.state.ListaBackup;
        }
        this.setState({
            currentItems: newData,
            textBuscar: text,
        })
    }
    //FUNCION DE PAGINACION POR  MEDIO DE UN CLIC
    handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % listaauxiliar.length;
        this.setState((state) => {
            return { itemOffset: newOffset }
        });
        const endOffset = newOffset + itemsPerPage;
        this.setState({ currentItems: listaauxiliar.slice(newOffset, endOffset) });
        this.setState({ ListaBackup: listaauxiliar.slice(newOffset, endOffset) });
        this.setState({ pageCount: Math.ceil(listaauxiliar.length / itemsPerPage) });
        this.setState((state) => {
            return { itemOffset: newOffset }
        });
    }
    //FINDE FUNCION DE PAGINACION
    ingresarDatosEstadoDocumento = () => {
        axios
            .post(urlIngresarEstadoDoctTitulacion, {
                nomEstadoDoc: this.state.nomEstadoDocument,
                descripEstadoDoc: this.state.descripEstadoDocument,
                rolEstadoDoc: this.state.rolEstadoDocument
            })
            .then(response => {
                if (response.data.success === true) {
                    swal({
                        text: "Datos procesados correctamente",
                        icon: "success",
                        button: "Aceptar",
                    });
                    //Listar los estados
                    axios.get(urlListarTodosEstadosTitu)
                        .then(response => {
                            listaauxiliar = response.data.data;
                            if (listaauxiliar != null) {
                                const estud = response.data.data;
                                this.setState((state) => {
                                    return { estud: estud }
                                }
                                );
                                const ListaBackup = estud;

                                this.setState((state) => {
                                    return { ListaBackup: ListaBackup }
                                });
                                //PAGINACION
                                this.setState({ itemOffset: 0 });
                                const endOffset = this.state.itemOffset + itemsPerPage;
                                this.setState({ currentItems: listaauxiliar.slice(this.state.itemOffset, endOffset) });
                                this.setState({ ListaBackup: listaauxiliar.slice(this.state.itemOffset, endOffset) });
                                this.setState({ pageCount: Math.ceil(listaauxiliar.length / itemsPerPage) });
                                this.setState((state) => {
                                    return { itemOffset: state.itemOffset + endOffset }
                                });
                                //FIN DE LA PAGINACION
                            }
                            else {
                                swal({
                                    title: "Advertencia",
                                    text: "No existe información",
                                    icon: "warning",
                                })
                                this.setState({ currentItems: [] });
                            }
                        })

                    this.setState({ modalIngresarEstadoDocumentTitul: false });
                } else {
                    swal({
                        text: "Datos no actualizados",
                        icon: "error",
                        button: "Aceptar",
                    });
                    this.setState({ modalIngresarEstadoDocumentTitul: false });
                }
            })
            .catch(error => {

                this.setState({ error: "No existe conexión con el servidor" });
                swal({
                    text: "No existe conexión con el servidor",
                    icon: "error",
                    button: "Aceptar",

                });
            });
    }
    modificarDatosEstadoDocument = () => {
        axios
            .post(urlModificarEstadoDocument, {
                idestaddocmod: this.state.idEstadoDocumentMod,
                nomestaddocmod: this.state.nomEstadoDocumentMod,
                descripestaddocmod: this.state.descripEstadoDocumentMod,
                idrolestaddocmod: this.state.rolEstadoDocumentMod
            })
            .then(response => {
                if (response.data.success === true) {
                    swal({
                        text: "Datos procesados correctamente",
                        icon: "success",
                        button: "Aceptar",
                    });
                    axios.get(urlListarTodosEstadosTitu)
                        .then(response => {
                            listaauxiliar = response.data.data;
                            if (listaauxiliar != null) {

                                const estud = response.data.data;
                                this.setState((state) => {
                                    return { estud: estud }
                                }
                                );
                                const ListaBackup = estud;

                                this.setState((state) => {
                                    return { ListaBackup: ListaBackup }
                                });
                                //PAGINACION
                                this.setState({ itemOffset: 0 });
                                const endOffset = this.state.itemOffset + itemsPerPage;
                                this.setState({ currentItems: listaauxiliar.slice(this.state.itemOffset, endOffset) });
                                this.setState({ ListaBackup: listaauxiliar.slice(this.state.itemOffset, endOffset) });
                                this.setState({ pageCount: Math.ceil(listaauxiliar.length / itemsPerPage) });
                                this.setState((state) => {
                                    return { itemOffset: state.itemOffset + endOffset }
                                });
                                //FIN  D DE LA PAGINACION
                            }

                        })
                    this.setState({ modalModifEstadoDocumentTitul: false });
                } else {
                    swal({
                        text: "Datos no actualizados",
                        icon: "error",
                        button: "Aceptar",
                    });
                    this.setState({ modalModTipoTitulacion: false });
                }
            })
            .catch(error => {

                this.setState({ error: "No existe conexión con el servidor" });
                swal({
                    text: "No existe conexión con el servidor",
                    icon: "error",
                    button: "Aceptar",

                });
            });
    }
    //Eliminar el estado de un documento
    eliminarEstadoDocument = (datos) => {

        var combmsmalert = "¿Está seguro que desea eliminar?";
        swal({
            title: "Advertencia",
            text: combmsmalert,
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {

                    axios
                        .post(urlEliminarEstadoDocumento, {
                            idestaddocmod: datos.id_estad_documentos_titulacion,
                        })
                        .then(response => {
                            if (response.data.success === true) {
                                swal({
                                    text: "Datos eliminados correctamente",
                                    icon: "success",
                                    button: "Aceptar",
                                });
                               //Reporte
                               axios.get(urlListarTodosEstadosTitu)
                               .then(response => {
                                   listaauxiliar = response.data.data;
                                   if (listaauxiliar != null) {
       
                                       const estud = response.data.data;
                                       this.setState((state) => {
                                           return { estud: estud }
                                       }
                                       );
                                       const ListaBackup = estud;
       
                                       this.setState((state) => {
                                           return { ListaBackup: ListaBackup }
                                       });
                                       //PAGINACION
                                       this.setState({ itemOffset: 0 });
                                       const endOffset = this.state.itemOffset + itemsPerPage;
                                       this.setState({ currentItems: listaauxiliar.slice(this.state.itemOffset, endOffset) });
                                       this.setState({ ListaBackup: listaauxiliar.slice(this.state.itemOffset, endOffset) });
                                       this.setState({ pageCount: Math.ceil(listaauxiliar.length / itemsPerPage) });
                                       this.setState((state) => {
                                           return { itemOffset: state.itemOffset + endOffset }
                                       });
                                       //FIN  D DE LA PAGINACION
                                   }
       
                               })
                            } else {
                                swal({
                                    text: "Datos no eliminados",
                                    icon: "error",
                                    button: "Aceptar",
                                });
                                // this.setState({ modalRegistroPagoMatricula: false });
                            }
                        })
                        .catch(error => {
                            this.setState({ error: "No existe conexión con el servidor" });
                            swal({
                                text: "No existe conexión con el servidor",
                                icon: "error",
                                button: "Aceptar",
                            });
                        });
                } else {
                    swal({
                        text: "¡Haz denegado la acción!",
                        icon: "warning",
                        button: "Aceptar",
                    });
                }
            });

    }

    render() {
        return (
            <div className="row animate__animated animate__fadeIn">
                <ol className="breadcrumb mb-4">
                    <li className="breadcrumb-item active">{ }</li>
                </ol>
                <div className="card shadow p-3 mb-5 bg-white rounded">
                    <div className="card-header back-istmas">
                        <i className="far fa-sticky-note"></i>
                        <b> Estados de los documentos del proceso de titulación</b>
                    </div>
                    <div className="card-body">
                        <div className="row ">
                            <div className="col-12 col-sm-12 col-lg-5 col-xl-5 centrar" >
                                <div className="mb-3">
                                    <label className="form-label"><b> <i className="fa-magnifying-glass"></i> Buscar:</b></label>
                                    <input type="text" class="form-control col-md-12" value={this.state.text} placeholder="Buscar..." onChange={(text) => this.filter(text)} />
                                </div>
                            </div>
                            <div className="col-12 col-sm-12  col-lg-5 col-xl-5 centrar" >

                            </div>
                        </div>
                        <div className="row ">
                            <div className="col-12 col-sm-12 col-lg-11 col-xl-11 centrar" >
                                <div className="card text-center ">
                                    <div className="card-header ">
                                        ESTADOS
                                    </div>
                                    <div className="card-body centrar " style={{ width: "100%" }}>
                                        <div class="table-responsive">
                                            <table className="table  text-left"  >
                                                <thead >
                                                    <tr >
                                                        <th scope="col">NOMBRE</th>
                                                        <th scope="col">DESCRIPCIÓN</th>
                                                        <th scope="col">ROL</th>
                                                        <th scope="col">MODIFICAR</th>
                                                        <th scope="col">ELIMINAR</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.state.currentItems && this.state.currentItems.map((consola) => (
                                                        <tr key={consola.id_estad_documentos_titulacion}>
                                                            <td class="oculto"> {consola.id_estad_documentos_titulacion}</td>
                                                            <td >{consola.nom_estad_documentos_titulacion}</td>
                                                            <td >{consola.descr_estad_documentos_titulacion}</td>
                                                            <td >{consola.descripcion_rol}</td>


                                                            <td>
                                                                <button onClick={() => this.mostrarModalModificar(consola)}  > <i className="fas fa-clipboard-list" ></i></button>
                                                            </td>
                                                            <td class="eliminar">
                                                                <button onClick={() => this.eliminarEstadoDocument(consola)} > <i class="fa fa-trash"></i></button>

                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>

                                            <div class="table-responsive">
                                                <ReactPaginate
                                                    nextLabel="next >"
                                                    onPageChange={this.handlePageClick}
                                                    pageRangeDisplayed={5}
                                                    pageCount={this.state.pageCount}
                                                    marginPagesDisplayed={2}
                                                    previousLabel="< previous"
                                                    pageClassName="page-item"
                                                    pageLinkClassName="page-link"
                                                    previousClassName="page-item"
                                                    previousLinkClassName="page-link"
                                                    nextClassName="page-item"
                                                    nextLinkClassName="page-link"
                                                    breakLabel="..."
                                                    breakClassName="page-item"
                                                    breakLinkClassName="page-link"
                                                    containerClassName="pagination"
                                                    activeClassName="active"
                                                    renderOnZeroPageCount={null}
                                                    count={10}
                                                />
                                            </div>
                                            <Modal isOpen={this.state.modalIngresarEstadoDocumentTitul}>
                                                <ModalHeader>

                                                    Ingresar un estado de de los documentos de titulación
                                                </ModalHeader>
                                                <ModalBody class="colormodal" >
                                                    <FormGroup>

                                                        <label className="form-label">Nombre</label>
                                                        <input type="text" id="nomEstadoDocument" name="nomEstadoDocument" className="form-control" onChange={this.handleChange} required />
                                                        <label className="form-label">Descripción</label>
                                                        <input type="text" id="descripEstadoDocument" name="descripEstadoDocument" className="form-control" onChange={this.handleChange} required />

                                                        <label className="form-label">Seleccione el nivel a cursar</label>
                                                        <select className="form-select" id="rolEstadoDocument" name="rolEstadoDocument" value={this.state.value} onChange={this.handleChange} aria-label="Default select example">
                                                            <option value="undefined" selected="true" disabled>Seleccione el rol</option>


                                                            {this.state.listarolesdoc.map(person => <option key={person.id_rol} value={person.id_rol} >{person.descripcion_rol}</option>)}

                                                        </select>

                                                    </FormGroup>
                                                </ModalBody>
                                                <ModalFooter>
                                                    <button class="btn btn-outline-primary" onClick={() => this.ingresarDatosEstadoDocumento()} >Aceptar</button>
                                                    <button class="btn btn-outline-primary" onClick={this.ocultarModalIngresar}>Salir</button>
                                                </ModalFooter>
                                            </Modal>
                                        </div>
                                    </div>
                                    <div className="text-center">
                                        <button id="agregar" type="button"
                                            className="btn  back-istmas centrar"
                                            style={{ margin: "5px", width: "170px" }}
                                            onClick={e =>
                                                this.mostrarModalIngresar(e)
                                            }
                                        >
                                            <b>Agregar</b>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row  ">
                            <div className="mb-3 ">
                                <Modal classname="modal-background" isOpen={this.state.modalModifEstadoDocumentTitul}>
                                    <ModalHeader>
                                        <h5></h5>
                                        Modificar los datos del estado del documento
                                    </ModalHeader>
                                    <ModalBody>
                                        <FormGroup>
                                            <input type="hidden" id="idEstadoDocumentMod" name="idEstadoDocumentMod" className="form-control" onChange={this.handleChange} required />
                                            <label className="form-label">Nombre</label>
                                            <input type="text" id="nomEstadoDocumentMod" name="nomEstadoDocumentMod" className="form-control" onChange={this.handleChange} required />
                                            <label className="form-label">Descripción</label>
                                            <input type="text" id="descripEstadoDocumentMod" name="descripEstadoDocumentMod" className="form-control" onChange={this.handleChange} required />
                                            <label className="form-label">Estado</label>
                                            <div className="mb-3">
                                                <label className="form-label"><b>Roles</b></label>
                                                <select className="form-select" id="rolEstadoDocumentMod" name="rolEstadoDocumentMod" value={this.state.value} onChange={this.handleChange} aria-label="Default select example">
                                                    <option value="undefined" selected="true" disabled>Seleccione una carrera</option>
                                                    {this.state.listarolesdoc.map(person => <option key={person.id_rol} value={person.id_rol} >{person.descripcion_rol}</option>)}

                                                </select>
                                            </div>
                                        </FormGroup>
                                    </ModalBody>
                                    <ModalFooter>
                                        <button class="btn btn-outline-primary" onClick={() => this.modificarDatosEstadoDocument(this.state.form2)} > Modificar</button>
                                        <button class="btn btn-outline-primary" onClick={this.ocultarModalModificar}>Salir</button>
                                    </ModalFooter>
                                </Modal>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}


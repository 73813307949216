import axios from 'axios';
import ReactPaginate from 'react-paginate'
import React, { Component } from 'react'
import "../../services/ApiRest";
import "../../secretaria/secretaria.css";
import swal from 'sweetalert';
import { ApiUrl } from '../../services/ApiRest';
import { Alert } from 'bootstrap';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';

const urlListPeriodos = ApiUrl + "listperiodostodos" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const urlListarCarreras = ApiUrl + "listarcarreras" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const UrlListarAsignMatCiclo = ApiUrl + "ListarAsignMatCiclo" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const urlListarEstudCarrPeriod = ApiUrl + "listestudasignamatri" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const urlInfoCarrera = ApiUrl + "infocarrselect" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const urlInfoPeriod = ApiUrl + "infoperiodselect" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const urlListParalelos = ApiUrl + "listodosparalelos" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";


let listaauxiliar = [];
const itemsPerPage = 10;

export default class RecordEst extends Component {

    constructor(props) {
        super(props)
        this.state = {
            carrera: [],
            estado: "",
            listasignaturas: [],
            listaperiodos: [],
            idPeriodo: 1,
            listaasigencab: [],
            listaniveles: [],
            listparalelos: [],
            //Variables para búsqueda
            estud: [],
            EstudianteBackup: [],
            textBuscar: "",
            //variables para paginacion
            currentItems: null,
            pageCount: 0,
            itemOffset: 0,
            count: 0,
            //encabezado de la tabla record
            txt_periodo: "",
            txt_ciclo: "",
            txt_fechaact: "",
            txt_paralelo: "",
            ListEstActaCalif: [],

        }
        this.handleChange = this.handleChange.bind(this);
        this.escFunction = this.escFunction.bind(this);
    }
    escFunction(event) {
        if (event.key === "Escape") {
            this.setState({ modalReportAsignatutasEst: false });
        }
    }
    handleChange = async (e) => {
        await this.setState({ [e.target.name]: e.target.value })
        this.setState({ estado: "" });
    }

    componentDidMount() {
        //Listar las carreras
        axios.get(urlListarCarreras)
            .then(res => {
                const carrera = res.data;
                this.setState({ carrera });
            })

        //listar todos los periodos
        axios
            .get(urlListPeriodos)
            .then(response => {
                const listaperiodos = response.data.data;
                const cant = listaperiodos.length;
                if (cant != 0) {
                    this.setState({ listaperiodos });
                }
            })
        //listar todos los paralelos
            axios
            .get(urlListParalelos)
            .then(response => {
                if (response.data.success == true) {
                    const listparalelos = response.data.data;
                    const cant = listparalelos.length;
                    if (cant != 0) {
                        this.setState({ listparalelos });
                    }
                }
            })

    }

    //BUSQUEDA......
    filter(event) {
        console.log(event.target.value);
        //obtener datos de buscar
        var text = event.target.value
        //obtener datos del array
        const data = this.state.estud
        var newData = data.filter(function (item) {
            //variable del objeto
            const itemDataPrimerApell = item.primer_apellido_estudiante.toUpperCase()
            const itemDataSegundoApell = item.segundo_apellido_estudiante.toUpperCase()
            const itemDataPrimerNom = item.primer_nombre_estudiante.toUpperCase()
            const itemDataSegundoNom = item.segundo_nombre_estudiante.toUpperCase()
            const itemDataCedula = item.numero_identificacion_estudiante.toUpperCase()
            const campo = itemDataPrimerApell + " " + itemDataSegundoApell + " " + itemDataPrimerNom + " " + itemDataSegundoNom + " " + itemDataCedula;
            //variable del buscador
            const textData = text.toUpperCase()
            return campo.indexOf(textData) > -1

        })
        if (text == "") {
            newData = this.state.EstudianteBackup;
        }
        this.setState({
            currentItems: newData,
            textBuscar: text,
        })

    }
    //FUNCION DE PAGINACION POR  MEDIO DE UN CLIC
    handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % listaauxiliar.length;
        this.setState((state) => {
            return { itemOffset: newOffset }
        });
        const endOffset = newOffset + itemsPerPage;
        this.setState({ currentItems: listaauxiliar.slice(newOffset, endOffset) });
        this.setState({ EstudianteBackup: listaauxiliar.slice(newOffset, endOffset) });
        this.setState({ pageCount: Math.ceil(listaauxiliar.length / itemsPerPage) });
        this.setState((state) => {
            return { itemOffset: newOffset }
        });
    }


    handleChangeSelectNivel = async (e) => {
        await this.setState({ [e.target.name]: e.target.value })
        axios
            .post(urlInfoCarrera, {
                idCarreraS: this.state.idCarrera
            })
            .then(response => {
                const codigocarrest = response.data.objcarrea[0].id_carrera;
                const descripcarrest = response.data.objcarrea[0].descripcion_carrera.toUpperCase();
                const nivelcarrest = response.data.objcarrea[0].num_niveles;
                this.setState({ codigoCarrE: codigocarrest });
                this.setState({ descripCarrE: descripcarrest });
                this.setState({ numnivelE: nivelcarrest });
                let listaniveles1 = []
                for (let i = 1; i <= nivelcarrest; i++) {
                    let fila = {
                        codnivel: i,
                        valor: i
                    };
                    listaniveles1.push(fila);
                }
                this.setState({ listaniveles: listaniveles1 });
            })
        //Datos del periodo seleccionado
        axios
            .post(urlInfoPeriod, {
                idPeriodoS: this.state.idPeriodo
            })
            .then(response => {
                const perioddescrip = response.data.data[0].descripcion_periodo_academico;
                this.setState({ txt_periodo: perioddescrip });

            })
        //Fecha actual
        let date = new Date();
        this.setState({ fecha_actual: date.toLocaleDateString() });


    }
    handleChangematcarrnivel = async (e) => {
        await this.setState({ [e.target.name]: e.target.value })
         //Tomar el texto del select
         var select = document.getElementById("idParalelo"), 
         value = select.value,
         text = select.options[select.selectedIndex].innerText;
         this.setState({nom_paralelo_select: text });

        axios
            .post(UrlListarAsignMatCiclo, {
                idCarrera: this.state.idCarrera,
                idCiclo: this.state.nivelEstu,
            })
            .then(response => {
                var array = response.data.data;
                this.setState({ listaasigencab: array });
                axios.post(urlListarEstudCarrPeriod, {
                    idPeriodSelect: this.state.idPeriodo,
                    idCarreraSelect: this.state.idCarrera,
                    idListaAsigna: array,
                    idCicloSelect: this.state.nivelEstu,
                    idParaleloSelect: this.state.idParalelo
                })
                    .then(respuesta => {
                        if (respuesta.data.success == true) {
                            var array = respuesta.data.data;
                            this.setState({ ListEstActaCalif: array });
                            listaauxiliar = array;
                            const estud = array;
                            this.setState((state) => {
                                return { estud: estud }
                            }
                            );
                            const EstudianteBackup = estud;
                            this.setState((state) => {
                                return { EstudianteBackup: EstudianteBackup }
                            });
                            //PAGINACION
                            this.state.itemOffset = 0;
                            const endOffset = this.state.itemOffset + itemsPerPage;
                            this.setState({ currentItems: listaauxiliar.slice(this.state.itemOffset, endOffset) });
                            this.setState({ EstudianteBackup: listaauxiliar.slice(this.state.itemOffset, endOffset) });
                            this.setState({ pageCount: Math.ceil(listaauxiliar.length / itemsPerPage) });
                            this.setState((state) => {
                                return { itemOffset: state.itemOffset + endOffset }
                            });
                            //FIN  DE LA PAGINACION
                        } else {
                            this.setState({ error: "¡No existe información!" });
                            swal({
                                text: "¡No existe información!",
                                icon: "info",
                                button: "Aceptar",
                            });
                        }
                    })
            })
    }

    render() {
        return (
            <div className="row animate__animated animate__fadeIn">
                <ol className="breadcrumb mb-4">
                    <li className="breadcrumb-item active">{ }</li>
                </ol>
                <div className="card shadow p-3 mb-5 bg-white rounded">
                    <div className="card-header back-istmas">
                        <i className="far fa-sticky-note"></i>
                        <b>Acta de calificaciones</b>
                    </div>
                    <div className="card-body">

                        <div class="row">
                            <div className="col-12 col-sm-12 col-lg-1 col-xl-3 ">
                                <div className="mb-3">
                                    <label className="form-label"><b> <i className="fa-magnifying-glass"></i> Buscar:</b></label>
                                    <input type="text" class="form-control col-md-12" value={this.state.text} placeholder="Buscar..." onChange={(text) => this.filter(text)} />

                                </div>
                            </div>
                        </div>

                        <div class="row">

                            <div className="col-12 col-sm-12 col-lg-1 col-xl-3 " >
                                <div className="mb-3">
                                    <label className="form-label"><b> <i className="fa fa-calendar"></i> Período</b></label>
                                    <select className="form-select" name="idPeriodo" value={this.state.value} onChange={this.handleChange} aria-label="Default select example">
                                        <option value="undefined" selected="true" disabled>Seleccione el período</option>
                                        {this.state.listaperiodos.map(periodo => <option key={periodo.id_periodo_academico} value={periodo.id_periodo_academico} > {periodo.descripcion_periodo_academico}</option>)}
                                    </select>
                                </div>
                            </div>
                            <div className="col-12 col-sm-12 col-lg-1 col-xl-3 " >
                                <div className="mb-3">
                                    <label className="form-label"><b>Carrera</b></label>
                                    <select className="form-select" name="idCarrera" value={this.state.value} onChange={this.handleChangeSelectNivel} aria-label="Default select example">
                                        <option value="undefined" selected="true" disabled>Seleccione una carrera</option>
                                        {this.state.carrera.map(person => <option key={person.id_carrera} value={person.id_carrera} > {person.descripcion_carrera}</option>)}
                                    </select>
                                </div>
                            </div>
                            <div className="col-12 col-sm-12 col-lg-1 col-xl-3 ">
                                <div className="mb-3">
                                    <label className="form-label"><b> <i className="fa-magnifying-glass"></i> Paralelo</b></label>
                                   <select className="form-select" name="idParalelo" id="idParalelo" value={this.state.value} onChange={this.handleChange} aria-label="Default select example">
                                                                    <option value="undefined">Seleccione un paralelo:</option>
                                                                    {this.state.listparalelos.map(consola => <option key={consola.id_paralelo} value={consola.id_paralelo} > {consola.nombre_paralelo}</option>)}
                                                                </select>


                                </div>
                            </div>
                            <div className="col-12 col-sm-12 col-lg-1 col-xl-3 ">
                                <div className="mb-3">
                                    <label className="form-label"><b>Nivel</b></label>
                                    <select className="form-select" id="nivelEstu" name="nivelEstu" value={this.state.value} onChange={this.handleChangematcarrnivel} aria-label="Default select example">
                                        <option value="undefined" selected="true" disabled>Seleccione </option>
                                        {this.state.listaniveles.map(person => <option key={person.codnivel} value={person.codnivel} > {person.valor}</option>)}
                                    </select>
                                </div>
                            </div>

                        </div>
                        <table id="tablarecordexcel" className="table text-left" hidden>
                            <thead >
                                <tr>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                             
                                    <td>
                                        <img src="https://www.istmas.edu.ec/images/descargas/logos/logoh.png" width="35%" height="auto" />
                                    </td>
                                </tr>
                                <tr></tr>
                                <tr></tr>
                                <tr></tr>
                                <tr></tr>
                                <tr></tr>
                                <tr></tr>
                                <tr></tr>
                                <tr>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <th style={{ fontSize: 20 }} colspan="5" scope="col">ACTA DE CALIFICACIONES</th>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <th scope="col">Carrera</th>
                                    <td scope="col">{this.state.descripCarrE}</td>
                                    <th scope="col">Ciclo</th>
                                    <td scope="col">

                                        {(() => {

                                            //Paralelo
                                            switch (this.state.nivelEstu) {
                                                case "1": return "PRIMER CICLO";
                                                case "2": return "SEGUNDO CICLO";
                                                case "3": return "TERCER CICLO";
                                                case "4": return "CUARTO CICLO";
                                                case "5": return "QUINTO CICLO";
                                                case "6": return "SEXTO CICLO";
                                                default: return ""
                                            }
                                        })()}
                                    </td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <th scope="col"></th>
                                    <td scope="col"></td>
                                    <th scope="col">Paralelo</th>
                                    <td scope="col"> { this.state.nom_paralelo_select}

                                    </td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <th scope="col">Período</th>
                                    <td scope="col">{this.state.txt_periodo}</td>
                                    <th scope="col">Fecha</th>
                                    <td scope="col">{this.state.fecha_actual}</td>
                                </tr>
                                <tr>
                                    <td></td>
                                </tr>
                                <tr>
                                    <th style={{ 'borderColor': "#4D4D4D", 'borderStyle': 'solid' }} scope="col">Nº</th>
                                    <th style={{ 'borderColor': "#4D4D4D", 'borderStyle': 'solid' }} scope="col">CÉDULA</th>
                                    <th style={{ 'borderColor': "#4D4D4D", 'borderStyle': 'solid' }} scope="col">PRIMER APELLIDO</th>
                                    <th style={{ 'borderColor': "#4D4D4D", 'borderStyle': 'solid' }} scope="col">SEGUNDO APELLIDO</th>
                                    <th style={{ 'borderColor': "#4D4D4D", 'borderStyle': 'solid' }} scope="col">PRIMER NOMBRE</th>
                                    <th style={{ 'borderColor': "#4D4D4D", 'borderStyle': 'solid' }} scope="col">SEGUNDO NOMBRE</th>
                                    {this.state.listaasigencab.map(consola => (

                                        <th style={{ 'borderColor': "#4D4D4D", 'borderStyle': 'solid' }} scope="col">{consola.descripcion_asignatura}</th>

                                    ))}

                                    <th style={{ 'borderColor': "#4D4D4D", 'borderStyle': 'solid' }} scope="col">TOTAL</th>
                                    <th style={{ 'borderColor': "#4D4D4D", 'borderStyle': 'solid' }} scope="col">PROMEDIO</th>
                                    <th style={{ 'borderColor': "#4D4D4D", 'borderStyle': 'solid' }} scope="col">VALORACIÓN</th>

                                </tr>
                            </thead>
                            <tbody>

                                {this.state.ListEstActaCalif.map(consola => (
                                    <tr key={consola.id_estudiante}>

                                        <td style={{ 'borderColor': "#4D4D4D", 'borderStyle': 'solid' }}>{consola.num_est}</td>
                                        <td style={{ 'borderColor': "#4D4D4D", 'borderStyle': 'solid' }}>{consola.numero_identificacion_estudiante}</td>
                                        <td style={{ 'borderColor': "#4D4D4D", 'borderStyle': 'solid' }}>{consola.primer_apellido_estudiante}</td>
                                        <td style={{ 'borderColor': "#4D4D4D", 'borderStyle': 'solid' }}>{consola.segundo_apellido_estudiante}</td>
                                        <td style={{ 'borderColor': "#4D4D4D", 'borderStyle': 'solid' }}>{consola.primer_nombre_estudiante}</td>
                                        <td style={{ 'borderColor': "#4D4D4D", 'borderStyle': 'solid' }}>{consola.segundo_nombre_estudiante}</td>


                                        {consola.lista_calif_asig.map(consolaasig => (

                                            <td style={{ 'borderColor': "#4D4D4D", 'borderStyle': 'solid' }}>{consolaasig.calificacion_prom}</td>

                                        ))}

                                        <td style={{ 'borderColor': "#4D4D4D", 'borderStyle': 'solid' }}>{consola.total}</td>
                                        <td style={{ 'borderColor': "#4D4D4D", 'borderStyle': 'solid' }}>{consola.promedio}</td>
                                        <td style={{ 'borderColor': "#4D4D4D", 'borderStyle': 'solid' }}>{consola.valoracion}</td>
                                    </tr>
                                ))}


                            </tbody>
                            <tfooter>
                                <tr></tr>
                                <tr></tr>
                                <tr></tr>
                                <tr>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td><b>Rectora</b></td>
                                    <td></td>
                                    <td><b>Secretaría General</b></td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td>
                                        {this.state.texto_docente}
                                    </td>
                                    <td></td>
                                    <td>MSc.Renata Rafaela León Costales </td>
                                    <td></td>
                                    <td>Tlga. Fanny Barriga</td>
                                </tr>
                            </tfooter>
                        </table>


                        <div className="row ">
                            <div className="col-12 col-sm-12 col-lg-11 col-xl-11 centrar" >
                                <div className="card text-center ">
                                    <div className="card-header ">
                                        CALIFICACIÓN DE ESTUDIANTES-COORDINACIÓN
                                    </div>
                                    <div className="card-body centrar " style={{ width: "100%" }}>
                                        <div class="table-responsive">
                                            <table className="table  text-left" id="tablaRECORD" >
                                                <thead >
                                                    <tr >
                                                        <th scope="col">Nº</th>
                                                        <th scope="col">CÉDULA</th>
                                                        <th scope="col">PRIMER APELLIDO</th>
                                                        <th scope="col">SEGUNDO APELLIDO</th>
                                                        <th scope="col">PRIMER NOMBRE</th>
                                                        <th scope="col">SEGUNDO NOMBRE</th>
                                                        {this.state.listaasigencab.map(consola => (
                                                            <th scope="col">{consola.descripcion_asignatura}</th>
                                                        ))}
                                                        <th scope="col">TOTAL</th>
                                                        <th scope="col">PROMEDIO</th>
                                                        <th scope="col">VALORACIÓN</th>

                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.state.currentItems &&
                                                        this.state.currentItems.map((consola) => (
                                                            <tr class="list-afiliados " key={this.state.estud.id_estudiante}  >

                                                                <td >{consola.num_est}</td>
                                                                <td >{consola.numero_identificacion_estudiante}</td>
                                                                <td >{consola.primer_apellido_estudiante}</td>
                                                                <td>{consola.segundo_apellido_estudiante}</td>
                                                                <td>{consola.primer_nombre_estudiante}</td>
                                                                <td>{consola.segundo_nombre_estudiante}</td>
                                                                {consola.lista_calif_asig.map(consolaasig => (
                                                                    <td >
                                                                        {(() => {

                                                                            if (consolaasig.calificacion_prom < 7) {
                                                                                return <span class="mensajesusp"> {consolaasig.calificacion_prom}</span>
                                                                            }
                                                                            else {
                                                                                return <span> {consolaasig.calificacion_prom}</span>
                                                                            }
                                                                        })()}
                                                                    </td>

                                                                ))}
                                                                <td>{consola.total}</td>
                                                                <td>{consola.promedio}</td>
                                                                <td>{consola.valoracion}</td>
                                                            </tr>
                                                        ))}
                                                </tbody>
                                            </table>

                                            <div className="row ">
                                                <div className="col-12 col-sm-12 col-lg-5 col-xl-5 centrar" >
                                                    <div className="mb-3">
                                                        <ReactHTMLTableToExcel
                                                            id="botonExportarExcel"
                                                            className="btn btn-success"
                                                            table="tablarecordexcel"
                                                            filename="acta_calificaciones"
                                                            sheet="RECORD"
                                                            buttonText="Descargar Acta"
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    <ReactPaginate
                                        nextLabel="next >"
                                        onPageChange={this.handlePageClick}
                                        pageRangeDisplayed={5}
                                        pageCount={this.state.pageCount}
                                        marginPagesDisplayed={2}
                                        previousLabel="< previous"
                                        pageClassName="page-item"
                                        pageLinkClassName="page-link"
                                        previousClassName="page-item"
                                        previousLinkClassName="page-link"
                                        nextClassName="page-item"
                                        nextLinkClassName="page-link"
                                        breakLabel="..."
                                        breakClassName="page-item"
                                        breakLinkClassName="page-link"
                                        containerClassName="pagination"
                                        activeClassName="active"
                                        renderOnZeroPageCount={null}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="alert alert-warning" role="alert" hidden={this.state.hidden}>
                            {this.state.estado}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

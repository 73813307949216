import axios from 'axios';
import { Modal, ModalHeader, ModalBody, ModalFooter, FormGroup } from 'reactstrap';
import $ from 'jquery';
import React, { Component, useState } from 'react';
import { ApiUrl } from '../../services/ApiRest';
import "../../vicerrectoradoacad/vicerrectoradoacad.css";
import ReactPaginate from 'react-paginate';
import swal from 'sweetalert';


const urlListarEntidadF = ApiUrl + "listarentidadf" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const urlCambiarEstEntidad = ApiUrl + "estadoentidad" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const urlIngresarEntidad = ApiUrl + "ingresarentidad" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const urlDatosEntidad = ApiUrl + "datosentidadf" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const urlModifDatosEntidad = ApiUrl + "modificarentidad" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
let listaauxiliar = [];
const itemsPerPage = 2;

export default class ListCentroPracticas extends Component {
    constructor(props) {
        super(props)

        this.state = {

            coordinacion: [],
            listaBackup: [],

            form: {
            },
            form1: {
            },
            form2: {
            },
            //variables para paginacion
            currentItems: null,
            pageCount: 0,
            itemOffset: 0,
            count: 0,
        }
        this.handleChange = this.handleChange.bind(this);
        this.escFunction = this.escFunction.bind(this);
    }

    escFunction(event) {
        if (event.key === "Escape") {
            this.setState({ modalIngresarCoord: false });
        }
    }

    handleChange = async (e) => {
        await this.setState({ [e.target.name]: e.target.value })
        this.setState({ estado: "" });
    }

    bttActDesac = (entidad) => {
        var auxestad = 0;
        if (entidad.estado_entidad == 0) {
            auxestad = 1;
        }
        else {
            auxestad = 0;
        }
        axios.post(urlCambiarEstEntidad, {
            idEntidad: entidad.id_entidad,
            estado: auxestad,
        })
            .then(response => {
                // Reporte de entidades

                axios.get(urlListarEntidadF)
                    .then(response => {
                        listaauxiliar = response.data.data;
                        if (listaauxiliar != null) {

                            const estud = response.data.data;
                            this.setState((state) => {
                                return { estud: estud }
                            }
                            );
                            const ListaBackup = estud;

                            this.setState((state) => {
                                return { ListaBackup: ListaBackup }
                            });
                            //PAGINACION
                            this.setState({ itemOffset: 0 });
                            const endOffset = this.state.itemOffset + itemsPerPage;
                            this.setState({ currentItems: listaauxiliar.slice(this.state.itemOffset, endOffset) });
                            this.setState({ ListaBackup: listaauxiliar.slice(this.state.itemOffset, endOffset) });
                            this.setState({ pageCount: Math.ceil(listaauxiliar.length / itemsPerPage) });
                            this.setState((state) => {
                                return { itemOffset: state.itemOffset + endOffset }
                            });
                            //FIN  D DE LA PAGINACION
                        }
                        else {
                            swal({
                                title: "Advertencia",
                                text: "No existe información",
                                icon: "warning",
                            })
                        }
                    })

                //fin de reporte de entidades
            })
    }
    componentDidMount() {
        document.addEventListener("keydown", this.escFunction, true);
        axios.get(urlListarEntidadF)
            .then(response => {
                listaauxiliar = response.data.data;
                if (listaauxiliar != null) {
                    const estud = response.data.data;
                    this.setState((state) => {
                        return { estud: estud }
                    }
                    );
                    const ListaBackup = estud;

                    this.setState((state) => {
                        return { ListaBackup: ListaBackup }
                    });
                    //PAGINACION
                    this.setState({ itemOffset: 0 });
                    const endOffset = this.state.itemOffset + itemsPerPage;
                    this.setState({ currentItems: listaauxiliar.slice(this.state.itemOffset, endOffset) });
                    this.setState({ ListaBackup: listaauxiliar.slice(this.state.itemOffset, endOffset) });
                    this.setState({ pageCount: Math.ceil(listaauxiliar.length / itemsPerPage) });
                    this.setState((state) => {
                        return { itemOffset: state.itemOffset + endOffset }
                    });
                    //FIN  D DE LA PAGINACION
                }
                else {
                    swal({
                        title: "Advertencia",
                        text: "No existe información",
                        icon: "warning",

                    })
                }
            })

    }
    //Modal de Ingreso de coordinación
    modalAgregarEntidad = (registro) => {
        this.setState({ modalIngresarEntidad: true, form1: registro });
    }
    ingresarDatosEntidad = () => {
        this.setState({ modalIngresarEntidad: false });
    }
    ingresarDatosEntidad = () => {
        axios
            .post(urlIngresarEntidad, {

                ruc: this.state.ruc,
                nombre: this.state.nombre,
                representante: this.state.representante,
                direccion: this.state.direccion,
                ciudad: this.state.ciudad,
                telefono: this.state.telefono,
                actividad: this.state.actividad,
                estado: 1,
            })
            .then(response => {
                if (response.data.success === true) {
                    swal({
                        text: "Datos procesados correctamente",
                        icon: "success",
                        button: "Aceptar",
                    });
                    axios.get(urlListarEntidadF)
                        .then(response => {
                            listaauxiliar = response.data.data;
                            if (listaauxiliar != null) {

                                const estud = response.data.data;
                                this.setState((state) => {
                                    return { estud: estud }
                                }
                                );
                                const ListaBackup = estud;

                                this.setState((state) => {
                                    return { ListaBackup: ListaBackup }
                                });
                                //PAGINACION
                                this.setState({ itemOffset: 0 });
                                const endOffset = this.state.itemOffset + itemsPerPage;
                                this.setState({ currentItems: listaauxiliar.slice(this.state.itemOffset, endOffset) });
                                this.setState({ ListaBackup: listaauxiliar.slice(this.state.itemOffset, endOffset) });
                                this.setState({ pageCount: Math.ceil(listaauxiliar.length / itemsPerPage) });
                                this.setState((state) => {
                                    return { itemOffset: state.itemOffset + endOffset }
                                });
                                //FIN  D DE LA PAGINACION
                            }
                            else {
                                swal({
                                    title: "Advertencia",
                                    text: "No existe información",
                                    icon: "warning",
                                })
                            }
                        })
                    this.setState({ modalIngresarEntidad: false });
                } else {
                    swal({
                        text: "Datos no actualizados",
                        icon: "error",
                        button: "Aceptar",
                    });
                    this.setState({ modalIngresarEntidad: false });
                }
            })
            .catch(error => {

                this.setState({ error: "No existe conexión con el servidor" });
                swal({
                    text: "No existe conexión con el servidor",
                    icon: "error",
                    button: "Aceptar",

                });
            });
    }
    //Modal de modificar la sede
    modificarEntidad = (entidad) => {
        axios.post(urlDatosEntidad, {
            idEntidad: entidad.id_entidad,
        })
            .then(res => {
                const EntidadF = res.data.data;
                $(function () {
                    $("#rucmod").val(EntidadF[0].ruc_entidad);
                    $("#nombremod").val(EntidadF[0].nombre_entidad);
                    $("#representantemod").val(EntidadF[0].represent_legal_ent);
                    $("#direccionmod").val(EntidadF[0].direccion_entidad);
                    $("#ciudadmod").val(EntidadF[0].ciudad_entidad);
                    $("#telefonomod").val(EntidadF[0].telefono_entidad);
                    $("#actividadmod").val(EntidadF[0].actividad_entidad);

                });

                this.setState({ rucmod: EntidadF[0].ruc_entidad });
                this.setState({ nombremod: EntidadF[0].nombre_entidad });
                this.setState({ representantemod: EntidadF[0].represent_legal_ent });
                this.setState({ direccionmod: EntidadF[0].direccion_entidad });
                this.setState({ ciudadmod: EntidadF[0].ciudad_entidad });
                this.setState({ telefonomod: EntidadF[0].telefono_entidad });
                this.setState({ actividadmod: EntidadF[0].actividad_entidad });



            })
        this.setState({ modalModEntidad: true, form2: entidad });
    }

    ocultarModalModifCentro = () => {
        this.setState({ modalModEntidad: false });
    }

    modificarDatosEntidad = (datos) => {


        var combmsmalert = "¿Está seguro que desea modificar los datos?";
        swal({
            title: "Advertencia",
            text: combmsmalert,
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    axios
                        .post(urlModifDatosEntidad, {
                            idEntidad: datos.id_entidad,
                            ruc: this.state.rucmod,
                            nombre: this.state.nombremod,
                            representante: this.state.representantemod,
                            direccion: this.state.direccionmod,
                            ciudad: this.state.ciudadmod,
                            telefono: this.state.telefonomod,
                            actividad: this.state.actividadmod
                        })
                        .then(response => {

                            if (response.data.success === true) {
                                swal({
                                    text: "Datos procesados correctamente",
                                    icon: "success",
                                    button: "Aceptar",
                                });

                                //llamar al reporte
                                axios.get(urlListarEntidadF)
                                    .then(response => {
                                        listaauxiliar = response.data.data;
                                        if (listaauxiliar != null) {

                                            const estud = response.data.data;
                                            this.setState((state) => {
                                                return { estud: estud }
                                            }
                                            );
                                            const ListaBackup = estud;

                                            this.setState((state) => {
                                                return { ListaBackup: ListaBackup }
                                            });
                                            //PAGINACION
                                            this.setState({ itemOffset: 0 });
                                            const endOffset = this.state.itemOffset + itemsPerPage;
                                            this.setState({ currentItems: listaauxiliar.slice(this.state.itemOffset, endOffset) });
                                            this.setState({ ListaBackup: listaauxiliar.slice(this.state.itemOffset, endOffset) });
                                            this.setState({ pageCount: Math.ceil(listaauxiliar.length / itemsPerPage) });
                                            this.setState((state) => {
                                                return { itemOffset: state.itemOffset + endOffset }
                                            });
                                            //FIN  D DE LA PAGINACION
                                        }
                                        else {
                                            swal({
                                                title: "Advertencia",
                                                text: "No existe información",
                                                icon: "warning",


                                            })
                                        }


                                    })
                                //fin


                                this.setState({ modalModEntidad: false });

                            } else {
                                swal({
                                    text: "No se actualizó los datos",
                                    icon: "error",
                                    button: "Aceptar",
                                });


                            }

                        })
                        .catch(error => {
                            console.log(error);

                            this.setState({ estado: "Error No se pudo conectar con el servidor" });
                            window.location.reload(true);
                        })
                } else {
                    swal({
                        text: "¡Haz denegado la acción!",
                        icon: "warning",
                        button: "Aceptar",
                    });
                }
            });
    }
    //BUSQUEDA......hola

    filter(event) {
        console.log(event.target.value);
        //obtener datos de buscar
        var text = event.target.value
        //obtener datos del array
        const data = this.state.estud
        var newData = data.filter(function (item) {
            //variable del objeto
            const itemDataNomCentro = item.ruc_entidad.toUpperCase();
            const itemDataRepresentante = item.nombre_entidad.toUpperCase();
            const itemDataSupervisor = item.ciudad_entidad.toUpperCase();
            const campo = itemDataNomCentro + " " + itemDataRepresentante + " " + itemDataSupervisor;
            //variable del buscador
            const textData = text.toUpperCase()
            //return itemData.indexOf(textData)> -1
            return campo.indexOf(textData) > -1

        })

        if (text == "") {
            newData = this.state.ListaBackup;
        }
        this.setState({
            currentItems: newData,
            textBuscar: text,
        })
    }

    filter(event) {
        console.log(event.target.value);
        //obtener datos de buscar
        var text = event.target.value
        //obtener datos del array
        const data = this.state.estud
        var newData = data.filter(function (item) {
            //variable del objeto
            const itemDataRuc = item.ruc_entidad.toUpperCase();
            const itemDataNombre = item.nombre_entidad.toUpperCase();
            const itemDataCiudad = item.ciudad_entidad.toUpperCase();
            const campo = itemDataRuc + " " + itemDataNombre + " " + itemDataCiudad;
            //variable del buscador
            const textData = text.toUpperCase()
            //return itemData.indexOf(textData)> -1
            return campo.indexOf(textData) > -1

        })

        if (text == "") {
            newData = this.state.ListaBackup;
        }
        this.setState({
            currentItems: newData,
            textBuscar: text,
        })

    }

    //FUNCION DE PAGINACION POR  MEDIO DE UN CLIC
    handlePageClick = (event) => {

        const newOffset = (event.selected * itemsPerPage) % listaauxiliar.length;
        this.setState((state) => {
            return { itemOffset: newOffset }
        });
        const endOffset = newOffset + itemsPerPage;
        this.setState({ currentItems: listaauxiliar.slice(newOffset, endOffset) });
        this.setState({ ListaBackup: listaauxiliar.slice(newOffset, endOffset) });
        this.setState({ pageCount: Math.ceil(listaauxiliar.length / itemsPerPage) });
        this.setState((state) => {
            return { itemOffset: newOffset }
        });
    }
    //FINDE FUNCION DE PAGINACION

    render() {
        return (
            <div className="row animate__animated animate__fadeIn">
                <ol className="breadcrumb mb-4">
                    <li className="breadcrumb-item active">{ }</li>
                </ol>
                <div className="card shadow p-3 mb-5 bg-white rounded">
                    <div className="card-header back-istmas">
                        <i className="far fa-sticky-note"></i>
                        <b> Entidades </b>
                    </div>
                    <div className="card-body">
                        <div className="row ">
                            <div className="col-12 col-sm-12 col-lg-11 col-xl-11 centrar" >
                                <div className="card text-center ">
                                    <div className="card-header ">
                                        ENTIDAD FORMADORA
                                    </div>

                                    <div className="col-12 col-sm-12 col-lg-12 col-xl-4 " >
                                        <div className="mb-3">
                                            <label className="form-label"><b> <i className="fa-magnifying-glass"></i> </b></label>
                                            <input type="text" class="form-control col-md-12" value={this.state.text} placeholder="Buscar..." onChange={(text) => this.filter(text)} />
                                        </div>
                                    </div>

                                    <div className="card-body centrar " style={{ width: "100%" }}>
                                        <div class="table-responsive">
                                            <table className="table  text-left"  >
                                                <thead >
                                                    <tr >
                                                        <th scope="col">RUC</th>
                                                        <th scope="col">INSTITUCIÓN</th>
                                                        <th scope="col">REPRESENTANTE</th>
                                                        <th scope="col">DIRECCIÓN</th>
                                                        <th scope="col">CIUDAD</th>
                                                        <th scope="col">TELÉFONO</th>
                                                        <th scope="col">ACTIVIDAD</th>
                                                        <th scope="col">ESTADO</th>
                                                        <th scope="col">MODIFICAR</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.state.currentItems && this.state.currentItems.map((consola) => (
                                                        <tr key={consola.id_entidad}>
                                                            <td >{consola.ruc_entidad}</td>
                                                            <td >{consola.nombre_entidad}</td>
                                                            <td >{consola.represent_legal_ent}</td>
                                                            <td >{consola.direccion_entidad}</td>
                                                            <td >{consola.ciudad_entidad}</td>
                                                            <td >{consola.telefono_entidad}</td>
                                                            <td >{consola.actividad_entidad}</td>
                                                            <td >
                                                                {(() => {
                                                                    //Paralelo
                                                                    switch (consola.estado_entidad) {
                                                                        case 0: return <button class="bttsuspendido" onClick={() => this.bttActDesac(consola)}  > SUSPENDIDO</button>
                                                                        case 1: return <button class="bttactiva" onClick={() => this.bttActDesac(consola)}  > ACTIVO</button>
                                                                        default: return ""
                                                                    }
                                                                })()
                                                                }

                                                            </td>
                                                            <td>
                                                                <button onClick={() => this.modificarEntidad(consola)}  > <i className="fas fa-clipboard-list" ></i></button>
                                                            </td>
                                                        </tr>
                                                    ))}

                                                </tbody>
                                            </table>

                                            <div class="table-responsive">
                                                <ReactPaginate
                                                    nextLabel="next >"
                                                    onPageChange={this.handlePageClick}
                                                    pageRangeDisplayed={5}
                                                    pageCount={this.state.pageCount}
                                                    marginPagesDisplayed={2}
                                                    previousLabel="< previous"
                                                    pageClassName="page-item"
                                                    pageLinkClassName="page-link"
                                                    previousClassName="page-item"
                                                    previousLinkClassName="page-link"
                                                    nextClassName="page-item"
                                                    nextLinkClassName="page-link"
                                                    breakLabel="..."
                                                    breakClassName="page-item"
                                                    breakLinkClassName="page-link"
                                                    containerClassName="pagination"
                                                    activeClassName="active"
                                                    renderOnZeroPageCount={null}
                                                    count={10}
                                                />
                                            </div>
                                            <Modal isOpen={this.state.modalIngresarEntidad}>
                                                <ModalHeader>

                                                    Ingresar datos de la entidad
                                                </ModalHeader>
                                                <ModalBody class="colormodal" >
                                                    <FormGroup>
                                                        <label className="form-label">RUC</label>
                                                        <input type="text" id="ruc" name="ruc" className="form-control" onChange={this.handleChange} required />
                                                        <label className="form-label">Nombre</label>
                                                        <input type="text" id="nombre" name="nombre" className="form-control" onChange={this.handleChange} required />
                                                        <label className="form-label">Representante</label>
                                                        <input type="text" id="representante" name="representante" className="form-control" onChange={this.handleChange} required />
                                                        <label className="form-label">Dirección</label>
                                                        <input type="text" id="direccion" name="direccion" className="form-control" onChange={this.handleChange} required />
                                                        <label className="form-label">Ciudad</label>
                                                        <input type="text" id="ciudad" name="ciudad" className="form-control" onChange={this.handleChange} required />
                                                        <label className="form-label">Teléfono</label>
                                                        <input type="text" id="telefono" name="telefono" className="form-control" onChange={this.handleChange} required />
                                                        <label className="form-label">actividad</label>
                                                        <input type="text" id="actividad" name="actividad" className="form-control" onChange={this.handleChange} required />
                                                    </FormGroup>
                                                </ModalBody>
                                                <ModalFooter>
                                                    <button class="btn btn-outline-primary" onClick={() => this.ingresarDatosEntidad()} >Aceptar</button>
                                                    <button class="btn btn-outline-primary" onClick={this.ocultarModalAgregarEntidad}>Salir</button>
                                                </ModalFooter>
                                            </Modal>
                                        </div>
                                    </div>
                                    <div className="text-center">
                                        <button id="agregar" type="button"
                                            className="btn  back-istmas centrar"
                                            style={{ margin: "5px", width: "170px" }}
                                            onClick={e =>
                                                this.modalAgregarEntidad(e)
                                            }
                                        >
                                            <b>Agregar</b>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row  ">
                            <div className="mb-3 ">
                                <Modal classname="modal-background" isOpen={this.state.modalModEntidad}>
                                    <ModalHeader>
                                        <h5></h5>
                                        Modificar
                                    </ModalHeader>
                                    <ModalBody>
                                        <FormGroup>
                                            <label className="form-label">RUC</label>
                                            <input type="text" id="rucmod" name="rucmod" className="form-control" onChange={this.handleChange} required />
                                            <label className="form-label">Nombre</label>
                                            <input type="text" id="nombremod" name="nombremod" className="form-control" onChange={this.handleChange} required />
                                            <label className="form-label">Representante</label>
                                            <input type="text" id="representantemod" name="representantemod" className="form-control" onChange={this.handleChange} required />
                                            <label className="form-label">Dirección</label>
                                            <input type="text" id="direccionmod" name="direccionmod" className="form-control" onChange={this.handleChange} required />
                                            <label className="form-label">Ciudad</label>
                                            <input type="text" id="ciudadmod" name="ciudadmod" className="form-control" onChange={this.handleChange} required />
                                            <label className="form-label">Teléfono</label>
                                            <input type="text" id="telefonomod" name="telefonomod" className="form-control" onChange={this.handleChange} required />
                                            <label className="form-label">Actividad</label>
                                            <input type="text" id="actividadmod" name="actividadmod" className="form-control" onChange={this.handleChange} required />
                                        </FormGroup>
                                    </ModalBody>
                                    <ModalFooter>
                                        <button class="btn btn-outline-primary" onClick={() => this.modificarDatosEntidad(this.state.form2)} > Modificar</button>
                                        <button class="btn btn-outline-primary" onClick={this.ocultarModalModifCentro}>Salir</button>
                                    </ModalFooter>
                                </Modal>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

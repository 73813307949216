import axios from 'axios'
import React, { Component } from 'react'
import { Redirect, Route } from 'react-router-dom'
import Cookies from 'universal-cookie'
import FooterDashboard from '../components/footer/FooterDashboard'
import NavbarGeneral from '../components/navbar/NavbarGeneral'
import SidebarEstudiante from '../components/sidebar/SidebarEstudiante'
import Contenido from '../components/contenido/Contenido'
import { ApiUrl } from '../components/services/ApiRest'
import VerPerfil from '../components/estudiante/perfil/VerPerfil'
import EstudianteInscripcion from '../components/estudiante/matriculas/EstudianteInscripcion'
import ListarAsignaturasMatricu from '../components/estudiante/matriculas/ListarAsignaturasMatricu'
import EstudianteColegPeriodCarr from '../components/estudiante/colegiaturas/EstudianteColegPeriodCarr'
import AgendaEst from '../components/estudiante/agenda/AgendaEst'
import EstudianteNotificacion from '../components/estudiante/notificaciones/NotificacionesEstudiante'
import EstudianteActaCompromiso from '../components/estudiante/documentacion/Actacompromisoest'
import TiposTitulacion from '../components/estudiante/titulacion/ListTiposTitulacion'

import { Alert } from 'reactstrap'

const cookie = new Cookies();
const idEstudiante = cookie.get("idEstudiante");
const urlDatosEst = ApiUrl + "datosestudiante" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";

export default class RutasEstudiante extends Component {
    constructor(props) {
        super(props);
        this.state = {
            estudiante: [],
            carrera: [],
            nombreCarrera: ""
        };
        this.componentDidMount = this.componentDidMount.bind(this);
    }

    componentDidMount() {


        axios.post(urlDatosEst, {
            idEstudiante: idEstudiante
        })
            .then(response => {
                const liscarrerasest = response.data.data;
                var tamcarr = 0;
                tamcarr = liscarrerasest.length - 1;
                //Nombres
                var NombresCompleto = "";
                if (liscarrerasest[0].segundo_nombre_estudiante != null) {
                    NombresCompleto = liscarrerasest[0].primer_nombre_estudiante + " " + liscarrerasest[0].segundo_nombre_estudiante;
                }
                else {
                    NombresCompleto = liscarrerasest[0].primer_nombre_estudiante;
                }
                //Apellidos
                var ApellidosCompletos = "";
                if (liscarrerasest[0].segundo_apellido_estudiante != null) {
                    ApellidosCompletos = liscarrerasest[0].primer_apellido_estudiante + " " + liscarrerasest[0].segundo_apellido_estudiante;
                }
                else {
                    ApellidosCompletos = liscarrerasest[0].primer_apellido_estudiante;
                }
                cookie.set('nombres', NombresCompleto, { path: "/" })
                cookie.set('apellidos', ApellidosCompletos, { path: "/" })
                cookie.set('idCarreraEstu', liscarrerasest[tamcarr].id_carrera, { path: "/" })
                const nombreCarrera = liscarrerasest[tamcarr].descripcion_carrera;
                this.setState({ nombreCarrera});
            });
    }

    render() {

        if (!cookie.get("log")) {
            return (<Redirect to="/login" />);
        }

        return (
            <>
                <NavbarGeneral />
                <div id="layoutSidenav" >
                    <SidebarEstudiante />
                    <div id="layoutSidenav_content">
                        <main>
                            <div className="container-fluid">

                                <div className="animate__animated animate__fadeIn">
                                    <h5 className="mt-4 "><b>{this.state.nombreCarrera}</b></h5>
                                </div>
                                <Contenido />
                                <Route path="/estudiante/EstudianteVerPerfil" component={VerPerfil} />
                                {/* Matricula */}
                                <Route path="/estudiante/EstudianteInscrip" component={EstudianteInscripcion} />
                                <Route path="/estudiante/ListarAsignaturasMatricu" component={ListarAsignaturasMatricu} />
                                <Route path="/estudiante/EstudianteColegPeriodCarr" component={EstudianteColegPeriodCarr} />
                                <Route path="/estudiante/AgendaEst" component={AgendaEst} />
                                <Route path="/estudiante/NotificacionEstudiante" component={EstudianteNotificacion} />
                                <Route path="/estudiante/actadecompromiso" component={EstudianteActaCompromiso} />
                                <Route path="/estudiante/tipostitulacion" component={TiposTitulacion} />
                                
                                 </div>
                        </main>
                        <FooterDashboard />
                    </div>
                </div>
            </>
        )
    }
}

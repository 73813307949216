import axios from 'axios';
import { Modal, ModalHeader, ModalBody, ModalFooter, FormGroup } from 'reactstrap';
import $ from 'jquery';
import React, { Component, useState } from 'react';
import { ApiUrl } from '../../services/ApiRest';
import "../../vicerrectoradoacad/vicerrectoradoacad.css";
import ReactPaginate from 'react-paginate';
import swal from 'sweetalert';
import Cookies from 'universal-cookie';
const cookie = new Cookies();

const urlListarTiposTitulacion = ApiUrl + "listartipotitul" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const urlIngresarTipoTitulacion = ApiUrl + "ingresartipotitul" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const urlDatosTipoTitulacion = ApiUrl + "datostipotitul" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const urlModificarTipoTitulacion = ApiUrl + "modificartipotitul" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const urlListarCarrAsigTipoTitu = ApiUrl + "carrerasasigtipoTitu" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const urlInfPeriodoCarreraUltNivel = ApiUrl + "periodultnivelmatricarr" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const urlCarrerasMatrUltCiclo = ApiUrl + "carrerasmatriultimociclo" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const urlTipoTitulacionEstudiante = ApiUrl + "tipotitulacionselectestud" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const urlConsultTipoTitulacionEstudiante = ApiUrl + "consulttipotitulselectestud" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";



let listaauxiliar = [];
const itemsPerPage = 10;

export default class ListTiposTitulacion extends Component {
    constructor(props) {
        super(props)

        this.state = {
            carreramatultciclo: [],
            coordinacion: [],
            listaBackup: [],
            carrera: [],
            listatipostitultodos: [],
            listCarrTipoTitulMod: [],
            mensajeseleccion: "",
            form: {
            },
            form1: {
            },
            form2: {
            },
            //variables para paginacion
            currentItems: null,
            pageCount: 0,
            itemOffset: 0,
            count: 0,
        }
        this.handleChange = this.handleChange.bind(this);
        this.escFunction = this.escFunction.bind(this);
    }

    escFunction(event) {
        if (event.key === "Escape") {
            this.setState({ modalIngresarTipoTitulacion: false });
            this.setState({ modalModTipoTitulacion: false });
        }
    }

    handleChange = async (e) => {
        await this.setState({ [e.target.name]: e.target.value })
        this.setState({ estado: "" });
    }


    componentDidMount() {
        document.addEventListener("keydown", this.escFunction, true);
        $("#nomtutorasignado").hide();

        //Listar las carreras
        axios
            .post(urlCarrerasMatrUltCiclo, {
                idEstudiante: cookie.get("idEstudiante")
            })
            .then(response => {
                if (response.data.success == true)
                    this.setState({ carreramatultciclo: response.data.data });
                else {
                    this.setState({ carreramatultciclo: [] });
                    swal({
                        title: "Advertencia",
                        text: "Usted no se encuentra cursando el último ciclo de su carrera.",
                        icon: "warning",
                    })
                }



            })
    }
    //Modal de Ingreso
    modalAgregarEntidad = (registro) => {
        this.setState({ modalIngresarTipoTitulacion: true, form1: registro });
    }
    //Ocultar modal de ingreso
    ocultarModalIngresarTipoTitul = () => {
        this.setState({ modalIngresarTipoTitulacion: false });
    }

    ocultarModalModificartDatosTipoTitulacion = () => {
        this.setState({ modalModTipoTitulacion: false });
    }

    ingresarDatosTipoTitulacion = () => {
        //Crear la lista de roles seleccionados
        let carreraselect = [];
        $("input:checkbox[class=checkbox-carrtipotituing]:checked").each(function () {
            var carrseleccionado = $(this).parent().parent().find('td').eq(0).text();
            let fila = {
                idCarr: carrseleccionado
            };
            carreraselect.push(fila);
        });
        var gsoncarrerasselect = JSON.stringify(carreraselect);
        axios
            .post(urlIngresarTipoTitulacion, {

                nombrett: this.state.nombretipo,
                descripciontt: this.state.descriptipo,
                estadott: 1,
                listcarreras: gsoncarrerasselect,
            })
            .then(response => {
                if (response.data.success === true) {
                    swal({
                        text: "Datos procesados correctamente",
                        icon: "success",
                        button: "Aceptar",
                    });
                    axios
                        .post(urlListarTiposTitulacion, {
                            idCarreraTipoT: this.state.idCarrera
                        })
                        .then(response => {

                            listaauxiliar = response.data.data;
                            if (listaauxiliar != null) {

                                const estud = response.data.data;
                                this.setState((state) => {
                                    return { estud: estud }
                                }
                                );
                                const ListaBackup = estud;

                                this.setState((state) => {
                                    return { ListaBackup: ListaBackup }
                                });
                                //PAGINACION
                                this.setState({ itemOffset: 0 });
                                const endOffset = this.state.itemOffset + itemsPerPage;
                                this.setState({ currentItems: listaauxiliar.slice(this.state.itemOffset, endOffset) });
                                this.setState({ ListaBackup: listaauxiliar.slice(this.state.itemOffset, endOffset) });
                                this.setState({ pageCount: Math.ceil(listaauxiliar.length / itemsPerPage) });
                                this.setState((state) => {
                                    return { itemOffset: state.itemOffset + endOffset }
                                });
                                //FIN  D DE LA PAGINACION
                            }
                            else {
                                swal({
                                    title: "Advertencia",
                                    text: "No existe información",
                                    icon: "warning",
                                })
                            }
                        })
                    this.setState({ modalIngresarTipoTitulacion: false });
                } else {
                    swal({
                        text: "Datos no actualizados",
                        icon: "error",
                        button: "Aceptar",
                    });
                    this.setState({ modalIngresarEntidad: false });
                }
            })
            .catch(error => {

                this.setState({ error: "No existe conexión con el servidor" });
                swal({
                    text: "No existe conexión con el servidor",
                    icon: "error",
                    button: "Aceptar",

                });
            });
    }
    //Modal de mostrar datos de un tipo de titulación
    mostrarModalDatosTipoTitulacion = async (e) => {
        await this.setState({ [e.target.name]: e.target.value });
        this.setState({ listCarrTipoTitulMod: [] });
        this.setState({ modalModTipoTitulacion: true });
    }

    mostrarDatosTipoTitulacion = async (e) => {
        await this.setState({ [e.target.name]: e.target.value });
        axios.post(urlDatosTipoTitulacion, {
            idTipoTitulacion: this.state.idtipomod,
        })
            .then(response => {
                const TipoTitulacion = response.data.data;
                $(function () {
                    $("#nombretipomod").val(TipoTitulacion[0].nom_tipo_titulacion);
                    $("#descriptipomod").val(TipoTitulacion[0].descrip_tipo_titulacion);
                    $("#idestadomod").val(TipoTitulacion[0].estado_tipo_titulacion);
                });
                this.setState({ nombretipomod: TipoTitulacion[0].nom_tipo_titulacion });
                this.setState({ descriptipomod: TipoTitulacion[0].descrip_tipo_titulacion });
                this.setState({ idestadomod: TipoTitulacion[0].estado_tipo_titulacion });
                //Lista las carreras asignadas a un tipo de titulación
                axios.post(urlListarCarrAsigTipoTitu, {
                    idTipoTitu: this.state.idtipomod
                })
                    .then(res => {
                        const listaCarrTitulacionMod = res.data.data;
                        this.setState({ listCarrTipoTitulMod: listaCarrTitulacionMod });
                    })
            })
        this.setState({ modalModTipoTitulacion: true });

    }
    ocultarModalModifCentro = () => {
        this.setState({ modalModEntidad: false });
    }
    filter(event) {
        console.log(event.target.value);
        //obtener datos de buscar
        var text = event.target.value
        //obtener datos del array
        const data = this.state.estud
        var newData = data.filter(function (item) {
            //variable del objeto
            const itemDataNom = item.nom_tipo_titulacion.toUpperCase();
            const itemDataDescrip = item.descrip_tipo_titulacion.toUpperCase();
            const campo = itemDataNom + itemDataDescrip;
            //variable del buscador
            const textData = text.toUpperCase()
            return campo.indexOf(textData) > -1

        })
        if (text == "") {
            newData = this.state.ListaBackup;
        }
        this.setState({
            currentItems: newData,
            textBuscar: text,
        })
    }
    //FUNCION DE PAGINACION POR  MEDIO DE UN CLIC
    handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % listaauxiliar.length;
        this.setState((state) => {
            return { itemOffset: newOffset }
        });
        const endOffset = newOffset + itemsPerPage;
        this.setState({ currentItems: listaauxiliar.slice(newOffset, endOffset) });
        this.setState({ ListaBackup: listaauxiliar.slice(newOffset, endOffset) });
        this.setState({ pageCount: Math.ceil(listaauxiliar.length / itemsPerPage) });
        this.setState((state) => {
            return { itemOffset: newOffset }
        });
    }
    //FINDE FUNCION DE PAGINACION

    handleChangeActPeriod = async (e) => {
        await this.setState({ [e.target.name]: e.target.value })

        axios
            .post(urlInfPeriodoCarreraUltNivel, {
                idEstudiante: cookie.get("idEstudiante"),
                idCarrera: this.state.idCarrera,
            })
            .then(response => {
         
                this.setState({ descriperiodo: response.data.data[0].descripcion_periodo_academico });
                this.setState({ idperiodo: response.data.data[0].id_periodo_academico });
            })



        axios
            .post(urlConsultTipoTitulacionEstudiante, {
                idEstudiante: cookie.get("idEstudiante"),
                idCarrera: this.state.idCarrera,
            })
            .then(response => {
                if (response.data.success == true) {
                    $("#nomtutorasignado").show();
                    var mensaje = "Usted ha seleccionado el tipo de titulación: " + response.data.data[0].nom_tipo_titulacion;
                    var idTutor = response.data.data[0].id_administrativo;
                    var nombresTutor = response.data.data[0].nombres_administrativo + " " + response.data.data[0].apellidos_administrativo;
                    var cedulaTut = response.data.data[0].numero_identificacion_administrativo;
                    var correoTut = response.data.data[0].email_administrativo;
                    var celularTut = response.data.data[0].celular_administrativo;


                    if (idTutor == 1) {
                        this.setState({ nombrescomplettutor: "Sin asignación de tutor" });
                        this.setState({ cedulaTutor: "Sin asignación de tutor" });
                        this.setState({ correoTutor: "Sin asignación de tutor" });
                        this.setState({ celularTutor: "Sin asignación de tutor" });
                    }
                    else {

                        this.setState({ nombrescomplettutor: nombresTutor });
                        this.setState({ cedulaTutor: cedulaTut });
                        this.setState({ correoTutor: correoTut });
                        this.setState({ celularTutor: celularTut });
                    }
                    this.setState({ mensajeseleccion: mensaje });
                    this.setState({ currentItems: [] });
                    $("#mensajetipotitulselect").show();
                    $("#tablatipostitulacion").hide();
                 
                }
                else {
                    $("#mensajetipotitulselect").hide();
                    $('#tablatipostitulacion').show();
                    this.setState({ mensajeseleccion: "" });
                    axios
                        .post(urlListarTiposTitulacion, {
                            idCarreraTipoT: this.state.idCarrera
                        })
                        .then(response => {

                            listaauxiliar = response.data.data;
                            if (listaauxiliar != null) {
                                const estud = response.data.data;
                                this.setState((state) => {
                                    return { estud: estud }
                                }
                                );
                                const ListaBackup = estud;

                                this.setState((state) => {
                                    return { ListaBackup: ListaBackup }
                                });
                                //PAGINACION
                                this.setState({ itemOffset: 0 });
                                const endOffset = this.state.itemOffset + itemsPerPage;
                                this.setState({ currentItems: listaauxiliar.slice(this.state.itemOffset, endOffset) });
                                this.setState({ ListaBackup: listaauxiliar.slice(this.state.itemOffset, endOffset) });
                                this.setState({ pageCount: Math.ceil(listaauxiliar.length / itemsPerPage) });
                                this.setState((state) => {
                                    return { itemOffset: state.itemOffset + endOffset }
                                });
                                //FIN  D DE LA PAGINACION
                            }
                            else {
                                swal({
                                    title: "Advertencia",
                                    text: "No existe información",
                                    icon: "warning",
                                })
                            }
                        })
                }
            })

    }
    //modificar

    modificarDatosTipoTitulacion = () => {

        //Crear la lista de roles seleccionados
        let carreraselect = [];
        // $("input[type=checkbox]:checked").each(function () {
        $("input:checkbox[class=checkbox-carrtipotitumod]:checked").each(function () {

            var carrseleccionado = $(this).parent().parent().find('td').eq(0).text();
            let fila = {
                idCarrMod: carrseleccionado
            };
            carreraselect.push(fila);
        });
        var gsoncarrerasselect = JSON.stringify(carreraselect);

        axios
            .post(urlModificarTipoTitulacion, {
                idttmod: this.state.idtipomod,
                nombrettmod: this.state.nombretipomod,
                descripcionttmod: this.state.descriptipomod,
                estadomod: this.state.idestadomod,
                listcarrerasmod: gsoncarrerasselect,
            })
            .then(response => {
                if (response.data.success === true) {
                    swal({
                        text: "Datos procesados correctamente",
                        icon: "success",
                        button: "Aceptar",
                    });
                    axios
                        .post(urlListarTiposTitulacion, {
                            idCarreraTipoT: this.state.idCarrera
                        })
                        .then(response => {

                            listaauxiliar = response.data.data;
                            if (listaauxiliar != null) {

                                const estud = response.data.data;
                                this.setState((state) => {
                                    return { estud: estud }
                                }
                                );
                                const ListaBackup = estud;

                                this.setState((state) => {
                                    return { ListaBackup: ListaBackup }
                                });
                                //PAGINACION
                                this.setState({ itemOffset: 0 });
                                const endOffset = this.state.itemOffset + itemsPerPage;
                                this.setState({ currentItems: listaauxiliar.slice(this.state.itemOffset, endOffset) });
                                this.setState({ ListaBackup: listaauxiliar.slice(this.state.itemOffset, endOffset) });
                                this.setState({ pageCount: Math.ceil(listaauxiliar.length / itemsPerPage) });
                                this.setState((state) => {
                                    return { itemOffset: state.itemOffset + endOffset }
                                });
                                //FIN  D DE LA PAGINACION
                            }

                        })
                    this.setState({ modalModTipoTitulacion: false });
                } else {
                    swal({
                        text: "Datos no actualizados",
                        icon: "error",
                        button: "Aceptar",
                    });
                    this.setState({ modalModTipoTitulacion: false });
                }
            })
            .catch(error => {

                this.setState({ error: "No existe conexión con el servidor" });
                swal({
                    text: "No existe conexión con el servidor",
                    icon: "error",
                    button: "Aceptar",

                });
            });
    }

    handleChangeTiposTitulacion = async (e) => {
        await this.setState({ [e.target.name]: e.target.value })
        axios
            .post(urlListarTiposTitulacion, {
                idCarreraTipoT: this.state.idCarrera
            })
            .then(response => {

                listaauxiliar = response.data.data;
                if (listaauxiliar != null) {
                    const estud = response.data.data;
                    this.setState((state) => {
                        return { estud: estud }
                    }
                    );
                    const ListaBackup = estud;

                    this.setState((state) => {
                        return { ListaBackup: ListaBackup }
                    });
                    //PAGINACION
                    this.setState({ itemOffset: 0 });
                    const endOffset = this.state.itemOffset + itemsPerPage;
                    this.setState({ currentItems: listaauxiliar.slice(this.state.itemOffset, endOffset) });
                    this.setState({ ListaBackup: listaauxiliar.slice(this.state.itemOffset, endOffset) });
                    this.setState({ pageCount: Math.ceil(listaauxiliar.length / itemsPerPage) });
                    this.setState((state) => {
                        return { itemOffset: state.itemOffset + endOffset }
                    });
                    //FIN  D DE LA PAGINACION
                }
                else {
                    swal({
                        title: "Advertencia",
                        text: "No existe información",
                        icon: "warning",

                    })
                    this.setState({ currentItems: [] });
                }
            })
    }

    seleccionartipoTitulacion = (datos) => {//valido
        var combmsmalert = "Usted ha seleccionado el tipo de titulación: " + datos.nom_tipo_titulacion;
        swal({
            title: "Advertencia",
            text: combmsmalert,
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    axios
                        .post(urlTipoTitulacionEstudiante, {
                            idEstudiante: cookie.get("idEstudiante"),
                            idTipoTitulacion: datos.id_tipo_titulacion,
                            idPeriodo: this.state.idperiodo,
                            idCarrera: this.state.idCarrera,
                        })
                        .then(response => {
                            if (response.data.success === true) {
                                swal({
                                    text: "Datos ingresados correctamente",
                                    icon: "success",
                                    button: "Aceptar",
                                });
                                //Actualizar
                                axios
                                    .post(urlConsultTipoTitulacionEstudiante, {
                                        idEstudiante: cookie.get("idEstudiante"),
                                        idCarrera: this.state.idCarrera,
                                    })
                                    .then(response => {

                                        if (response.data.success == true) {
                                            var mensaje = "Usted ha seleccionado el tipo de titulación: " + response.data.data[0].nom_tipo_titulacion;
                                            var nombresTutor = response.data.data[0].nombres_administrativo + " " + response.data.data[0].apellidos_administrativo;
                                            var idTutor = response.data.data[0].id_administrativo;
                                            var cedulaTut = response.data.data[0].numero_identificacion_administrativo;
                                            var correoTut = response.data.data[0].email_administrativo;
                                            var celularTut = response.data.data[0].celular_administrativo;
                                            $("#nomtutorasignado").show();
                                            if (idTutor == 1) {
                                                this.setState({ nombrescomplettutor: "Sin asignación de tutor" });
                                                this.setState({ cedulaTutor: "Sin asignación de tutor" });
                                                this.setState({ correoTutor: "Sin asignación de tutor" });
                                                this.setState({ celularTutor: "Sin asignación de tutor" });
                                            }
                                            else {

                                                this.setState({ nombrescomplettutor: nombresTutor });
                                                this.setState({ cedulaTutor: cedulaTut });
                                                this.setState({ correoTutor: correoTut });
                                                this.setState({ celularTutor: celularTut });
                                            }

                                            this.setState({ mensajeseleccion: mensaje });
                                            this.setState({ currentItems: [] });
                                            $("#mensajetipotitulselect").show();
                                            $("#tablatipostitulacion").hide();
                                        }
                                        else {
                                            $("#mensajetipotitulselect").hide();
                                            $('#tablatipostitulacion').show();
                                            this.setState({ mensajeseleccion: "" });
                                            axios
                                                .post(urlListarTiposTitulacion, {
                                                    idCarreraTipoT: this.state.idCarrera
                                                })
                                                .then(response => {

                                                    listaauxiliar = response.data.data;
                                                    if (listaauxiliar != null) {
                                                        const estud = response.data.data;
                                                        this.setState((state) => {
                                                            return { estud: estud }
                                                        }
                                                        );
                                                        const ListaBackup = estud;

                                                        this.setState((state) => {
                                                            return { ListaBackup: ListaBackup }
                                                        });
                                                        //PAGINACION
                                                        this.setState({ itemOffset: 0 });
                                                        const endOffset = this.state.itemOffset + itemsPerPage;
                                                        this.setState({ currentItems: listaauxiliar.slice(this.state.itemOffset, endOffset) });
                                                        this.setState({ ListaBackup: listaauxiliar.slice(this.state.itemOffset, endOffset) });
                                                        this.setState({ pageCount: Math.ceil(listaauxiliar.length / itemsPerPage) });
                                                        this.setState((state) => {
                                                            return { itemOffset: state.itemOffset + endOffset }
                                                        });
                                                        //FIN  D DE LA PAGINACION
                                                    }
                                                    else {
                                                        swal({
                                                            title: "Advertencia",
                                                            text: "No existe información",
                                                            icon: "warning",
                                                        })
                                                    }
                                                })
                                        }
                                    })


                                //fin de actualización
                            } else {
                                swal({
                                    text: "Datos no ingresados",
                                    icon: "error",
                                    button: "Aceptar",
                                });
                            }
                        })
                        .catch(error => {
                            this.setState({ error: "No existe conexión con el servidor" });
                            swal({
                                text: "No existe conexión con el servidor",
                                icon: "error",
                                button: "Aceptar",
                            });
                        });
                } else {
                    swal({
                        text: "¡Haz denegado la acción!",
                        icon: "warning",
                        button: "Aceptar",
                    });
                }
            });
    }

    render() {
        return (
            <div className="row animate__animated animate__fadeIn">
                <ol className="breadcrumb mb-4">
                    <li className="breadcrumb-item active">{ }</li>
                </ol>
                <div className="card shadow p-3 mb-5 bg-white rounded">
                    <div className="card-header back-istmas">
                        <i className="far fa-sticky-note"></i>
                        <b> Tipos de titulación</b>
                    </div>
                    <div className="card-body">
                        <div className="row ">
                            <div className="col-12 col-sm-12 col-lg-5 col-xl-5 centrar">
                                <div className="mb-3">
                                    <label className="form-label"><b> <i className="fa fa-calendar"></i> Carrera</b></label>
                                    <select className="form-select" name="idCarrera" value={this.state.value} onChange={this.handleChangeActPeriod} aria-label="Default select example">

                                        <option value="undefined" selected="true" disabled>Seleccione una carrera</option>
                                        {this.state.carreramatultciclo.map(person => <option key={person.id_carrera} value={person.id_carrera} > {person.descripcion_carrera} </option>)}


                                    </select>
                                </div>
                            </div>
                            <div className="col-12 col-sm-12  col-lg-5 col-xl-5 centrar" >
                                <label className="form-label"><b> <i className="fa fa-calendar"></i> Período Académico</b></label>
                                <br />
                                <label className="form-label">
                                    {this.state.descriperiodo}
                                </label>
                            </div>
                        </div>

                        <div className="row " id="mensajetipotitulselect">
                            <div className="col-12 col-sm-12 col-lg-11 col-xl-11 centrar" >
                                <div className="card text-center ">
                                    <div class="alert alert-success" role="alert">
                                        <b>
                                            {this.state.mensajeseleccion}</b >
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row " id="tablatipostitulacion">
                            <div className="col-12 col-sm-12 col-lg-11 col-xl-11 centrar" >
                                <div className="card text-center ">
                                    <div className="card-header ">
                                        TIPOS DE TITULACIÓN
                                    </div>
                                    <div className="card-body centrar " style={{ width: "100%" }}>
                                        <div class="table-responsive">
                                            <table className="table  text-left"  >
                                                <thead >
                                                    <tr >
                                                        <th scope="col">NOMBRE</th>
                                                        <th scope="col">DESCRIPCIÓN</th>
                                                        <th scope="col">CARRERA</th>
                                                        <th scope="col">SELECCIONAR</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.state.currentItems && this.state.currentItems.map((consola) => (
                                                        <tr key={consola.id_tipo_titulacion}>
                                                            <td class="oculto"> {consola.id_tipo_titulacion}</td>
                                                            <td >{consola.nom_tipo_titulacion}</td>
                                                            <td >{consola.descrip_tipo_titulacion}</td>
                                                            <td >{consola.descripcion_carrera}</td>
                                                            <td >
                                                                <input type="radio" value="1" name="afiliado" id="afiliado" onClick={() => this.seleccionartipoTitulacion(consola)} class="afiliado" />

                                                            </td>
                                                            <td>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>

                                            <div class="table-responsive">
                                                <ReactPaginate
                                                    nextLabel="next >"
                                                    onPageChange={this.handlePageClick}
                                                    pageRangeDisplayed={5}
                                                    pageCount={this.state.pageCount}
                                                    marginPagesDisplayed={2}
                                                    previousLabel="< previous"
                                                    pageClassName="page-item"
                                                    pageLinkClassName="page-link"
                                                    previousClassName="page-item"
                                                    previousLinkClassName="page-link"
                                                    nextClassName="page-item"
                                                    nextLinkClassName="page-link"
                                                    breakLabel="..."
                                                    breakClassName="page-item"
                                                    breakLinkClassName="page-link"
                                                    containerClassName="pagination"
                                                    activeClassName="active"
                                                    renderOnZeroPageCount={null}
                                                    count={10}
                                                />
                                            </div>





                                            <Modal isOpen={this.state.modalIngresarTipoTitulacion}>
                                                <ModalHeader>

                                                    Ingresar un tipo de titulación
                                                </ModalHeader>
                                                <ModalBody class="colormodal" >
                                                    <FormGroup>

                                                        <label className="form-label">Nombre</label>
                                                        <input type="text" id="nombretipo" name="nombretipo" className="form-control" onChange={this.handleChange} required />
                                                        <label className="form-label">Descripción</label>
                                                        <input type="text" id="descriptipo" name="descriptipo" className="form-control" onChange={this.handleChange} required />
                                                        <label className="form-label">Seleccione la carrera a la que pertenece</label>
                                                        <div class="table-responsive">
                                                            <table id="tablacarreras" className="table  text-left" >
                                                                <thead >
                                                                    <tr>
                                                                        <th scope="col">Carrera</th>
                                                                        <th scope="col">Seleccionar</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {this.state.carrera.map(consola => (
                                                                        <tr key={consola.id_carrera}>
                                                                            <td class="idCarreraSelect oculto" >{consola.id_carrera}</td>

                                                                            <td class="descriCarreraSelect" >{consola.descripcion_carrera}</td>
                                                                            <td class="selectCarrera">
                                                                                <input disabled={(this.state.disablmodmat) ? "disabled" : ""} name={"checkcarr" + consola.id_carrera} id={"checkcarr" + consola.id_carrera} type="checkbox" class="checkbox-carrtipotituing" />
                                                                                {(() => {
                                                                                    $('#checkcarr' + consola.id_carrera).checked = false;
                                                                                })()}
                                                                            </td>
                                                                        </tr>
                                                                    ))}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </FormGroup>
                                                </ModalBody>
                                                <ModalFooter>
                                                    <button class="btn btn-outline-primary" onClick={() => this.ingresarDatosTipoTitulacion()} >Aceptar</button>
                                                    <button class="btn btn-outline-primary" onClick={this.ocultarModalIngresarTipoTitul}>Salir</button>
                                                </ModalFooter>
                                            </Modal>
                                        </div>
                                    </div>
                                    <div className="text-center">


                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className="row  ">
                            <div className="mb-3 ">
                                <Modal classname="modal-background" isOpen={this.state.modalModTipoTitulacion}>
                                    <ModalHeader>
                                        <h5></h5>
                                        Modificar los datos del tipo de titulación
                                    </ModalHeader>
                                    <ModalBody>
                                        <FormGroup>
                                            <label className="form-label">Seleccione el nivel a cursar</label>
                                            <select className="form-select" id="idtipomod" name="idtipomod" value={this.state.value} onChange={this.mostrarDatosTipoTitulacion} aria-label="Default select example">
                                                <option value="undefined" selected="true" disabled>Seleccione el periódo</option>


                                                {this.state.listatipostitultodos.map(person => <option key={person.id_tipo_titulacion} value={person.id_tipo_titulacion} >{person.nom_tipo_titulacion}</option>)}

                                            </select>
                                            <label className="form-label">Nombre</label>
                                            <input type="text" id="nombretipomod" name="nombretipomod" className="form-control" onChange={this.handleChange} required />
                                            <label className="form-label">Descripción</label>
                                            <input type="text" id="descriptipomod" name="descriptipomod" className="form-control" onChange={this.handleChange} required />
                                            <label className="form-label">Estado</label>
                                            <select className="form-select" id="idestadomod" name="idestadomod" value={this.state.value} onChange={this.handleChange} aria-label="Default select example" disabled={(this.state.disabledformpagmodd) ? "disabled" : ""} >
                                                <option value="0" >Deshabilitado</option>
                                                <option value="1" >Habilitado</option>
                                            </select>
                                            <div className="mb-3">
                                                <label className="form-label">Seleccione el rol</label>
                                                <div class="table-responsive">
                                                    <table id="tablacarrtipomod" className="table  text-left" >
                                                        <thead >
                                                            <tr>

                                                                <th scope="col">Carrera</th>
                                                                <th scope="col">Seleccionar</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {this.state.listCarrTipoTitulMod.map(consola => (
                                                                <tr key={consola.id_carrera}>
                                                                    <td class="idCarreraMod oculto">{consola.id_carrera}</td>
                                                                    <td class="decripCarreraMod" >{consola.descripcion_carr}</td>
                                                                    <td class="selectcarreraMod">
                                                                        <input name={"checkmod" + consola.id_carrera} id={"checkmod" + consola.id_carrera} type="checkbox" class="checkbox-carrtipotitumod" />
                                                                        {(() => {

                                                                            if (this.state.modalModTipoTitulacion == true) {
                                                                                if (consola.estado == 1) {
                                                                                    $(function () {
                                                                                        $('#checkmod' + consola.id_carrera)[0].checked = true;
                                                                                    })
                                                                                }
                                                                                else {
                                                                                    if (consola.estado == 0) {
                                                                                        $(function () {
                                                                                            $('#checkmod' + consola.id_carrera)[0].checked = false;
                                                                                        })
                                                                                    }

                                                                                }
                                                                            }
                                                                        })()}

                                                                    </td>
                                                                </tr>
                                                            ))}

                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </FormGroup>
                                    </ModalBody>
                                    <ModalFooter>
                                        <button class="btn btn-outline-primary" onClick={() => this.modificarDatosTipoTitulacion(this.state.form2)} > Modificar</button>
                                        <button class="btn btn-outline-primary" onClick={this.ocultarModalModificartDatosTipoTitulacion}>Salir</button>
                                    </ModalFooter>
                                </Modal>
                            </div>
                        </div>


                        <br />
                        <div className="row " id="nomtutorasignado">
                            <div className="col-12 col-sm-12 col-lg-6 col-xl-6 centrar" >
                                <div className="card text-center ">
                                    <div className="card-header ">
                                        TUTOR ASIGNADO
                                    </div>
                                    <div className="card-body centrar " style={{ width: "100%" }}>
                                        <div class="table-responsive">
                                            <table className="table  text-center" id="tablacantmatri" name="tablacantmatri" >
                                                <thead >

                                                </thead>
                                                <tbody>

                                                    <tr >
                                                        <th scope="col">NOMBRES COMPLETOS</th>

                                                        <td scope="col">
                                                            {this.state.nombrescomplettutor}
                                                        </td>


                                                    </tr>
                                                    <tr >
                                                        <th scope="col">CORREO INSTITUCIONAL</th>
                                                        <td scope="col">
                                                            {this.state.correoTutor}
                                                        </td>

                                                    </tr>
                                                </tbody>
                                            </table>

                                        </div>



                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        )
    }
}


import axios from 'axios';
import $ from 'jquery';
import React, { Component, useState } from 'react'
import { Link } from 'react-router-dom';
import Cookies from 'universal-cookie';
import "../../services/ApiRest";
import { ApiUrl } from '../../services/ApiRest';
import { Table, Button, Container, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, ListGroupItemText } from 'reactstrap';
import swal from 'sweetalert';
import { Alert } from 'bootstrap';
import "./matricula.css";
const urlEstudianteColeg = ApiUrl + "consultarColegiturasEstudiante" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const urlEstudianteMesColeg = ApiUrl + "consultarvalormensualcolegiatura" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const mostrarCuotasColeMesUrl = ApiUrl + "listcolegmesestud" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const urlIdMatriculaPeriodo = ApiUrl + "idmatriculaperiodo" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const cookie = new Cookies();
const cedulaEstu = cookie.get("cedulaEstudiante");
const nombrescompletosc = cookie.get("nomCompleEst");
const urlEstudianteDatosCoutaMes = ApiUrl + "consultardatoscuotasmes" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const urlListMesColeg = ApiUrl + "listmesperiod1" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const urlListMesColegEst = ApiUrl + "listmesperiodestud" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const urlCuotaCarrNivelEst = ApiUrl + "consultarcuotacarrnivelest" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const urlAbonarColegiatura = ApiUrl + "abonarcolegiatura" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const urlSumTAbonosColegCuota = ApiUrl + "sumtabonoscolegcuota" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const urlSumTAbonosAprobColegCuota = ApiUrl + "sumtabonosaprobcuota" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const rutaimagen = ApiUrl + "mostarrutacomprobpensmes" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const urlAbonarColegiaturaMod = ApiUrl + "abonarcolegiaturamod" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const urlListarAbonosCuotaMesAct = ApiUrl + "listcolegmesestudcolec" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const urlSumTAbonosColegCuotaMod = ApiUrl + "sumtabonoscuotamod" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const urlPensionMes = ApiUrl + "datospensionmes" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const urlListarUltimaBecasEst = ApiUrl + "ListarUltimabecasEst" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const urlPagoColegiaturaT = ApiUrl + "pagocolegiaturat" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const urlValorTotalColegiatura = ApiUrl + "consultvalortotalcoleg" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const urlPagoTotalColegReport = ApiUrl + "pagototalcolegreport" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const urlPagoTotalColegMod = ApiUrl + "pagocolegiaturatmod" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const urlEstPagColeg = ApiUrl + "estpagocoleg" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const urlPeriodosMatriculadosEstudCarr = ApiUrl + "listarperiodosmatriculadosest" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";



export default class EstudianteColegPeriodCarr extends Component {

    constructor(props) {
        super(props)
        this.state = {
            cedula: cedulaEstu,
            nombrescompletos: nombrescompletosc,
            ciclosAcademicos: [],
            cuotascolegiaturam: [],
            valorescolegiaturam: 0,
            idPeriodo: 3,
            datoscuotames: [],
            listnombremes: [],
            fecha: "",
            carrera: [],
            colegitura: [],
            paralelo: [],
            periodos: [],
            nuevope: "",
            listacuota: [],
            idMatriculaEst: 0,
            listanivelespm: [],
            cursos: [],
            cursoMatricularse: "",
            tipoMatricula: "1",
            estadoMatricula: "1",
            costoMatricula: "100",
            estado: "",
            cantidadPago: "",
            fechaVencimiento: "2005-10-12",
            pagoId: "1",
            documentoscompl: "PENDIENTE",
            coelgiturames: 1,
            coelgituramest: 0,
            coelgituramestm: 0,
            idmatriculaperiod: 0,
            idmatriculaglobal: 0,
            estadocons: 0,
            urlComrobantebante: "",
            disabledcolegcomp: false,
            form: {
                id: '',
            },
            //variables ingreso
            //pagos parciales
            disabledformpag: true,
            disabledtransfe: true,
            numbancobeneficario: "7731051",
            modalPagoColegituraMes: false,
            comprobante: "",
            bancobeneficario: "1",
            nombremescuota: "",
            cuentadebitotransferencia: "",
            nomdueniocuentdebittransf: "",
            numdeposito: "",
            sumabonoscuota: 0,
            sumabonaprobcuot: 0,
            modalReportAbonosCuotaEst: false,
            sumt: 0,
            porcentaje_beca: 0,
            modalVisualComprobante: false,
            ruta: "",
            modalPagoColegituraMesMod: false,
            form1: {
            },
            disabledformpagmd: true,
            disabledtransfemd: true,
            disabledbottmod: true,
            numbancobeneficariomod: "7731051",
            datospensionmes: null,
            diferencuotamod: 0,
            recargoaux: 0,
            sumatotalcuotamod: 0,
            sumabonoscuotamod: 0,
            cuentadebitotransferenciamod: "",
            nomdueniocuentdebittransfmod: "",
            avisoestud: "",
            enunciadonumdeposit: "Número de depósito(número de papeleta o referencia)",
            enuncnumdeposting: "Número de depósito(número de papeleta o referencia)",
            //Pago total de colegiatura
            disabledformpagt: true,
            disabledtransfet: true,
            enuncnumdepostingt: "Número de depósito(número de papeleta o referencia)",
            numbancobeneficariot: "7731051",
            bancobeneficariot: "1",
            valorcolegtotal: 0,
            valorcolegtotalact: 0,
            hiddentabtot: true,
            hiddentabpar: false,
            listpagototal: [],
            modalPagoColegituraTotal: false,
            modalPagoColegituraTotalMod: false,
            enuncnumdepostingtmod: "Número de depósito(número de papeleta o referencia)",
            disabledformpagmdt: true,
            disabledtransfemdt: true,
            disabledbottmodtot: true,
            disabledpagotot: true,
            avisoestudpagtot: "",
            form2: {
            },
        }
        this.handleChange = this.handleChange.bind(this);
        this.mostrarModalIngresarPagoColegT = this.mostrarModalIngresarPagoColegT.bind(this);
        this.handleChangeComprobColeg = this.handleChangeComprobColeg.bind(this);
        this.handleChangeComprobColegModT = this.handleChangeComprobColegModT.bind(this);
        this.handleChangeComprobColegIndi = this.handleChangeComprobColegIndi.bind(this);
        this.handleChangeComprobColegIndiMod = this.handleChangeComprobColegIndiMod.bind(this);

        this.escFunction = this.escFunction.bind(this);
    }

    escFunction(event) {
        if (event.key === "Escape") {
            this.setState({ modalPagoColegituraMes: false });
            this.setState({ modalReportAbonosCuotaEst: false });
            this.setState({ modalPagoColegituraMesMod: false });
            this.setState({ modalVisualComprobante: false });
            this.setState({ modalPagoColegituraTotal: false });
            this.setState({ modalPagoColegituraTotalMod: false });
            this.setState({ modalVisualComprobPagTotColeg: false });
        }
    }
    handleChange = async (e) => {
        await this.setState({ [e.target.name]: e.target.value })
        this.setState({ estado: "" });
        //   this.setState({ comprobante: e.target.files });
        // this.setState({ comprobantemod: e.target.files });
        // this.setState({ comprobantet: e.target.files });
        //  this.setState({ comprobantemodt: e.target.files });

    }
    //Para cargar el archivo
    handleChangeComprobColeg = async (e) => {
        await this.setState({ [e.target.name]: e.target.value })
        this.setState({ comprobantet: e.target.files });
    }

    handleChangeComprobColegModT = async (e) => {
        await this.setState({ [e.target.name]: e.target.value })
        this.setState({ comprobantemodt: e.target.files });
    }

    handleChangeComprobColegIndi = async (e) => {
        await this.setState({ [e.target.name]: e.target.value })
        this.setState({ comprobante: e.target.files });
    }


    handleChangeComprobColegIndiMod = async (e) => {
        await this.setState({ [e.target.name]: e.target.value })
        this.setState({ comprobantemod: e.target.files });
    }

    //Muestra la tabla de abonos de la cuota//
    mostrarModalReportAbonosCuota = (registro) => {
        this.setState({ idMatriculaEst: registro.idMatricula });//id Matrícula
        this.setState({ sumatotalcuotamod: registro.valorcuota });//id Matrícula

        axios.post(mostrarCuotasColeMesUrl, {
            idMatriculaEst: registro.idMatricula,
            idCuota: registro.id,
        })
            .then(response => {
                if (response.data.success === true) {
                    const cuotascolegiaturam = response.data.data;
                    this.setState({ cuotascolegiaturam });
                }
            })
            .catch(error => {
                this.setState({ error: "No existe conexión con el servidor" });
                swal({
                    text: "No existe conexión con el servidor",
                    icon: "error",
                    button: "Aceptar",
                });
            });

        //suma total de abonos aprobados de la cuota de colegiatura//
        axios.post(urlSumTAbonosAprobColegCuota, {
            idMatriculaEst: this.state.idMatriculaEst,
            idCuota: registro.id,
        })
            .then(response => {
                if (response.data.success == true) {
                    const sumabonaprobcuotb = response.data.data;
                    this.setState({ sumabonaprobcuot: sumabonaprobcuotb });
                }
            });

        this.setState({ modalReportAbonosCuotaEst: true, form1: registro });

    }

    ocultarModalReportAbonosCuota = () => {
        this.setState({ modalReportAbonosCuotaEst: false });
    }

    componentDidMount() {

        document.addEventListener("keydown", this.escFunction, true);
        axios
            .post(urlPeriodosMatriculadosEstudCarr, {
                idCarrerae: cookie.get("idCarreraEstu"),
                idEstudiantein: cookie.get("idEstudiante")
            })
            .then(response => {
                const listaperiodos = response.data.data;
                const cant = listaperiodos.length;
                if (cant != 0) {
                    this.setState({ periodos: listaperiodos });
                    const idUltPeriod = listaperiodos[cant - 1].id_periodo_academico;
                    $(function () {
                        $("#idPeriodo").val(idUltPeriod);
                    });
                    this.setState({ idPeriodo: idUltPeriod });

                    //El valor total de la  colegiatura

                    axios.post(urlValorTotalColegiatura, {
                        idEstudianteDoc: cookie.get("idEstudiante"),
                        idPeriodoActual: idUltPeriod,
                        idCarrerae: cookie.get("idCarreraEstu")
                    })
                        .then(respuesta => {
                            if (respuesta.data.success == true) {
                                const valorcolegtotal = respuesta.data.data;
                                this.setState({ valorcolegtotal })
                            }
                        })


                    //devuelve el numero de matrícula
                    axios.post(urlIdMatriculaPeriodo, {
                        idEstudianteDoc: cookie.get("idEstudiante"),
                        idPeriodoActual: idUltPeriod,
                    })
                        .then(respuesta => {

                            if (respuesta.data.success == true) {

                                const idmatricula = respuesta.data.data;
                                this.setState({ idMatriculaEst: idmatricula });
                                
                                //Verificar primero en la tabla global
                          
                                axios.post(urlPagoTotalColegReport, {
                                    idEstudianteDoc: cookie.get("idEstudiante"),
                                    idPeriodoActual: idUltPeriod,
                                })
                                    .then(respuesta => {
                                      
                                        if (respuesta.data.success == true) {
                                            const pagtotalcoleg = respuesta.data.data;

                                            this.setState({ listpagototal: pagtotalcoleg });
                                            this.setState({ hiddentabtot: "" });
                                            this.setState({ hiddentabpar: true });
                                            this.setState({ disabledbttptc: true });
                                        }
                                        else {
                                            //listar los meses de colegiaturas  con el último período de la lista 
                                            axios.post(urlListMesColegEst, {
                                                idEstudianteDoc: cookie.get("idEstudiante"),
                                                idPeriodoActual: idUltPeriod,
                                            })
                                                .then(respuesta => {
                                                    if (respuesta.data.success == true) {
                                                        this.setState({ hiddentabtot: true });
                                                        this.setState({ hiddentabpar: "" });
                                                        this.setState({ disabledbttptc: true });

                                                        const listmes = respuesta.data.data;
                                                        this.setState({ listnombremes: listmes });
                                                    }
                                                    else {
                                                        const listmes = respuesta.data.data;
                                                        this.setState({ listnombremes: listmes });

                                                        this.setState({ hiddentabtot: "" });
                                                        this.setState({ hiddentabpar: "" });
                                                        this.setState({ disabledbttptc: false });
                                                    }
                                                })
                                            //Fin de la tabla de cuotas

                                        }
                                    })

                            } else {

                                this.setState({ estado: "¡No existe información para mostrar!" });
                                swal({
                                    text: "¡No existe información para mostrar!",
                                    icon: "info",
                                    button: {
                                        confirm: { text: 'Ubah', className: 'sweet-warning' },
                                        cancel: 'Batalkan'
                                    },
                                });
                                this.setState({ listnombremes: [] });
                                this.setState({ listpagototal: [] });
                                this.setState({ hiddentabtot: true });
                                this.setState({ hiddentabpar: true });
                                this.setState({ disabledbttptc: true });


                            }
                        })



                }
            })
    }

    handleChangePeriodo = async (e) => {


        await this.setState({ [e.target.name]: e.target.value });
        this.setState({ listnombremes: [] });
        this.setState({ listpagototal: [] });


        //devuelve el numero de matricula
        axios.post(urlIdMatriculaPeriodo, {
            idEstudianteDoc: cookie.get("idEstudiante"),
            idPeriodoActual: this.state.idPeriodo,
        })
            .then(respuesta => {
                if (respuesta.data.success == true) {
                    //Verificar primero en la tabla global
                    axios.post(urlPagoTotalColegReport, {
                        idEstudianteDoc: cookie.get("idEstudiante"),
                        idPeriodoActual: this.state.idPeriodo,
                    })
                        .then(respuesta => {
                            if (respuesta.data.success == true) {
                                const pagtotalcoleg = respuesta.data.data;

                                this.setState({ listpagototal: pagtotalcoleg });
                                this.setState({ hiddentabtot: "" });
                                this.setState({ hiddentabpar: true });
                                this.setState({ disabledbttptc: true });

                            }
                            else {
                                //


                                //listar los meses de colegiaturas  con el último período de la lista
                                axios.post(urlListMesColegEst, {
                                    idEstudianteDoc: cookie.get("idEstudiante"),
                                    idPeriodoActual: this.state.idPeriodo,
                                })
                                    .then(respuesta => {
                                        if (respuesta.data.success == true) {
                                            this.setState({ hiddentabtot: true });
                                            this.setState({ hiddentabpar: "" });
                                            this.setState({ disabledbttptc: true });
                                            const listmes = respuesta.data.data;
                                            this.setState({ listnombremes: listmes });

                                        }
                                        else {
                                            const listmes = respuesta.data.data;
                                            this.setState({ listnombremes: listmes });
                                            this.setState({ hiddentabtot: "" });
                                            this.setState({ hiddentabpar: "" });
                                            this.setState({ disabledbttptc: false });

                                        }
                                    })
                                //Fin de la tabla de cuotas
                            }
                        })

                } else {
                    this.setState({ estado: "¡No existe información para mostrar!" });
                    swal({
                        text: "¡No existe información para mostrar!",
                        icon: "info",
                        button: {
                            confirm: { text: 'Ubah', className: 'sweet-warning' },
                            cancel: 'Batalkan'
                        },
                    });
                    this.setState({ listnombremes: [] });
                    this.setState({ listpagototal: [] });
                    this.setState({ hiddentabtot: true });
                    this.setState({ hiddentabpar: true });
                    this.setState({ disabledbttptc: true });

                }
            })

    }
    //****PAGO PARCIAL*****/
    handleChangeFormaPagoEfect = async (e) => {
        //e.preventDefault();
        await this.setState({ [e.target.name]: e.target.value });
        //si es  un efectivo
        if (this.state.idTipoTransaccion == 3) {

            this.setState({ disabledct: this.state.disabledtransfe });
            this.setState({ disabledformpagd: this.state.disabledformpag });
            this.setState({ enuncnumdeposting: "Número de depósito(número de papeleta o referencia)" });

        }
        if (this.state.idTipoTransaccion == 2) {

            this.setState({ disabledct: !this.state.disabledtransfe });
            this.setState({ disabledformpagd: !this.state.disabledformpag });
            this.setState({ enuncnumdeposting: "Número de comprobante" });

        }

        if (this.state.idTipoTransaccion == 1) {

            this.setState({ disabledct: this.state.disabledtransfe });
            this.setState({ disabledformpagd: !this.state.disabledformpag });
            this.setState({ enuncnumdeposting: "Número de depósito(número de papeleta o referencia)" });
        }
    }
    handleChangeCuentaBeneficiario = async (e) => {
        //e.preventDefault();
        await this.setState({ [e.target.name]: e.target.value });
        this.setState({ disablednumbanbened: !this.state.disablednumbanbene });
        if (this.state.bancobeneficario == 1) {
            $(function () {
                $("#numbancobeneficario").val("7731051");
            });
            this.setState({ numbancobeneficario: "7731051" });
        }
        else {
            if (this.state.bancobeneficario == 2) {
                $(function () {
                    $("#numbancobeneficario").val("3257594104");

                });
                this.setState({ numbancobeneficario: "3257594104" });
            }
            else {
                if (this.state.bancobeneficario == 3) {

                    $(function () {
                        $("#numbancobeneficario").val("2100271381");
                    });
                    this.setState({ numbancobeneficario: "2100271381" });
                }
                else {
                    $(function () {
                        $("#numbancobeneficario").val("06073611");
                    });
                    this.setState({ numbancobeneficario: "06073611" });
                }

            }
        }
    }
    mostrarModalIngresarPagoColeg = (registro) => {
        this.setState({ modalPagoColegituraMes: true, form: registro });
        $(function () {
            $("#bancobeneficario").val("1");
            $("#numbancobeneficario").val("7731051");
            $("#numcuota").val(registro.id);
            $("#nombremescuota").val(registro.nombremes);
            $("#fechmaxpagocoleg").val(registro.datemes);
            $("#recargopagocoleg").val(registro.recargo);
        });
        this.setState({ numcuota: registro.id });
        this.setState({ nombremescuota: registro.nombremes });
        this.setState({ fechmaxpagocoleg: registro.datemes });
        this.setState({ recargopagocoleg: registro.recargo });
        this.setState({ sumatotalcuota: registro.valorcuota });


        //suma total de abonos de la cuota de colegiatura
        /*   axios.post(urlSumTAbonosColegCuota, {
               idMatriculaEst: cookie.get("idMatriculas"),
               idCuota: registro.id,
           })
               .then(response => {
                   if (response.data.success == true) {
                       const sumabonoscuotar = response.data.data;
                       alert(sumabonoscuotar);
                       this.setState({ sumabonoscuota: sumabonoscuotar });
                   }
               });*/
        //suma total de abonos aprobados de la cuota de colegiatura
        axios.post(urlSumTAbonosAprobColegCuota, {
            idMatriculaEst: registro.idMatricula,
            idCuota: registro.id,
        })
            .then(response => {
                if (response.data.success == true) {
                    const sumabonaprobcuotb = response.data.data;
                    this.setState({ sumabonoscuota: sumabonaprobcuotb });
                }
            });


    }

    ocultarModalIngresoColegiatura = () => {
        this.setState({ modalPagoColegituraMes: false });
    }
    //abonar el  pago de la  colegiatura mes
    abonarColegiaturaMes = (datos) => {

        /*var diferencuota = this.state.sumatotalcuota - this.state.sumabonoscuota;
        diferencuota = diferencuota.toFixed(2);*/
        var cantidadPagocoleg = parseFloat(this.state.cantidadPago);

        var diferencuota = 2000;

        if (cantidadPagocoleg <= diferencuota) {
            var vidFileLength = $("#comprobante")[0].files.length;
            if (((this.state.comprobante == null) || (vidFileLength === 0)) && (this.state.idTipoTransaccion != 3)) {

                swal({
                    text: "No se ha seleccionado ningun archivo",
                    icon: "error",
                    button: "Aceptar",
                });
            } else {
                var comprobantecolec = "";
                var auxbanbenef = "";
                var auxctabanbenef = "";
                if (this.state.idTipoTransaccion == 3) {
                    comprobantecolec = null;
                    auxbanbenef = "";
                    auxctabanbenef = "";
                }
                else {
                    comprobantecolec = this.state.comprobante[0];
                    auxbanbenef = this.state.bancobeneficario;
                    auxctabanbenef = this.state.numbancobeneficario;
                }
                //Validar el comprobante
                var fileInput = document.getElementById('comprobante');
                var filePath = fileInput.value;
                var allowedExtensions = /(.jpg|.jpeg|.png)$/i;
                if ((!allowedExtensions.exec(filePath)) && (this.state.idTipoTransacciont != 3)) {
                    swal({
                        text: "El archivo debe ser formato:.jpeg/.jpg/.png",
                        icon: "error",
                        button: "Aceptar",

                    });
                }
                else {

                    const f = new FormData();
                    f.append("url_comprobante", comprobantecolec);
                    f.append("num_depositof", this.state.numdeposito);
                    f.append("fecha_depositof", this.state.fechadeposito);
                    f.append("valor_canceladof", this.state.cantidadPago);
                    f.append("cuenta_debito_transferenciaf", this.state.cuentadebitotransferencia);
                    f.append("nom_dueno_cuenta_transff", this.state.nomdueniocuentdebittransf);
                    f.append("nombre_banco_beneficiariof", auxbanbenef);
                    f.append("cuenta_beneficiariaf", auxctabanbenef);
                    f.append("descrip_pension_mesf", this.state.nombremescuota);
                    f.append("tipo_pagof", this.state.idTipoTransaccion);
                    f.append("cuota_pension_mesf", this.state.numcuota);
                    f.append("estado_pension_mesf", 2); //2: En Proceso
                    f.append("matricula_idf", this.state.idMatriculaEst);
                    f.append("recargo_pension_mesf", this.state.recargopagocoleg);
                    f.append("fecha_venc_pension_mesf", this.state.fechmaxpagocoleg);

                    const config = {
                        headers: { 'content-type': 'multipart/form-data' }
                    }

                    axios.post(urlAbonarColegiatura, f, {
                        idprobarcarrera: this.state.idCarrera
                    }, config)
                        .then(response => {
                            console.log(response)
                            if (response.data.success == true) {
                                this.setState({ estado: "Su comprobante ha sido enviado para revisión" });
                                swal({
                                    text: "Su comprobante ha sido enviado para revisión",
                                    icon: "success",
                                    button: "Aceptar",
                                });
                                $(function () {
                                    $("#bottpagtotcolegocu").text('');
                                });

                                //actualiza el reporte
                                axios.post(urlListMesColegEst, {
                                    idEstudianteDoc: cookie.get("idEstudiante"),
                                    idPeriodoActual: this.state.idPeriodo
                                })
                                    .then(respuesta => {
                                        if (respuesta.data.success == true) {
                                            const listmes = respuesta.data.data;
                                            this.setState({ listnombremes: listmes });

                                        }
                                        else {
                                            if (respuesta.data.message == "vacio") {


                                                this.setState({ error: "¡No existe información para mostrar!" });
                                                swal({
                                                    text: "¡No existe información para mostrar!",
                                                    icon: "info",
                                                    button: {
                                                        confirm: { text: 'Ubah', className: 'sweet-warning' },
                                                        cancel: 'Batalkan'
                                                    },

                                                });

                                            }
                                            else {
                                                this.setState({ error: "¡Error no identificado!" });
                                                swal({
                                                    text: "Error no identificado",
                                                    icon: "error",
                                                    button: "Aceptar",


                                                });
                                            }

                                        }

                                    })


                                this.setState({ modalPagoColegituraMes: false });
                            }
                            else {
                                if (response.data.message == "Formato incorrecto") {
                                    this.setState({ estado: "El archivo seleccionado no corresponde al formato: jpg o png" });
                                    swal({
                                        text: "El archivo seleccionado no corresponde al formato: jpg o png",
                                        icon: "error",
                                        button: "Aceptar",
                                    });
                                }
                                else {
                                    this.setState({ estado: "Valores no registrados" });
                                    swal({
                                        text: "Valores no registrados",
                                        icon: "error",
                                        button: "Aceptar",
                                    });
                                }
                                this.setState({ modalPagoColegituraMes: false });
                            }
                        })
                        .catch(error => {
                            console.log(error);
                            this.setState({ estado: "Error No se pudo conectar con el servidor" });
                            window.location.reload(true);
                        })
                }
            }
        }
        else {
            swal({
                text: "La cantidad ingresada supera al valor permitido",
                icon: "error",
                button: "Aceptar",
            });
        }
    }

    mostrarModalComprobanteAbonoCuota = (registro) => {
        axios.post(rutaimagen, {

            nombrecomprobante: "colegiaturas/" + registro.comprobante_pens,
        })
            .then(res => {
                const ruta = res.data.data;
                this.setState({ ruta });
            })
        this.setState({ modalVisualComprobante: true, form: registro });
    }



    ocultarModalVisualComprobante = () => {
        this.setState({ modalVisualComprobante: false });
        //console.log( e.target.value);
    }
    //ocultar el modal

    mostrarModalPagoColegituraMesMod = (registro) => {

        $(function () {
            $("#idTipoTransaccionmod").val(registro.tipo_deposito_pens);
            $("#numdepositomod").val(registro.num_deposito_pens);
            $("#fechadepositomod").val(registro.fecha_deposito_pens);
            $("#cantidadPagomod").val(registro.cantidad_abono_pens);
            $("#cuentadebitotransferenciamod").val(registro.cuenta_debito_transf_pens);
            $("#nomdueniocuentdebittransfmod").val(registro.nom_dueno_cuenta_transf_pens);
            $("#bancobeneficariomod").val(registro.nombre_banco_benef_pens);
            $("#numbancobeneficariomod").val(registro.cuenta_beneficiaria_pens);
        });

        this.setState({ modalPagoColegituraMesMod: true, form: registro });

        if (registro.tipo_deposito_pens == 3) {
            this.setState({ disabledformpagdmod: this.state.disabledformpagmd });
            this.setState({ disabledctmod: this.state.disabledtransfemd });
            this.setState({ enunciadonumdeposit: "Número de depósito(número de papeleta o referencia)" });
        }
        else
            if (registro.tipo_deposito_pens == 1) {

                this.setState({ disabledctmod: this.state.disabledtransfemd });
                this.setState({ disabledformpagdmod: !this.state.disabledformpagmd });
                this.setState({ enunciadonumdeposit: "Número de depósito(número de papeleta o referencia)" });
            }
            else
                if (registro.tipo_deposito_pens == 2) {

                    this.setState({ enunciadonumdeposit: "Número de comprobante" });
                    this.setState({ disabledctmod: !this.state.disabledtransfemd });
                    this.setState({ disabledformpagdmod: !this.state.disabledformpagmd });

                }

        if (registro.estado_abono_pens != 3) {

            this.setState({ disabledbttmod: this.state.disabledbottmod });

            $(function () {
                $("#bottmodif").text('');
            });
        }
        else {
            this.setState({ disabledbttmod: !this.state.disabledbottmod });

        }
        //mensaje  de aviso a lado del botón modificar
        this.setState({ avisoestud: "En proceso de verificación" });
        if ((registro.estado_abono_pens == 1) || (registro.estado_abono_pens == 4) || (registro.estado_abono_pens == 5)) {

            this.setState({ avisoestud: "En proceso de verificación" });
        }
        else {

            this.setState({ avisoestud: "" });
        }


        this.setState({ numdepositomod: registro.num_deposito_pens });
        this.setState({ fechadepositomod: registro.fecha_deposito_pens });
        this.setState({ cantidadPagomod: registro.cantidad_abono_pens });
        this.setState({ cuentadebitotransferenciamod: registro.cuenta_debito_transf_pens });
        this.setState({ nomdueniocuentdebittransfmod: registro.nom_dueno_cuenta_transf_pens });
        this.setState({ bancobeneficariomod: registro.nombre_banco_benef_pens });
        this.setState({ numbancobeneficariomod: registro.cuenta_beneficiaria_pens });
        this.setState({ idTipoTransaccionmod: registro.tipo_deposito_pens });

    }

    ocultarModalPagoColegituraMesMod = () => {
        this.setState({ modalPagoColegituraMesMod: false });
    }

    handleChangeFormaPagoEfectMod = async (e) => {
        //e.preventDefault();
        await this.setState({ [e.target.name]: e.target.value });

        if (this.state.idTipoTransaccionmod == 3) {

            this.setState({ disabledctmod: this.state.disabledtransfemd });
            this.setState({ disabledformpagdmod: this.state.disabledformpagmd });
            this.setState({ enunciadonumdeposit: "Número de depósito(número de papeleta o referencia)" });

        }
        if (this.state.idTipoTransaccionmod == 2) {

            this.setState({ disabledctmod: !this.state.disabledtransfemd });
            this.setState({ disabledformpagdmod: !this.state.disabledformpagmd });
            this.setState({ enunciadonumdeposit: "Número de comprobante" });

        }

        if (this.state.idTipoTransaccionmod == 1) {

            this.setState({ disabledctmod: this.state.disabledtransfemd });
            this.setState({ disabledformpagdmod: !this.state.disabledformpagmd });
            this.setState({ enunciadonumdeposit: "Número de depósito(número de papeleta o referencia)" });
        }
    }
    //modificar los datos del pago  de abono de matrícula
    modificarAbonoCuotaMes = (datos) => {
        var combmsmalert = "¿Está seguro que desea modificar los datos?"
        swal({
            title: "Advertencia",
            text: combmsmalert,
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    //----------------------------------------
                    //*Definir la fecha actual
                    var fecha = new Date();
                    var dia = fecha.getDate();
                    var mes = (fecha.getMonth() + 1);
                    var anio = fecha.getFullYear();
                    var fechaMatricula = `${anio}-${mes}-${dia}`;
                    //define mes 
                    var nombremes = mes + "";
                    var numeroCaracteresmes = nombremes.length;
                    if (numeroCaracteresmes == 1) {
                        var mesfecha = "0" + nombremes;
                    } else {
                        mesfecha = nombremes;
                    }
                    //define dia
                    var nombredia = dia + "";
                    var numeroCaracteresdia = nombredia.length;
                    if (numeroCaracteresdia == 1) {
                        var diafecha = "0" + nombredia;
                    } else {
                        diafecha = nombredia;
                    }
                    var fechaactualgeneradamod = anio + "-" + mesfecha + "-" + diafecha;

                    //  var diferencuota = this.state.sumatotalcuotamod - this.state.sumabonoscuotamod;
                    var diferencuota = 2000;

                    //--------------------------------------------

                    if (this.state.cantidadPagomod <= diferencuota) {

                        var vidFileLength = $("#comprobantemod")[0].files.length;
                        var cargarArchivo = 0;
                        var comprobantenuevo = "";
                        var auxbanbenef = "";
                        var auxctabanbenef = "";
                        if ((vidFileLength === 0)) {
                            cargarArchivo = 0;
                            comprobantenuevo = null;

                            if (this.state.idTipoTransaccionmod == 3) {
                                auxbanbenef = "";
                                auxctabanbenef = "";
                            }
                            else {
                                auxbanbenef = this.state.bancobeneficariomod;
                                auxctabanbenef = this.state.numbancobeneficariomod;
                            }
                        } else {
                            cargarArchivo = 1;
                            comprobantenuevo = this.state.comprobantemod[0];
                            auxbanbenef = this.state.bancobeneficariomod;
                            auxctabanbenef = this.state.numbancobeneficariomod;
                        }

                        //Validar el comprobante
                        var fileInput = document.getElementById('comprobantemod');
                        var filePath = fileInput.value;
                        var allowedExtensions = /(.jpg|.jpeg|.png)$/i;
                        if ((!allowedExtensions.exec(filePath)) && (this.state.idTipoTransacciont != 3)) {
                            swal({
                                text: "El archivo debe ser formato:.jpeg/.jpg/.png",
                                icon: "error",
                                button: "Aceptar",

                            });
                        }
                        else {
                            const f = new FormData();
                            f.append("url_comprobante", comprobantenuevo);
                            f.append("num_depositof", this.state.numdepositomod);
                            f.append("fecha_depositof", this.state.fechadepositomod);
                            f.append("valor_canceladof", this.state.cantidadPagomod);
                            f.append("cuenta_debito_transferenciaf", this.state.cuentadebitotransferenciamod);
                            f.append("nom_dueno_cuenta_transff", this.state.nomdueniocuentdebittransfmod);
                            f.append("cuenta_beneficiariaf", auxctabanbenef);
                            f.append("nombre_banco_beneficiariof", auxbanbenef);
                            f.append("tipo_pagof", this.state.idTipoTransaccionmod);
                            f.append("fecha_reg_depositof", fechaactualgeneradamod);
                            //  f.append("estado_pension_mesf", 2); //2: En Proceso
                            f.append("cargarchivomod", cargarArchivo);
                            //   f.append("recargo_pension_mesf", 0);
                            f.append("idpagoabonopenmes", datos.id_pension_abono_mes);

                            const config = {
                                headers: { 'content-type': 'multipart/form-data' }
                            }

                            axios
                                .post(urlAbonarColegiaturaMod, f, config)

                                .then(response => {
                                    if (response.data.success == true) {

                                        this.setState({ estado: "Datos modificados correctamente" });
                                        swal({
                                            text: "Datos modificados correctamente",
                                            icon: "success",
                                            button: "Aceptar",

                                        });

                                        //lista de abonos de la cuota
                                        axios.post(urlListarAbonosCuotaMesAct, {
                                            idPensionMes: datos.pension_mes_id,
                                        })
                                            .then(response => {
                                                if (response.data.success === true) {
                                                    const cuotascolegiaturam = response.data.data;
                                                    this.setState({ cuotascolegiaturam });
                                                }
                                            })
                                            .catch(error => {
                                                this.setState({ error: "No existe conexión con el servidor" });
                                                swal({
                                                    text: "No existe conexión con el servidor",
                                                    icon: "error",
                                                    button: "Aceptar",
                                                });
                                            });
                                        this.setState({ modalPagoColegituraMesMod: false });

                                    }
                                    else {

                                        if (response.data.status == "failed") {
                                            this.setState({ estado: "Formato del archivo incorrecto" });
                                            swal({
                                                text: "El archivo seleccionado no corresponde al formato: jpg o png",
                                                icon: "error",
                                                button: "Aceptar",
                                            });
                                        }
                                        else {
                                            this.setState({ estado: "Valores no registrados" });
                                            swal({
                                                text: "Valores no registrados",
                                                icon: "error",
                                                button: "Aceptar",

                                            });
                                        }
                                        this.setState({ modalPagoColegituraMesMod: false });
                                    }
                                })
                                .catch(error => {
                                    console.log(error);

                                    this.setState({ estado: "Error No se pudo conectar con el servidor" });
                                    window.location.reload(true);
                                })

                        }
                    }
                    else {
                        swal({
                            text: "La cantidad ingresada supera al valor permitido",
                            icon: "error",
                            button: "Aceptar",
                        });
                    }

                } else {
                    swal({
                        text: "¡Haz denegado la acción!",
                        icon: "warning",
                        button: "Aceptar",
                    });
                }
            });
    }
    handleChangeCuentaBeneficiarioMod = async (e) => {
        //e.preventDefault();
        await this.setState({ [e.target.name]: e.target.value });
        if (this.state.bancobeneficariomod == 1) {
            $(function () {
                $("#numbancobeneficariomod").val("7731051");
            });
            this.setState({ numbancobeneficariomod: "7731051" });
        }
        else {
            if (this.state.bancobeneficariomod == 2) {
                $(function () {
                    $("#numbancobeneficariomod").val("3257594104");

                });
                this.setState({ numbancobeneficariomod: "3257594104" });
            }
            else {


                if (this.state.bancobeneficariomod == 3) {

                    $(function () {
                        $("#numbancobeneficariomod").val("2100271381");
                    });
                    this.setState({ numbancobeneficariomod: "2100271381" });
                }
                else {
                    $(function () {
                        $("#numbancobeneficariomod").val("06073611");
                    });
                    this.setState({ numbancobeneficariomod: "06073611" });
                }

            }
        }
    }
    //****PAGO TOTAL****
    //Muestar del pago total
    mostrarModalIngresarPagoColegT = () => {
        this.setState({ modalPagoColegituraTotal: true });

        $(function () {
            $("#bancobeneficariot").val("1");
            $("#numbancobeneficariot").val("7731051");
        });

        this.setState({ numdepositot: null });
        this.setState({ fechadepositot: null });
        this.setState({ cantidadPagot: 0.00 });
        this.setState({ ctadebitotransft: null });
        this.setState({ nomduenioctadebittransft: null });
        this.setState({ idTipoTransacciont: 1 });
        this.setState({ numbancobeneficariot: "7731051" });
        this.setState({ bancobeneficariot: "1" });

        //calculo del porcentaje de las becas
        var valorcancelar = 0;
        if ((this.state.porcentaje_beca != 0)) {
            valorcancelar = (this.state.valorcolegtotal - ((this.state.valorcolegtotal * this.state.porcentaje_beca) / 100));
        }
        else {
            valorcancelar = (this.state.valorcolegtotal - ((this.state.valorcolegtotal * 7) / 100));
        }

        this.setState({ valorcolegtotalact: valorcancelar });


    }

    ocultarModalIngresoColegiaturaT = () => {
        this.setState({ modalPagoColegituraTotal: false });
    }
    //Deshabilitar los botones segun forma de pago(colegiatura total)

    handleChangeFormaPagoEfectT = async (e) => {
        //e.preventDefault();
        await this.setState({ [e.target.name]: e.target.value });
        if (this.state.idTipoTransacciont == 3) {

            this.setState({ disabledctt: this.state.disabledtransfet });
            this.setState({ disabledformpagdt: this.state.disabledformpagt });
            this.setState({ enuncnumdepostingt: "Número de depósito(número de papeleta o referencia)" });

        }
        if (this.state.idTipoTransacciont == 2) {

            this.setState({ disabledctt: !this.state.disabledtransfet });
            this.setState({ disabledformpagdt: !this.state.disabledformpagt });
            this.setState({ enuncnumdepostingt: "Número de comprobante" });
        }

        if (this.state.idTipoTransacciont == 1) {

            this.setState({ disabledctt: this.state.disabledtransfet });
            this.setState({ disabledformpagdt: !this.state.disabledformpagt });
            this.setState({ enuncnumdepostingt: "Número de depósito(número de papeleta o referencia)" });
        }
    }
    //Para los bancos beneficiarios (colegiatura total)
    handleChangeCuentaBeneficiarioT = async (e) => {
        //e.preventDefault();
        await this.setState({ [e.target.name]: e.target.value });
        if (this.state.bancobeneficariot == 1) {
            $(function () {
                $("#numbancobeneficariot").val("7731051");
            });
            this.setState({ numbancobeneficariot: "7731051" });
        }
        else {
            if (this.state.bancobeneficariot == 2) {
                $(function () {
                    $("#numbancobeneficariot").val("3257594104");

                });
                this.setState({ numbancobeneficariot: "3257594104" });
            }
            else {

                if (this.state.bancobeneficariot == 3) {

                    $(function () {
                        $("#numbancobeneficariot").val("2100271381");
                    });
                    this.setState({ numbancobeneficariot: "2100271381" });
                }
                else {
                    $(function () {
                        $("#numbancobeneficariot").val("06073611");
                    });
                    this.setState({ numbancobeneficariot: "06073611" });
                }


            }
        }
    }
    abonarColegiaturaTotal = () => {
        var combmsmalert = "¿Está seguro que desea registrar el pago total de la colegiatura?"
        swal({
            title: "Advertencia",
            text: combmsmalert,
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {

                    var valorcancelar = 2000;
                    /*if ((this.state.porcentaje_beca != 0)) {
                        valorcancelar = (this.state.valorcolegtotal - ((this.state.valorcolegtotal * this.state.porcentaje_beca) / 100));
                    }
                    else {
                        valorcancelar = (this.state.valorcolegtotal - ((this.state.valorcolegtotal * 10) / 100));
                    }*/
                    //Convertir
                    var cantidadPagotaux = parseFloat(this.state.cantidadPagot);
                    if (cantidadPagotaux <= valorcancelar) {
                        var vidFileLength = $("#comprobantet")[0].files.length;
                        if (((this.state.comprobantet == null) || (vidFileLength === 0)) && (this.state.idTipoTransacciont != 3)) {

                            swal({
                                text: "No se ha seleccionado ningun archivo",
                                icon: "error",
                                button: "Aceptar",
                            });
                        } else {
                            var comprobantecolec = "";
                            var auxbanbenef = "";
                            var auxctabanbenef = "";
                            if (this.state.idTipoTransacciont == 3) {
                                comprobantecolec = null;
                                auxbanbenef = "";
                                auxctabanbenef = "";
                            }
                            else {
                                comprobantecolec = this.state.comprobantet[0];
                                auxbanbenef = this.state.bancobeneficariot;
                                auxctabanbenef = this.state.numbancobeneficariot;
                            }
                            //Validar el comprobante
                            var fileInput = document.getElementById('comprobantet');
                            var filePath = fileInput.value;
                            var allowedExtensions = /(.jpg|.jpeg|.png)$/i;
                            if ((!allowedExtensions.exec(filePath)) && (this.state.idTipoTransacciont != 3)) {
                                swal({
                                    text: "El archivo debe ser formato:.jpeg/.jpg/.png",
                                    icon: "error",
                                    button: "Aceptar",
                                });
                            }
                            else {

                                const f = new FormData();
                                f.append("url_comprobantet", comprobantecolec);
                                f.append("num_depositot", this.state.numdepositot);
                                f.append("fecha_depositot", this.state.fechadepositot);
                                f.append("valor_canceladot", this.state.cantidadPagot);
                                f.append("cuenta_debito_transft", this.state.ctadebitotransft);
                                f.append("nom_dueno_cta_transft", this.state.nomduenioctadebittransft);
                                f.append("nombre_banco_beneft", auxbanbenef);
                                f.append("cuenta_beneft", auxctabanbenef);
                                f.append("tipo_pagot", this.state.idTipoTransacciont);
                                f.append("estado_pagcolegt", 1);
                                f.append("matricula_idt", this.state.idMatriculaEst);
                                const config = {
                                    headers: { 'content-type': 'multipart/form-data' }
                                }
                                axios.post(urlPagoColegiaturaT, f, {
                                    idprobarcarrera: this.state.idCarrera
                                }, config)
                                    .then(response => {

                                        if (response.data.success == true) {

                                            this.setState({ estado: "Su comprobante ha sido enviado para revisión" });

                                            swal({
                                                text: "Su comprobante ha sido enviado para revisión",
                                                icon: "success",
                                                button: "Aceptar",
                                            });
                                            $(function () {
                                                $("#bottpagtotcolegocu").text('');
                                            });
                                            this.setState({ modalPagoColegituraTotal: false });


                                            axios.post(urlPagoTotalColegReport, {
                                                idEstudianteDoc: cookie.get("idEstudiante"),
                                                idPeriodoActual: this.state.idPeriodo,
                                            })
                                                .then(respuesta => {
                                                    if (respuesta.data.success == true) {
                                                        const pagtotalcoleg = respuesta.data.data;
                                                        this.setState({ listpagototal: pagtotalcoleg });
                                                        this.setState({ hiddentabtot: "" });
                                                        this.setState({ hiddentabpar: true });
                                                    }
                                                    else {
                                                        //
                                                        this.setState({ hiddentabtot: true });
                                                        this.setState({ hiddentabpar: "" });
                                                    }
                                                })

                                        }
                                        else {
                                            if (response.data.message == "Formato incorrecto") {
                                                this.setState({ estado: "El archivo seleccionado no corresponde al formato: jpg o png" });
                                                swal({
                                                    text: "El archivo seleccionado no corresponde al formato: jpg o png",
                                                    icon: "error",
                                                    button: "Aceptar",
                                                });
                                            }
                                            else {


                                                if (response.data.message == "existe") {
                                                    this.setState({ estado: "El pago ya se encuentra enviado" });
                                                    swal({
                                                        text: "El pago ya se encuentra enviado",
                                                        icon: "info",
                                                        button: "Aceptar",
                                                    });
                                                }
                                                else {

                                                    this.setState({ estado: "Valores no registrados" });
                                                    swal({
                                                        text: "Valores no registrados",
                                                        icon: "error",
                                                        button: "Aceptar",
                                                    });
                                                }

                                            }

                                            this.setState({ modalPagoColegituraTotal: false });
                                        }

                                    })
                                    .catch(error => {
                                        console.log(error);
                                        this.setState({ estado: "Error, No se pudo conectar con el servidor" });
                                        //window.location.reload(true);
                                    })
                            }
                        }
                    }
                    else {
                        swal({
                            text: "La cantidad ingresada supera al valor permitido",
                            icon: "error",
                            button: "Aceptar",
                        });
                    }

                } else {
                    swal({
                        text: "¡Haz denegado la acción!",
                        icon: "warning",
                        button: "Aceptar",
                    });
                }
            });
    }


    mostrarModalPagoColegituraTotalMod = (registro) => {
        var valorCancelModalMT = 0;
        if ((this.state.porcentaje_beca != 0)) {
            valorCancelModalMT = (this.state.valorcolegtotal - ((this.state.valorcolegtotal * this.state.porcentaje_beca) / 100));
        }
        else {
            valorCancelModalMT = (this.state.valorcolegtotal - ((this.state.valorcolegtotal * 10) / 100));
        }

        this.setState({ valorCancelModalMT });

        $(function () {
            $("#idTipoTransaccionmodt").val(registro.tipo_deposit_penst);
            $("#numdepositomodt").val(registro.num_deposito_penst);
            $("#fechadepositomodt").val(registro.fecha_deposit_penst);
            $("#cantidadPagomodt").val(registro.cantidad_penst);
            $("#ctadebitotransfemodt").val(registro.cuenta_deb_transf_penst);
            $("#nomduenctadebittransfmodt").val(registro.nom_dueno_cuenta_transf_penst);
            $("#bancobeneficariomodt").val(registro.nombre_banc_benef_penst);
            $("#numbancobenefmodt").val(registro.cuenta_benef_penst);
        });

        this.setState({ modalPagoColegituraTotalMod: true, form2: registro });

        if (registro.tipo_deposit_penst == 3) {
            this.setState({ disabledformpagdmodtt: this.state.disabledformpagmdt });
            this.setState({ disabledctmodtt: this.state.disabledtransfemdt });
            this.setState({ enuncnumdepostingtmod: "Número de depósito(número de papeleta o referencia)" });
        }
        else
            if (registro.tipo_deposit_penst == 1) {

                this.setState({ disabledctmodtt: this.state.disabledtransfemdt });
                this.setState({ disabledformpagdmodtt: !this.state.disabledformpagmdt });
                this.setState({ enuncnumdepostingtmod: "Número de depósito(número de papeleta o referencia)" });
            }
            else
                if (registro.tipo_deposit_penst == 2) {
                    this.setState({ enuncnumdepostingtmod: "Número de comprobante" });
                    this.setState({ disabledctmodtt: !this.state.disabledtransfemdt });
                    this.setState({ disabledformpagdmodtt: !this.state.disabledformpagmdt });
                }

        if (registro.estado_penst != 3) {
            this.setState({ disabledbttmodtot: this.state.disabledbottmodtot });
            $(function () {
                $("#bottmodiftot").text('');
            });
        }
        else {
            this.setState({ disabledbttmodtot: !this.state.disabledbottmodtot });
        }
        //mensaje  de aviso a lado del botón modificar
        this.setState({ avisoestudpagtot: "En proceso de verificación" });
        if ((registro.estado_penst == 1) || (registro.estado_penst == 4) || (registro.estado_penst == 5)) {

            this.setState({ avisoestudpagtot: "En proceso de verificación" });
        }
        else {

            this.setState({ avisoestudpagtot: "" });
        }

        /*
        //Determinar si tiene o no  recargo el abono de la cuota
        /*axios.post(urlPensionMes, {
            idPensionMesMod: registro.pension_mes_id,
        })
            .then(response => {
                if (response.data.success == true) {
                    const pensionmes = response.data.data;
                    // this.setState({ datospensionmes: pensionmes });
                    var fechaMaxPago = pensionmes[0].fecha_venc_pension_mes;
                    var estadopension = pensionmes[0].estado_pension_mes;
                    var recargopension = pensionmes[0].recargo_pension_mes;

                    var fechaconsulta = new Date(fechaMaxPago);
                    var mesconsulta = fechaconsulta;
                    const fechahoy = Date.now();
                    const hoy = new Date(fechahoy);
                    var meshoy = hoy;
                    var auxrecargo = 0;
                    if (mesconsulta <= meshoy) {
                        if (fechaconsulta < fechahoy && estadopension != 4) {
                            auxrecargo = 1;
                            this.setState({ recargoaux: auxrecargo });
                        }
                        if (fechaconsulta < fechahoy && estadopension == 4 && recargopension == 1) {
                            auxrecargo = 1;
                            this.setState({ recargoaux: auxrecargo });
                        }
                        if (recargopension == 1) {
                            auxrecargo = 1;
                            this.setState({ recargoaux: auxrecargo });
                        }


                    } else {
                        this.setState({ recargoaux: auxrecargo });

                    }

                    //----------------------------------------------
                    axios.post(urlSumTAbonosColegCuotaMod, {
                        idPensionMesMod: registro.pension_mes_id,
                    })
                        .then(response => {
                            if (response.data.success == true) {
                                const sumabonoscuotamodr = response.data.data;

                                this.setState({ sumabonoscuotamod: sumabonoscuotamodr });
                            }
                        });
                    //---------------------------------------------
                    var sumatotalcuota = 0;
                    if (this.state.recargoaux == 1) {
                        sumatotalcuota = this.state.valorescolegiaturam + 5;
                        this.setState({ sumatotalcuotamod: sumatotalcuota });

                    } else {
                        sumatotalcuota = this.state.valorescolegiaturam;
                        this.setState({ sumatotalcuotamod: sumatotalcuota });
                    }


                }
            });*/
        //colocar en los state
        this.setState({ idTipoTransaccionmodt: registro.tipo_deposit_penst });
        this.setState({ numdepositomodt: registro.num_deposito_penst });
        this.setState({ fechadepositomodt: registro.fecha_deposit_penst });
        this.setState({ cantidadPagomodt: registro.cantidad_penst });
        this.setState({ ctadebitotransfemodt: registro.cuenta_deb_transf_penst });
        this.setState({ nomduenctadebittransfmodt: registro.nom_dueno_cuenta_transf_penst });
        this.setState({ bancobeneficariomodt: registro.nombre_banc_benef_penst });
        this.setState({ numbancobenefmodt: registro.cuenta_benef_penst });



    }




    ocultarModalPagoColegTotalMod = () => {

        this.setState({ modalPagoColegituraTotalMod: false });
    }

    handleChangeFormaPagoEfectModT = async (e) => {
        //e.preventDefault();
        await this.setState({ [e.target.name]: e.target.value });
        if (this.state.idTipoTransaccionmodt == 3) {
            this.setState({ disabledctmodtt: this.state.disabledtransfemd });
            this.setState({ disabledformpagdmodtt: this.state.disabledformpagmd });
            this.setState({ enuncnumdepostingtmod: "Número de depósito(número de papeleta o referencia)" });
        }
        if (this.state.idTipoTransaccionmodt == 2) {
            this.setState({ disabledctmodtt: !this.state.disabledtransfemd });
            this.setState({ disabledformpagdmodtt: !this.state.disabledformpagmd });
            this.setState({ enuncnumdepostingtmod: "Número de comprobante" });
        }
        if (this.state.idTipoTransaccionmodt == 1) {
            this.setState({ disabledctmodtt: this.state.disabledtransfemd });
            this.setState({ disabledformpagdmodtt: !this.state.disabledformpagmd });
            this.setState({ enuncnumdepostingtmod: "Número de depósito(número de papeleta o referencia)" });
        }
    }

    handleChangeCuentaBeneficiarioModT = async (e) => {
        //e.preventDefault();
        await this.setState({ [e.target.name]: e.target.value });
        if (this.state.bancobeneficariomodt == 1) {
            $(function () {
                $("#numbancobenefmodt").val("7731051");
            });
            this.setState({ numbancobenefmodt: "7731051" });
        }
        else {
            if (this.state.bancobeneficariomodt == 2) {
                $(function () {
                    $("#numbancobenefmodt").val("3257594104");

                });
                this.setState({ numbancobenefmodt: "3257594104" });
            }
            else {

                if (this.state.bancobeneficariomodt == 3) {

                    $(function () {
                        $("#numbancobenefmodt").val("2100271381");
                    });
                    this.setState({ numbancobenefmodt: "2100271381" });
                }
                else {
                    $(function () {
                        $("#numbancobenefmodt").val("06073611");
                    });
                    this.setState({ numbancobenefmodt: "06073611" });
                }

            }
        }
    }
    //Modificar el pago total de colegiatura
    modificarPagTotColeg = (datos) => {
        var combmsmalert = "¿Está seguro que desea modificar los datos?"
        swal({
            title: "Advertencia",
            text: combmsmalert,
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    //----------------------------------------
                    //*Definir la fecha actual
                    var fecha = new Date();
                    var dia = fecha.getDate();
                    var mes = (fecha.getMonth() + 1);
                    var anio = fecha.getFullYear();
                    var fechaMatricula = `${anio}-${mes}-${dia}`;
                    //define mes 
                    var nombremes = mes + "";
                    var numeroCaracteresmes = nombremes.length;
                    if (numeroCaracteresmes == 1) {
                        var mesfecha = "0" + nombremes;
                    } else {
                        mesfecha = nombremes;
                    }
                    //define dia
                    var nombredia = dia + "";
                    var numeroCaracteresdia = nombredia.length;
                    if (numeroCaracteresdia == 1) {
                        var diafecha = "0" + nombredia;
                    } else {
                        diafecha = nombredia;
                    }
                    var fechaactualgeneradamod = anio + "-" + mesfecha + "-" + diafecha
                    //--------------------------------------------
                    var valorcancelar = 0;
                    /* if ((this.state.porcentaje_beca != 0)) {
                         valorcancelar = (this.state.valorcolegtotal - ((this.state.valorcolegtotal * this.state.porcentaje_beca) / 100));
                     }
                     else {
                         valorcancelar = (this.state.valorcolegtotal - ((this.state.valorcolegtotal * 10) / 100));
                     }*/

                    var cantidadPagomodtaux = parseFloat(this.state.cantidadPagomodt);
                    var valorcancelar = 2000;

                    if (cantidadPagomodtaux <= valorcancelar) {

                        var vidFileLength = $("#comprobantemodt")[0].files.length;
                        var cargarArchivo = 0;
                        var comprobantenuevo = "";
                        var auxbanbenef = "";
                        var auxctabanbenef = "";
                        if ((vidFileLength === 0)) {
                            cargarArchivo = 0;
                            comprobantenuevo = null;

                            if (this.state.idTipoTransaccionmodt == 3) {
                                auxbanbenef = "";
                                auxctabanbenef = "";
                            }
                            else {
                                auxbanbenef = this.state.bancobeneficariomodt;
                                auxctabanbenef = this.state.numbancobenefmodt;
                            }
                        } else {
                            cargarArchivo = 1;
                            comprobantenuevo = this.state.comprobantemodt[0];
                            auxbanbenef = this.state.bancobeneficariomodt;
                            auxctabanbenef = this.state.numbancobenefmodt;
                        }

                        //Validar el comprobante
                        var fileInput = document.getElementById('comprobantemodt');
                        var filePath = fileInput.value;
                        var allowedExtensions = /(.jpg|.jpeg|.png)$/i;
                        if ((!allowedExtensions.exec(filePath)) && (this.state.idTipoTransacciont != 3) && cargarArchivo == 1) {
                            swal({
                                text: "El archivo debe ser formato:.jpeg/.jpg/.png",
                                icon: "error",
                                button: "Aceptar",

                            });
                        }
                        else {

                            const f = new FormData();
                            f.append("url_comprobantetmod", comprobantenuevo);
                            f.append("num_depositotmod", this.state.numdepositomodt);
                            f.append("fecha_depositotmod", this.state.fechadepositomodt);
                            f.append("valor_canceladotmod", this.state.cantidadPagomodt);
                            f.append("cuenta_debito_transftmod", this.state.ctadebitotransfemodt);
                            f.append("nom_dueno_cta_transftmod", this.state.nomduenctadebittransfmodt);
                            f.append("cuenta_beneftmod", auxctabanbenef);
                            f.append("nombre_banco_beneftmod", auxbanbenef);
                            f.append("tipo_pagotmod", this.state.idTipoTransaccionmodt);
                            f.append("fecha_reg_deposttmod", fechaactualgeneradamod);
                            f.append("cargarchivotmod", cargarArchivo);
                            f.append("idpagototal", datos.id_pension_total);

                            const config = {
                                headers: { 'content-type': 'multipart/form-data' }
                            }
                            axios
                                .post(urlPagoTotalColegMod, f, config)

                                .then(response => {
                                    console.log(response)
                                    if (response.data.success == true) {

                                        this.setState({ estado: "Datos modificados correctamente" });
                                        swal({
                                            text: "Datos modificados correctamente",
                                            icon: "success",
                                            button: "Aceptar",

                                        });


                                        //Listar la tabla de pago total de colegiatura //26
                                        axios.post(urlPagoTotalColegReport, {
                                            idEstudianteDoc: cookie.get("idEstudiante"),
                                            idPeriodoActual: this.state.idPeriodo,

                                        })
                                            .then(respuesta => {
                                                if (respuesta.data.success == true) {
                                                    const pagtotalcoleg = respuesta.data.data;
                                                    this.setState({ listpagototal: pagtotalcoleg });
                                                }


                                            })


                                        this.setState({ modalPagoColegituraTotalMod: false });

                                    }
                                    else {

                                        if (response.data.status == "failed") {
                                            this.setState({ estado: "Formato del archivo incorrecto" });
                                            swal({
                                                text: "El archivo seleccionado no corresponde al formato: jpg o png",
                                                icon: "error",
                                                button: "Aceptar",
                                            });
                                        }
                                        else {
                                            this.setState({ estado: "Valores no registrados" });
                                            swal({
                                                text: "Valores no registrados",
                                                icon: "error",
                                                button: "Aceptar",

                                            });
                                        }
                                        this.setState({ modalPagoColegituraTotalMod: false });
                                    }

                                })
                                .catch(error => {
                                    console.log(error);

                                    this.setState({ estado: "Error No se pudo conectar con el servidor" });
                                    window.location.reload(true);

                                })
                        }
                    }
                    else {
                        swal({
                            text: "La cantidad ingresada supera al valor permitido",
                            icon: "error",
                            button: "Aceptar",
                        });
                    }

                } else {
                    swal({
                        text: "¡Haz denegado la acción!",
                        icon: "warning",
                        button: "Aceptar",
                    });
                }
            });


    }
    //Mostrar el  comprobante del pago total
    mostrarModalComprobanteTotalCuota = (registro) => {
        axios.post(rutaimagen, {
            nombrecomprobante: "colegiaturas/" + registro.comprobante_penst,
        })
            .then(res => {
                const ruta = res.data.data;
                this.setState({ ruta });
            })
        this.setState({ modalVisualComprobPagTotColeg: true, form: registro });
    }

    ocultarModVisualComprobPagTotColeg = () => {
        this.setState({ modalVisualComprobPagTotColeg: false });
    }


    render() {
        return (
            <div className="row animate__animated animate__fadeIn">
                <ol className="breadcrumb mb-4">
                    <li className="breadcrumb-item active">{ }</li>
                </ol>
                <div className="card shadow p-3 mb-5 bg-white rounded">
                    <div className="card-header back-istmas">
                        <i className="far fa-sticky-note"></i>
                        <b>-Colegiaturas </b>
                    </div>
                    <div className="card-body">
                        <form className="" style={{ padding: 10 }} id="create-course-form" >
                            <div className="row ">
                                <div className="col-12 col-sm-12  col-lg-5 col-xl-5 centrar" >
                                    <div className="mb-3">
                                        <div className="mb-3">
                                            <label className="form-label">Nombres:</label><br />
                                            <label className="form-label">{this.state.nombrescompletos}</label>
                                            <input type="hidden" name="per" onChange={this.handleChange} className="form-control" value={this.state.nuevope} placeholder="1999-01-01" required />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-6 col-sm-6  col-lg-5 col-xl-5 centrar" >
                                    <div className="mb-3">
                                        <div className="mb-3">
                                            <label className="form-label"><b> <i className="fa fa-calendar"></i> Período Académico</b></label>
                                            <select className="form-select" id="idPeriodo" name="idPeriodo" value={this.state.value} onChange={this.handleChangePeriodo} aria-label="Default select example">
                                                <option value="undefined" selected="true" disabled>Seleccione el periódo</option>
                                                {this.state.periodos.map(periodo => <option key={periodo.id_periodo_academico} value={periodo.id_periodo_academico} > {periodo.descripcion_periodo_academico}</option>)}
                                            </select>


                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-sm-12 col-lg-5 col-xl-5 centrar" >
                                    <div className="mb-3">
                                        <label className="form-label"></label><br />
                                        <label className="form-label">{this.state.nuevope}</label>
                                    </div>
                                </div>
                            </div>
                        </form>
                        <div className="alert alert-warning" role="alert" hidden={this.state.hidden}>
                            {this.state.estado}
                        </div>
                        <div className="card-body centrar " style={{ width: "100%" }}>
                            <div id="bottpagtotcolegocu">
                                <button id="agregar" type="button"
                                    className="btn  back-istmas centrar"
                                    style={{ margin: "5px", width: "170px" }}
                                    onClick={this.mostrarModalIngresarPagoColegT}
                                    disabled={(this.state.disabledbttptc) ? "disabled" : ""}
                                    hidden={this.state.hiddentabtot}
                                >
                                    <b>Pago Total</b>
                                </button>
                            </div>

                            <br />

                            <div class="table-responsive">
                                <table id="tbtotpagcoleg" name="tbtotpagcoleg" className="table  text-left" hidden={this.state.hiddentabtot} >
                                    <thead >
                                        <tr>
                                            <th scope="col">Forma de pago</th>
                                            <th scope="col">Fecha del depósito</th>
                                            <th scope="col">Valor cancelado</th>
                                            <th scope="col">Fecha de registro</th>
                                            <th scope="col">Estado</th>
                                            <th scope="col">Novedad</th>
                                            <th scope="col">Detalle</th>
                                            <th scope="col">Comprobante</th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.listpagototal.map(consola => (
                                            <tr key={consola.id_pension_total}>
                                                <td class="tipodepo">


                                                    {(() => {

                                                        //Paralelo
                                                        switch (consola.tipo_deposit_penst) {

                                                            case 1: return "DEPOSITO";
                                                            case 2: return "TRANSFERENCIA";
                                                            case 3: return "EFECTIVO";
                                                            default: return ""
                                                        }
                                                    })()}
                                                </td>
                                                <td class="fecha deposito">{consola.fecha_deposit_penst}</td>
                                                <td class="cantidad">${consola.cantidad_penst}</td>
                                                <td class="fecha regis">{consola.fecha_regis_depos_penst}</td>
                                                <td class="estado">
                                                    {(() => {
                                                        switch (consola.estado_penst) {
                                                            case 1: return "Enviado";
                                                            case 2: return "Aprobado";
                                                            case 3: return "Rechazado";
                                                            case 4: return "En proceso";
                                                            case 5: return "En proceso";
                                                            default: return ""
                                                        }
                                                    })()}
                                                </td>


                                                {(() => {

                                                    //Paralelo
                                                    if ((consola.detalle_penst == null)) {
                                                        return <td class="novedad"></td>;
                                                    }
                                                    else
                                                        return <td class="novedad">{consola.detalle_penst}</td>;

                                                })()}


                                                <td class="detalle">
                                                    <button onClick={() => this.mostrarModalPagoColegituraTotalMod(consola)} > <i className="puntero fas fa-pen" ></i></button>
                                                </td>
                                                <td class="comprobante">
                                                    <button onClick={() => this.mostrarModalComprobanteTotalCuota(consola)} > <i className=" puntero fas fa-solid fa-image" ></i></button>
                                                </td>

                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                            <div class="table-responsive">
                                <table id="tablacuotas" name="tablacuotas" className="table  text-left" hidden={this.state.hiddentabpar} >
                                    <thead >
                                        <tr>

                                            <th scope="col">Mes</th>
                                            <th scope="col">Cuota</th>
                                            <th scope="col">Estado</th>
                                            <th scope="col">Comprobante</th>
                                            <th scope="col">Detalle</th>
                                        </tr>
                                    </thead>
                                    <tbody>

                                        {this.state.listnombremes.map(consola => (
                                            <tr key={consola.nombremes}>
                                                <td class="#">{consola.nombremes}</td>


                                                <td class="cuota" >${consola.valorcuotDetall}</td>



                                                <td class="estado">{consola.estado}</td>

                                                <td class="Cargar">

                                                    {(() => {
                                                        //Paralelo
                                                        if ((consola.estado == "Completado")) {
                                                            return <button onClick={() => this.mostrarModalIngresarPagoColeg(consola)} disabled="true"><i class="puntero fas fa-solid fa-image"></i></button>
                                                        }
                                                        else
                                                            return <button onClick={() => this.mostrarModalIngresarPagoColeg(consola)} ><i class="puntero fas fa-solid fa-image"></i></button>

                                                    })()}
                                                </td>

                                                <td class="detalle">
                                                    <button onClick={() => this.mostrarModalReportAbonosCuota(consola)} > <i className=" fas fa-clipboard-list" ></i></button>  </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>

                        </div>
                        {/*Modal de pago total de colegiatura*/}
                        <Modal classname="modal-background" isOpen={this.state.modalVisualComprobPagTotColeg}>
                            <ModalHeader>

                                <h5>Comprobante</h5>
                            </ModalHeader>
                            <ModalBody>
                                <FormGroup>

                                    <div className="mb-3 centrar">
                                        <input name="imagen" type="image" src={this.state.ruta} align="center" alt="Comprobante" width='100%'
                                            height='100%' ></input>
                                    </div>

                                </FormGroup>
                            </ModalBody>
                            <ModalFooter>
                                <button class="btn btn-outline-primary" onClick={this.ocultarModVisualComprobPagTotColeg}>Salir</button>
                            </ModalFooter>
                        </Modal>

                        <Modal isOpen={this.state.modalPagoColegituraTotalMod} >
                            <ModalHeader>
                                <h5>Detalle</h5>
                                <b> Valor: </b>${this.state.valorCancelModalMT}
                            </ModalHeader>
                            <ModalBody>
                                <FormGroup>
                                    <div className="mb-3 centrar">
                                        <label className="form-label">Seleccionar la forma de pago</label>
                                        <select className="form-select" id="idTipoTransaccionmodt" name="idTipoTransaccionmodt" value={this.state.value} onChange={this.handleChangeFormaPagoEfectModT} aria-label="Default select example" autocomplete="off" required>
                                            <option value="">Seleccione</option>
                                            <option value="1">Depósito</option>
                                            <option value="2">Transferencia</option>
                                            <option value="3">Efectivo</option>
                                        </select>
                                        <label className="form-label">{this.state.enuncnumdepostingtmod}</label><br />
                                        <input type="text" id="numdepositomodt" name="numdepositomodt" onChange={this.handleChange} className="form-control" placeholder="" disabled={(this.state.disabledformpagdmodtt) ? "disabled" : ""} autocomplete="off" required />
                                        <label className="form-label">Fecha de depósito</label><br />
                                        <input type="date" id="fechadepositomodt" name="fechadepositomodt" onChange={this.handleChange} className="form-control" placeholder="" autocomplete="off" required />
                                        <label className="form-label">Ingresar la cantidad  de pago</label><br />
                                        <div class="input-group mb-3">
                                            <div class="input-group-prepend">
                                                <span class="input-group-text">$</span>
                                            </div>
                                            <input type="text" id="cantidadPagomodt" name="cantidadPagomodt" onChange={this.handleChange} className="form-control" placeholder="0.00" autocomplete="off" required />

                                        </div>

                                        <label className="form-label">#Cuenta que se debitó la transferencia</label><br />
                                        <input type="text" id="ctadebitotransfemodt" name="ctadebitotransfemodt" onChange={this.handleChange} className="form-control" placeholder="" disabled={(this.state.disabledctmodtt) ? "disabled" : ""} autocomplete="off" required />
                                        <label className="form-label">Nombre del dueño de la cuenta que se debitó la transferencia</label><br />
                                        <input type="text" id="nomduenctadebittransfmodt" name="nomduenctadebittransfmodt" onChange={this.handleChange} className="form-control" placeholder="" disabled={(this.state.disabledctmodtt) ? "disabled" : ""} autocomplete="off" required />
                                        <label className="form-label">Banco Beneficiario</label><br />
                                        <select className="form-select" id="bancobeneficariomodt" name="bancobeneficariomodt" value={this.state.value} onChange={this.handleChangeCuentaBeneficiarioModT} aria-label="Default select example" disabled={(this.state.disabledformpagdmodtt) ? "disabled" : ""}>
                                            <option value="1">PACIFICO</option>
                                            <option value="2">PICHINCHA-3257594104</option>
                                            <option value="3">PICHINCHA-2100271381</option>
                                            <option value="4">AUSTRO</option>

                                        </select>
                                        <label className="form-label">#Cuenta del Banco Beneficiario</label><br />
                                        <input type="text" id="numbancobenefmodt" name="numbancobenefmodt" onChange={this.handleChange} className="form-control" placeholder="" disabled={(this.state.disabledformpagdmodtt) ? "disabled" : ""} autocomplete="off" required />
                                        <label className="form-label">Subir el  comprobante</label><br />
                                        <input className="form-control" type="file" id="comprobantemodt" name="comprobantemodt" disabled={(this.state.disabledformpagdmodtt) ? "disabled" : ""} onChange={this.handleChangeComprobColegModT} autocomplete="off" />

                                    </div>
                                </FormGroup>
                            </ModalBody>
                            <ModalFooter>
                                {this.state.avisoestudpagtot}
                                <div id="bottmodiftot">
                                    <button class="btn btn-outline-primary" disabled={(this.state.disabledbttmodtot) ? "disabled" : ""} onClick={() => this.modificarPagTotColeg(this.state.form2)}  > Modificar</button>
                                </div>
                                <button class="btn btn-outline-primary" onClick={this.ocultarModalPagoColegTotalMod}>Salir</button>

                            </ModalFooter>
                        </Modal>


                        <Modal isOpen={this.state.modalPagoColegituraTotal}>
                            <ModalHeader>
                                Registro de pago total de colegiatura
                            </ModalHeader>
                            <ModalBody>
                                <FormGroup>
                                    <div className="mb-3 centrar">
                                        <b>Cantidad: </b>
                                        ${this.state.valorcolegtotalact}
                                        <br />
                                        <br />
                                        <label className="form-label">Seleccionar la forma de pago</label>
                                        <select className="form-select" id="idTipoTransacciont" name="idTipoTransacciont" value={this.state.value} onChange={this.handleChangeFormaPagoEfectT} aria-label="Default select example" autocomplete="off" required>
                                            <option value="">Seleccione</option>
                                            <option value="1">Depósito</option>
                                            <option value="2">Transferencia</option>
                                            <option value="3">Efectivo</option>
                                        </select>
                                        <label className="form-label">{this.state.enuncnumdepostingt}</label><br />
                                        <input type="text" id="numdepositot" name="numdepositot" onChange={this.handleChange} className="form-control" placeholder="" disabled={(this.state.disabledformpagdt) ? "disabled" : ""} autocomplete="off" required />
                                        <label className="form-label">Fecha de depósito</label><br />
                                        <input type="date" id="fechadepositot" name="fechadepositot" onChange={this.handleChange} className="form-control" placeholder="" autocomplete="off" required />
                                        <label className="form-label">Ingresar la cantidad  de pago</label><br />

                                        <div class="input-group mb-3">
                                            <div class="input-group-prepend">
                                                <span class="input-group-text">$</span>
                                            </div>
                                            <input type="text" id="cantidadPagot" name="cantidadPagot" onChange={this.handleChange} className="form-control" placeholder="0.00" autocomplete="off" required />

                                        </div>

                                        <label className="form-label">#Cuenta que se debitó la transferencia</label><br />
                                        <input type="text" id="ctadebitotransft" name="ctadebitotransft" onChange={this.handleChange} className="form-control" placeholder="" disabled={(this.state.disabledctt) ? "disabled" : ""} autocomplete="off" required />
                                        <label className="form-label">Nombre del dueño de la cuenta que se debitó la transferencia</label><br />
                                        <input type="text" id="nomduenioctadebittransft" name="nomduenioctadebittransft" onChange={this.handleChange} className="form-control" placeholder="" disabled={(this.state.disabledctt) ? "disabled" : ""} autocomplete="off" required />
                                        <label className="form-label">Banco Beneficiario</label><br />
                                        <select className="form-select" id="bancobeneficariot" name="bancobeneficariot" value={this.state.value} onChange={this.handleChangeCuentaBeneficiarioT} aria-label="Default select example" disabled={(this.state.disabledformpagdt) ? "disabled" : ""}>
                                            <option value="1">PACIFICO</option>
                                            <option value="2">PICHINCHA-3257594104</option>
                                            <option value="3">PICHINCHA-2100271381</option>
                                            <option value="4">AUSTRO</option>
                                        </select>
                                        <label className="form-label">#Cuenta del Banco Beneficiario</label><br />
                                        <input type="text" id="numbancobeneficariot" name="numbancobeneficariot" onChange={this.handleChange} className="form-control" placeholder="" disabled={(this.state.disabledformpagdt) ? "disabled" : ""} autocomplete="off" required />
                                        <label className="form-label">Subir el  comprobante</label><br />
                                        <input className="form-control" type="file" id="comprobantet" name="comprobantet" disabled={(this.state.disabledformpagdt) ? "disabled" : ""} onChange={this.handleChangeComprobColeg} autocomplete="off" />
                                    </div>
                                </FormGroup>
                            </ModalBody>
                            <ModalFooter>
                                <button class="btn btn-outline-primary" onClick={this.abonarColegiaturaTotal} > Aceptar</button>
                                <button class="btn btn-outline-primary" onClick={this.ocultarModalIngresoColegiaturaT}>Salir</button>
                            </ModalFooter>
                        </Modal>
                        {/*Modales de pagos parciales*/}

                        <Modal isOpen={this.state.modalPagoColegituraMes}>
                            <ModalHeader>
                                Registro de pago de colegiatura

                            </ModalHeader>
                            <ModalBody>
                                <FormGroup>
                                    <div className="mb-3 centrar">
                                        <input name="numcuota" id="numcuota" type="hidden" value={this.state.form.id} onChange={this.handleChange} ></input>
                                        <input name="nombremescuota" id="nombremescuota" type="hidden" value={this.state.form.nombremes} onChange={this.handleChange}></input>
                                        <input name="fechmaxpagocoleg" id="fechmaxpagocoleg" type="hidden" value={this.state.form.nombremes} onChange={this.handleChange}></input>
                                        <input name="recargopagocoleg" id="recargopagocoleg" type="hidden" value={this.state.form.nombremes} onChange={this.handleChange}></input>
                                        <label className="form-label"><h5>{this.state.form.nombremes}</h5></label>
                                        <br />
                                        <label className="form-label">Seleccionar la forma de pago</label>
                                        <select className="form-select" name="idTipoTransaccion" value={this.state.value} onChange={this.handleChangeFormaPagoEfect} aria-label="Default select example" autocomplete="off" required>
                                            <option value="">Seleccione</option>
                                            <option value="1">Depósito</option>
                                            <option value="2">Transferencia</option>
                                            <option value="3">Efectivo</option>
                                        </select>
                                        <label className="form-label">{this.state.enuncnumdeposting}</label><br />
                                        <input type="text" name="numdeposito" onChange={this.handleChange} className="form-control" placeholder="" disabled={(this.state.disabledformpagd) ? "disabled" : ""} autocomplete="off" required />
                                        <label className="form-label">Fecha de depósito</label><br />
                                        <input type="date" name="fechadeposito" onChange={this.handleChange} className="form-control" placeholder="" autocomplete="off" required />
                                        <label className="form-label">Ingresar la cantidad  de pago</label><br />
                                        <div class="input-group mb-3"><div class="input-group-prepend"><span class="input-group-text">$</span></div><input type="text" id="cantidadPago" name="cantidadPago" onChange={this.handleChange} className="form-control" placeholder="0.00" autocomplete="off" required /></div>
                                        <label className="form-label">#Cuenta que se debitó la transferencia</label><br />
                                        <input type="text" name="cuentadebitotransferencia" onChange={this.handleChange} className="form-control" placeholder="" disabled={(this.state.disabledct) ? "disabled" : ""} autocomplete="off" required />
                                        <label className="form-label">Nombre del dueño de la cuenta que se debitó la transferencia</label><br />
                                        <input type="text" name="nomdueniocuentdebittransf" onChange={this.handleChange} className="form-control" placeholder="" disabled={(this.state.disabledct) ? "disabled" : ""} autocomplete="off" required />
                                        <label className="form-label">Banco Beneficiario</label><br />
                                        <select className="form-select" id="bancobeneficario" name="bancobeneficario" value={this.state.value} onChange={this.handleChangeCuentaBeneficiario} aria-label="Default select example" disabled={(this.state.disabledformpagd) ? "disabled" : ""}>
                                            <option value="1">PACIFICO</option>
                                            <option value="2">PICHINCHA-3257594104</option>
                                            <option value="3">PICHINCHA-2100271381</option>
                                            <option value="4">AUSTRO</option>
                                        </select>
                                        <label className="form-label">#Cuenta del Banco Beneficiario</label><br />
                                        <input type="text" id="numbancobeneficario" name="numbancobeneficario" onChange={this.handleChange} className="form-control" placeholder="" disabled={(this.state.disabledformpagd) ? "disabled" : ""} autocomplete="off" required />
                                        <label className="form-label">Subir el  comprobante</label><br />
                                        <input className="form-control" type="file" id="comprobante" name="comprobante" disabled={(this.state.disabledformpagd) ? "disabled" : ""} onChange={this.handleChangeComprobColegIndi} autocomplete="off" />
                                    </div>
                                </FormGroup>
                            </ModalBody>
                            <ModalFooter>
                                <button class="btn btn-outline-primary" onClick={() => this.abonarColegiaturaMes(2)} > Aceptar</button>
                                <button class="btn btn-outline-primary" onClick={this.ocultarModalIngresoColegiatura}>Salir</button>
                            </ModalFooter>
                        </Modal>

                        <Modal size='lg' isOpen={this.state.modalReportAbonosCuotaEst}>
                            <ModalHeader>

                            </ModalHeader>
                            <ModalBody class="colormodal" >
                                <FormGroup>
                                    <input name="idestudiante" type="hidden" value=""></input>
                                    <input name="numcomprobnate" type="hidden" value=""></input>
                                    <label className="form-label"><h5>{this.state.form1.nombremes}</h5></label>
                                    <label className="form-label"></label>
                                    <div class="table-responsive">
                                        <table className="table table-bordered" id="tablacientificos" width="100%" >
                                            <thead>
                                                <tr>

                                                    <th scope="col">Forma de pago</th>
                                                    <th scope="col">Fecha del depósito</th>
                                                    <th scope="col">Valor cancelado</th>
                                                    <th scope="col">Fecha de registro</th>
                                                    <th scope="col">Estado</th>
                                                    <th scope="col">Novedad</th>
                                                    <th scope="col">Detalle</th>
                                                    <th scope="col">Comprobante</th>
                                                </tr>
                                            </thead>

                                            <tbody>

                                                {this.state.cuotascolegiaturam.map(consola => (
                                                    <tr key={consola.id_pension_abono_mes}>
                                                        <td>
                                                            {(() => {

                                                                //Paralelo
                                                                switch (consola.tipo_deposito_pens) {

                                                                    case 1: return "DEPOSITO";
                                                                    case 2: return "TRANSFERENCIA";
                                                                    case 3: return "EFECTIVO";
                                                                    default: return ""
                                                                }
                                                            })()}

                                                        </td>
                                                        <td>{consola.fecha_deposito_pens}</td>
                                                        <td>${consola.cantidad_abono_pens}

                                                        </td>

                                                        <td>{consola.fecha_regis_depos_pens}</td>
                                                        <td>

                                                            {(() => {

                                                                switch (consola.estado_abono_pens) {
                                                                    case 1: return "Enviado";
                                                                    case 2: return "Aprobado";
                                                                    case 3: return "Rechazado";
                                                                    case 4: return "En proceso";
                                                                    case 5: return "En proceso";
                                                                    default: return ""
                                                                }
                                                            })()}
                                                        </td>

                                                        <td>

                                                            {(() => {
                                                                if (consola.estado_abono_pens == 4 || consola.estado_abono_pens == 5) {
                                                                    return "";
                                                                }
                                                                else
                                                                    return consola.detalle_pens;

                                                            })()}


                                                        </td>

                                                        <td class="detalle">
                                                            <button onClick={() => this.mostrarModalPagoColegituraMesMod(consola)} > <i className="puntero fas fa-pen" ></i></button>  </td>
                                                        <td class="comprobante">
                                                            <button onClick={() => this.mostrarModalComprobanteAbonoCuota(consola)} > <i className=" puntero fas fa-solid fa-image" ></i></button>  </td>

                                                    </tr>
                                                ))}
                                                <tr>
                                                    <td colspan="2">
                                                        <b>
                                                            SUMA
                                                        </b >
                                                    </td>
                                                    <td >
                                                        <b>
                                                            ${this.state.sumabonaprobcuot}
                                                        </b >

                                                    </td>

                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </FormGroup>
                            </ModalBody>
                            <ModalFooter>
                                <button class="btn btn-outline-primary" onClick={this.ocultarModalReportAbonosCuota}>Salir</button>
                            </ModalFooter>
                        </Modal>

                        <Modal classname="modal-background" isOpen={this.state.modalVisualComprobante}>
                            <ModalHeader>

                                <h5>Comprobante</h5>
                            </ModalHeader>
                            <ModalBody>
                                <FormGroup>
                                    <input name="id_pagos_matriculas" type="hidden" value={this.state.form.id_pago_abono_matricula_periodo}></input>

                                    <div className="mb-3 centrar">
                                        <input name="imagen" type="image" src={this.state.ruta} align="center" alt="Comprobante" width='100%'
                                            height='100%' ></input>
                                    </div>

                                </FormGroup>
                            </ModalBody>
                            <ModalFooter>
                                <button class="btn btn-outline-primary" onClick={this.ocultarModalVisualComprobante}>Salir</button>
                            </ModalFooter>
                        </Modal>
                        <Modal isOpen={this.state.modalPagoColegituraMesMod} >
                            <ModalHeader>
                                <h5>Detalle</h5>
                            </ModalHeader>
                            <ModalBody>
                                <FormGroup>

                                    <div className="mb-3 centrar">
                                        <input name="numcuota" id="numcuota" type="hidden" value={this.state.form.id} onChange={this.handleChange} ></input>
                                        <input name="nombremescuota" id="nombremescuota" type="hidden" value={this.state.form.nombremes} onChange={this.handleChange}></input>
                                        <input name="fechmaxpagocoleg" id="fechmaxpagocoleg" type="hidden" value={this.state.form.nombremes} onChange={this.handleChange}></input>
                                        <input name="recargopagocoleg" id="recargopagocoleg" type="hidden" value={this.state.form.nombremes} onChange={this.handleChange}></input>
                                        <label className="form-label"><h5>{this.state.form.nombremes}</h5></label>
                                        <br />
                                        <label className="form-label">Seleccionar la forma de pago</label>
                                        <select className="form-select" id="idTipoTransaccionmod" name="idTipoTransaccionmod" value={this.state.value} onChange={this.handleChangeFormaPagoEfectMod} aria-label="Default select example" autocomplete="off" required>
                                            <option value="">Seleccione</option>
                                            <option value="1">Depósito</option>
                                            <option value="2">Transferencia</option>
                                            <option value="3">Efectivo</option>
                                        </select>
                                        <label className="form-label">{this.state.enunciadonumdeposit}</label><br />
                                        <input type="text" id="numdepositomod" name="numdepositomod" onChange={this.handleChange} className="form-control" placeholder="" disabled={(this.state.disabledformpagdmod) ? "disabled" : ""} autocomplete="off" required />
                                        <label className="form-label">Fecha de depósito</label><br />
                                        <input type="date" id="fechadepositomod" name="fechadepositomod" onChange={this.handleChange} className="form-control" placeholder="" autocomplete="off" required />
                                        <label className="form-label">Ingresar la cantidad  de pago</label><br />
                                        <div class="input-group mb-3"><div class="input-group-prepend"><span class="input-group-text">$</span></div><input type="text" id="cantidadPagomod" name="cantidadPagomod" onChange={this.handleChange} className="form-control" placeholder="0.00" autocomplete="off" required /></div>
                                        <label className="form-label">#Cuenta que se debitó la transferencia</label><br />
                                        <input type="text" id="cuentadebitotransferenciamod" name="cuentadebitotransferenciamod" onChange={this.handleChange} className="form-control" placeholder="" disabled={(this.state.disabledctmod) ? "disabled" : ""} autocomplete="off" required />
                                        <label className="form-label">Nombre del dueño de la cuenta que se debitó la transferencia</label><br />
                                        <input type="text" id="nomdueniocuentdebittransfmod" name="nomdueniocuentdebittransfmod" onChange={this.handleChange} className="form-control" placeholder="" disabled={(this.state.disabledctmod) ? "disabled" : ""} autocomplete="off" required />
                                        <label className="form-label">Banco Beneficiario</label><br />
                                        <select className="form-select" id="bancobeneficariomod" name="bancobeneficariomod" value={this.state.value} onChange={this.handleChangeCuentaBeneficiarioMod} aria-label="Default select example" disabled={(this.state.disabledformpagdmod) ? "disabled" : ""}>
                                            <option value="1">PACIFICO</option>
                                            <option value="2">PICHINCHA-3257594104</option>
                                            <option value="3">PICHINCHA-2100271381</option>
                                            <option value="4">AUSTRO</option>
                                        </select>
                                        <label className="form-label">#Cuenta del Banco Beneficiario</label><br />
                                        <input type="text" id="numbancobeneficariomod" name="numbancobeneficariomod" onChange={this.handleChange} className="form-control" placeholder="" disabled={(this.state.disabledformpagdmod) ? "disabled" : ""} autocomplete="off" required />
                                        <label className="form-label">Subir el  comprobante</label><br />
                                        <input className="form-control" type="file" id="comprobantemod" name="comprobantemod" disabled={(this.state.disabledformpagdmod) ? "disabled" : ""} onChange={this.handleChangeComprobColegIndiMod} autocomplete="off" />
                                    </div>
                                </FormGroup>
                            </ModalBody>
                            <ModalFooter>
                                {this.state.avisoestud}
                                <div id="bottmodif">
                                    <button class="btn btn-outline-primary" disabled={(this.state.disabledbttmod) ? "disabled" : ""} onClick={() => this.modificarAbonoCuotaMes(this.state.form)}  > Modificar</button>
                                </div>
                                <button class="btn btn-outline-primary" onClick={this.ocultarModalPagoColegituraMesMod}>Salir</button>
                            </ModalFooter>
                        </Modal>

                    </div>
                </div>
            </div>
        )
    }
}
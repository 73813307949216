import axios from 'axios'
import { ApiUrl } from '../components/services/ApiRest'
import React, { Component } from 'react'
import { Redirect, Route } from 'react-router-dom'
import Cookies from 'universal-cookie'
import Contenido from '../components/contenido/Contenido'
import FooterDashboard from '../components/footer/FooterDashboard'
import NavbarGeneral from '../components/navbar/NavbarGeneral'
import SidebarDocente from '../components/sidebar/SidebarDocente'
import ReporteAsignaturas from '../components/docente/ListarAsignaturasDoc'
import VerPerfil from '../components/docente/perfil/VerPerfil'
import ListarEstudiantesAsig from '../components/docente/titulacion/ListarEstudiantesAsig'

const cookie = new Cookies();
const urlAdministrativo=ApiUrl +"admins/"; 
const idAdministrativo = cookie.get("idAdministrativo"); 
const config = {
    headers: { Authorization: `Bearer ${cookie.get("rol")}` }
};

export default class RutasDocente extends Component {
    componentDidMount() {
        axios.get(urlAdministrativo+idAdministrativo+"?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71" )
          .then(res => {
            const estudiante = res.data;
            this.setState({ estudiante });
            
            cookie.set('nombres',estudiante.nombres_administrativo,{path:"/"})
            cookie.set('apellidos',estudiante.apellidos_administrativo,{path:"/"})
          })
    }

    render() {

        if(!cookie.get("log")){
            return(<Redirect to="/login" />);
        }

        if(cookie.get("rol") !== "administrativo"){
        }
        return (
            <>
                <NavbarGeneral />
                <div id="layoutSidenav" >
                    <SidebarDocente />
                    <div id="layoutSidenav_content">
                        <main>
                            <div className="container-fluid">
                                <Contenido />
                                <Route path="/docente/ListarAsignaturasDoc" component={ReporteAsignaturas} />
                                <Route path="/docente/VerPerfilDocente" component={VerPerfil} />
                                <Route path="/docente/listarestudiantestitul" component={ListarEstudiantesAsig} />
                            </div>
                        </main>
                        <FooterDashboard />
                    </div>
                </div>
            </>
        )
    }
}

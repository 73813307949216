import axios from 'axios';
import ReactPaginate from 'react-paginate'
import $ from 'jquery';
import React, { Component, useState } from 'react'
import Cookies from 'universal-cookie';
import "../../services/ApiRest";
import swal from 'sweetalert';
import { ApiUrl } from '../../services/ApiRest';
import { Table, Button, Container, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, ListGroupItemText, Alert } from 'reactstrap';

const urlListarCarrAsigTipoTitu = ApiUrl + "carrerasasigtipoTitu" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const urlListarTiposTitulacion = ApiUrl + "listartipotitul" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const urlIngresarTipoTitulacion = ApiUrl + "ingresartipotitul" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const urlDatosTipoTitulacion = ApiUrl + "datostipotitul" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const urlModificarTipoTitulacion = ApiUrl + "modificartipotitul" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const urlListarTodosTiposTitulacion = ApiUrl + "listartodostipotitu" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const urlListarCarreras = ApiUrl + "listarcarreras" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";

const cookie = new Cookies();
let listaauxiliar = [];
const itemsPerPage = 10;
export default class ListTiposTitulacion extends Component {

    constructor(props) {
        super(props)
        this.state = {
            listatipostitultodos: [],
            listCarrTipoTitulMod: [],
            carrera: [],
            //variables para paginacion
            currentItems: null,
            pageCount: 0,
            itemOffset: 0,
            count: 0,
        }
        this.handleChange = this.handleChange.bind(this);
        this.escFunction = this.escFunction.bind(this);
    }
    escFunction(event) {
        if (event.key === "Escape") {
            this.setState({ modalIngresarTipoTitulacion: false });
            this.setState({ modalModTipoTitulacion: false });
        }
    }
    handleChange = async (e) => {
        await this.setState({ [e.target.name]: e.target.value })
        this.setState({ estado: "" });
    
    }

    componentDidMount() {
        document.addEventListener("keydown", this.escFunction, true);
        //Listar las carreras
        axios.get(urlListarCarreras)
            .then(res => {
                const carrera = res.data;
                this.setState({ carrera });
            })
    }

    //BUSQUEDA......
    filter(event) {
        console.log(event.target.value);
        //obtener datos de buscar
        var text = event.target.value
        //obtener datos del array
        const data = this.state.estud
        var newData = data.filter(function (item) {
            //variable del objeto
            const itemDataNom = item.nom_tipo_titulacion.toUpperCase();
            const itemDataDescrip = item.descrip_tipo_titulacion.toUpperCase();
            const campo = itemDataNom + " " + itemDataDescrip;
            //variable del buscador
            const textData = text.toUpperCase()
            //return itemData.indexOf(textData)> -1
            return campo.indexOf(textData) > -1

        })

        if (text == "") {
            newData = this.state.EstudianteBackup;
        }
        this.setState({
            currentItems: newData,
            textBuscar: text,
        })

    }
    //FUNCION DE PAGINACION POR  MEDIO DE UN CLIC
    handlePageClick = (event) => {

        const newOffset = (event.selected * itemsPerPage) % listaauxiliar.length;
        this.setState((state) => {
            return { itemOffset: newOffset }
        });
        const endOffset = newOffset + itemsPerPage;
        this.setState({ currentItems: listaauxiliar.slice(newOffset, endOffset) });
        this.setState({ EstudianteBackup: listaauxiliar.slice(newOffset, endOffset) });
        this.setState({ pageCount: Math.ceil(listaauxiliar.length / itemsPerPage) });
        this.setState((state) => {
            // Importante: lee state en vez de this.state al actualizar.
            return { itemOffset: newOffset }
        });
    }
    //FINDE FUNCION DE PAGINACION
    handleChangeTiposTitulacion = async (e) => {
        await this.setState({ [e.target.name]: e.target.value })
        axios
            .post(urlListarTiposTitulacion, {
                idCarreraTipoT: this.state.idCarrera
            })
            .then(response => {

                if (response.data.success == true) {
                    listaauxiliar = [];
                    this.setState({ currentItems: [] });
                    listaauxiliar = response.data.data;
                    const estud = response.data.data;
                    this.setState((state) => {
                        return { estud: estud }
                    }
                    );
                    const EstudianteBackup = estud;

                    this.setState((state) => {
                        return { EstudianteBackup: EstudianteBackup }
                    });
                    //PAGINACION
                    this.setState({ itemOffset: 0 });
                    const endOffset = this.state.itemOffset + itemsPerPage;
                    this.setState({ currentItems: listaauxiliar.slice(this.state.itemOffset, endOffset) });
                    this.setState({ EstudianteBackup: listaauxiliar.slice(this.state.itemOffset, endOffset) });
                    this.setState({ pageCount: Math.ceil(listaauxiliar.length / itemsPerPage) });
                    this.setState((state) => {
                        return { itemOffset: state.itemOffset + endOffset }
                    });
                    //FIN  D DE LA PAGINACION
                } else {
                    this.setState({ error: "No existe estudiantes" });
                    swal({
                        text: "¡No existe datos para visualizar!",
                        icon: "info",
                        button: "Aceptar",
                    });
                    this.setState({ currentItems: [] });
                }
            })
    }
    handleChangeCarrera = async (e) => {
        await this.setState({ [e.target.name]: e.target.value });

        axios
            .post(urlListarTiposTitulacion, {
                idCarreraTipoT: this.state.idCarrera
            })
            .then(respuesta => {
                if (respuesta.data.success == true) {
                    listaauxiliar = respuesta.data.data;
                    const estud = respuesta.data.data;
                    this.setState((state) => {
                        return { estud: estud }
                    }
                    );
                    const EstudianteBackup = estud;

                    this.setState((state) => {
                        return { EstudianteBackup: EstudianteBackup }
                    });
                    //PAGINACION
                    this.setState({ itemOffset: 0 });
                    const endOffset = this.state.itemOffset + itemsPerPage;
                    this.setState({ currentItems: listaauxiliar.slice(this.state.itemOffset, endOffset) });
                    this.setState({ EstudianteBackup: listaauxiliar.slice(this.state.itemOffset, endOffset) });
                    this.setState({ pageCount: Math.ceil(listaauxiliar.length / itemsPerPage) });
                    this.setState((state) => {
                        return { itemOffset: state.itemOffset + endOffset }
                    });
                    //FIN  D DE LA PAGINACION
                } else {
                    this.setState({ error: "No existe estudiantes" });
                    swal({
                        text: "¡No existe estudiantes para modificar la matrícula!",
                        icon: "info",
                        button: "Aceptar",
                    });
                    this.setState({ currentItems: [] });
                }
            })
    }
    //Modal de Ingreso
    mostrarModalIngresoTiposTitulacion = (registro) => {
        this.setState({ modalIngresarTipoTitulacion: true});
    }
    //Ocultar modal de ingreso
    ocultarModalIngresarTipoTitul = () => {
        this.setState({ modalIngresarTipoTitulacion: false });
    }

    ocultarModalModificartDatosTipoTitulacion = () => {
        this.setState({ modalModTipoTitulacion: false });
    }

    ingresarDatosTipoTitulacion = () => {
        //Crear la lista de roles seleccionados
        let carreraselect = [];
        $("input:checkbox[class=checkbox-carrtipotituing]:checked").each(function () {
            var carrseleccionado = $(this).parent().parent().find('td').eq(0).text();
            let fila = {
                idCarr: carrseleccionado
            };
            carreraselect.push(fila);
        });
        var gsoncarrerasselect = JSON.stringify(carreraselect);
        axios
            .post(urlIngresarTipoTitulacion, {

                nombrett: this.state.nombretipo,
                descripciontt: this.state.descriptipo,
                estadott: 1,
                listcarreras: gsoncarrerasselect,
            })
            .then(response => {
                if (response.data.success === true) {
                    swal({
                        text: "Datos procesados correctamente",
                        icon: "success",
                        button: "Aceptar",
                    });
                    axios
                        .post(urlListarTiposTitulacion, {
                            idCarreraTipoT: this.state.idCarrera
                        })
                        .then(response => {
                            if (response.data.success == true) {
                                listaauxiliar = response.data.data;
                                const estud = response.data.data;
                                this.setState((state) => {
                                    return { estud: estud }
                                }
                                );
                                const EstudianteBackup = estud;

                                this.setState((state) => {
                                    return { EstudianteBackup: EstudianteBackup }
                                });
                                //PAGINACION
                                this.setState({ itemOffset: 0 });
                                const endOffset = this.state.itemOffset + itemsPerPage;
                                this.setState({ currentItems: listaauxiliar.slice(this.state.itemOffset, endOffset) });
                                this.setState({ EstudianteBackup: listaauxiliar.slice(this.state.itemOffset, endOffset) });
                                this.setState({ pageCount: Math.ceil(listaauxiliar.length / itemsPerPage) });
                                this.setState((state) => {
                                    return { itemOffset: state.itemOffset + endOffset }
                                });
                                //FIN  D DE LA PAGINACION
                            } else {
                                this.setState({ error: "No existe estudiantes" });
                                swal({
                                    text: "¡No existe estudiantes para modificar la matrícula!",
                                    icon: "info",
                                    button: "Aceptar",
                                });
                                this.setState({ currentItems: [] });
                            }
                        })
                    this.setState({ modalIngresarTipoTitulacion: false });
                } else {
                    swal({
                        text: "Datos no actualizados",
                        icon: "error",
                        button: "Aceptar",
                    });
                    this.setState({ modalIngresarEntidad: false });
                }
            })
            .catch(error => {

                this.setState({ error: "No existe conexión con el servidor" });
                swal({
                    text: "No existe conexión con el servidor",
                    icon: "error",
                    button: "Aceptar",

                });
            });
    }

    //Modal de mostrar datos de un tipo de titulación
    mostrarModalDatosTipoTitulacion = async (e) => {
        await this.setState({ [e.target.name]: e.target.value });
        this.setState({ listCarrTipoTitulMod: [] });
          //Listar todos los tipos de titulación
          axios.get(urlListarTodosTiposTitulacion)
          .then(response => {
              var listatipostitul = response.data.data;
              this.setState({ listatipostitultodos: listatipostitul });
          })
        this.setState({ modalModTipoTitulacion: true });
    }

    mostrarDatosTipoTitulacion = async (e) => {
        await this.setState({ [e.target.name]: e.target.value });
      
        //Datos de un tipo de titulación
        axios.post(urlDatosTipoTitulacion, {
            idTipoTitulacion: this.state.idtipomod,
        })
            .then(response => {
                const TipoTitulacion = response.data.data;
                $(function () {
                    $("#nombretipomod").val(TipoTitulacion[0].nom_tipo_titulacion);
                    $("#descriptipomod").val(TipoTitulacion[0].descrip_tipo_titulacion);
                    $("#idestadomod").val(TipoTitulacion[0].estado_tipo_titulacion);
                });
                this.setState({ nombretipomod: TipoTitulacion[0].nom_tipo_titulacion });
                this.setState({ descriptipomod: TipoTitulacion[0].descrip_tipo_titulacion });
                this.setState({ idestadomod: TipoTitulacion[0].estado_tipo_titulacion });
                //Lista las carreras asignadas a un tipo de titulación
                axios.post(urlListarCarrAsigTipoTitu, {
                    idTipoTitu: this.state.idtipomod
                })
                    .then(res => {
                        const listaCarrTitulacionMod = res.data.data;
                        this.setState({ listCarrTipoTitulMod: listaCarrTitulacionMod });
                    })
            })
        this.setState({ modalModTipoTitulacion: true });

    }
    //modificar
    modificarDatosTipoTitulacion = () => {
        
        //Crear la lista de roles seleccionados
        let carreraselect = [];
        // $("input[type=checkbox]:checked").each(function () {
        $("input:checkbox[class=checkbox-carrtipotitumod]:checked").each(function () {

            var carrseleccionado = $(this).parent().parent().find('td').eq(0).text();
            let fila = {
                idCarrMod: carrseleccionado
            };
            carreraselect.push(fila);
        });
        var gsoncarrerasselect = JSON.stringify(carreraselect);

        axios
            .post(urlModificarTipoTitulacion, {
                idttmod: this.state.idtipomod,
                nombrettmod: this.state.nombretipomod,
                descripcionttmod: this.state.descriptipomod,
                estadomod: this.state.idestadomod,
                listcarrerasmod: gsoncarrerasselect,
            })
            .then(response => {
                if (response.data.success === true) {
                    swal({
                        text: "Datos procesados correctamente",
                        icon: "success",
                        button: "Aceptar",
                    });
                    axios
                        .post(urlListarTiposTitulacion, {
                            idCarreraTipoT: this.state.idCarrera
                        })
                        .then(response => {


                            if (response.data.success == true) {
                                listaauxiliar = response.data.data;
                                const estud = response.data.data;
                                this.setState((state) => {
                                    return { estud: estud }
                                }
                                );
                                const EstudianteBackup = estud;

                                this.setState((state) => {
                                    return { EstudianteBackup: EstudianteBackup }
                                });
                                //PAGINACION
                                this.setState({ itemOffset: 0 });
                                const endOffset = this.state.itemOffset + itemsPerPage;
                                this.setState({ currentItems: listaauxiliar.slice(this.state.itemOffset, endOffset) });
                                this.setState({ EstudianteBackup: listaauxiliar.slice(this.state.itemOffset, endOffset) });
                                this.setState({ pageCount: Math.ceil(listaauxiliar.length / itemsPerPage) });
                                this.setState((state) => {
                                    return { itemOffset: state.itemOffset + endOffset }
                                });
                                //FIN  D DE LA PAGINACION
                            } else {
                                this.setState({ error: "No existe estudiantes" });
                                swal({
                                    text: "¡No existe estudiantes para modificar la matrícula!",
                                    icon: "info",
                                    button: "Aceptar",
                                });
                                this.setState({ currentItems: [] });
                            }
                        })
                    this.setState({ modalModTipoTitulacion: false });
                } else {
                    swal({
                        text: "Datos no actualizados",
                        icon: "error",
                        button: "Aceptar",
                    });
                    this.setState({ modalModTipoTitulacion: false });
                }
            })
            .catch(error => {

                this.setState({ error: "No existe conexión con el servidor" });
                swal({
                    text: "No existe conexión con el servidor",
                    icon: "error",
                    button: "Aceptar",

                });
            });
    }
    render() {
        return (

            <div className="row animate__animated animate__fadeIn">
                <ol className="breadcrumb mb-4">
                    <li className="breadcrumb-item active">{ }</li>
                </ol>
                <div className="card shadow p-3 mb-5 bg-white rounded">
                    <div className="card-header back-istmas">
                        <i className="far fa-sticky-note"></i>
                        <b>Tipos de Titulación</b>
                    </div>
                    <div className="card-body">
                        <div className="row" >
                            <div className="col-12 col-sm-12 col-lg-5 col-xl-5 centrar" >

                            </div>
                        </div>
                        <div className="row ">
                            <div className="col-12 col-sm-12 col-lg-5 col-xl-5 centrar" >
                                <div className="mb-3">
                                    <label className="form-label"><b> <i className="fa-magnifying-glass"></i> Buscar:</b></label>
                                    <input type="text" class="form-control col-md-12" value={this.state.text} placeholder="Buscar..." onChange={(text) => this.filter(text)} />

                                </div>
                            </div>
                            <div className="col-12 col-sm-12  col-lg-5 col-xl-5 centrar" >
                                <div className="mb-3">

                                    <label className="form-label"><b>Carrera</b></label>
                                    <select className="form-select" id="idCarrera" name="idCarrera" value={this.state.value} onChange={this.handleChangeCarrera} aria-label="Default select example">
                                        <option value="undefined" selected="true" disabled>Seleccione una carrera</option>
                                        {this.state.carrera.map(person => <option key={person.id_carrera} value={person.id_carrera} > {person.descripcion_carrera}</option>)}
                                        <option value="todos">Todos</option>
                                    </select>

                                </div>
                            </div>
                        </div>
                        <div className="row ">
                            <div className="col-12 col-sm-12 col-lg-11 col-xl-11 centrar" >
                                <div className="card text-center ">
                                    <div className="card-header ">
                                        ESTUDIANTE
                                    </div>
                                    <div className="card-body centrar " style={{ width: "100%" }}>
                                        <div class="table-responsive">
                                            <table className="table  text-left"  >
                                                <thead >
                                                    <tr >
                                                        <th scope="col">NOMBRE</th>
                                                        <th scope="col">DESCRIPCIÓN</th>
                                                        <th scope="col">CARRERA</th>
                                                    </tr>
                                                </thead>
                                                <tbody>

                                                    {this.state.currentItems &&
                                                        this.state.currentItems.map((consola) => (
                                                            <tr class="list-afiliados" key={this.state.estud.id_tipo_titulacion}  >
                                                                <td class="oculto"> {consola.id_tipo_titulacion}</td>
                                                                <td >{consola.nom_tipo_titulacion}</td>
                                                                <td >{consola.descrip_tipo_titulacion}</td>
                                                                <td >{consola.descripcion_carrera}</td>
                                                                <td >

                                                                </td>
                                                                <td>
                                                                </td>

                                                            </tr>
                                                        ))}
                                                </tbody>
                                            </table>
                                            <Modal isOpen={this.state.modalIngresarTipoTitulacion}>
                                                <ModalHeader>

                                                    Ingresar un tipo de titulación
                                                </ModalHeader>
                                                <ModalBody class="colormodal" >
                                                    <FormGroup>

                                                        <label className="form-label">Nombre</label>
                                                        <input type="text" id="nombretipo" name="nombretipo" className="form-control" onChange={this.handleChange} required />
                                                        <label className="form-label">Descripción</label>
                                                        <input type="text" id="descriptipo" name="descriptipo" className="form-control" onChange={this.handleChange} required />
                                                        <label className="form-label">Seleccione la carrera a la que pertenece</label>
                                                        <div class="table-responsive">
                                                            <table id="tablacarreras" className="table  text-left" >
                                                                <thead >
                                                                    <tr>
                                                                        <th scope="col">Carrera</th>
                                                                        <th scope="col">Seleccionar</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {this.state.carrera.map(consola => (
                                                                        <tr key={consola.id_carrera}>
                                                                            <td class="idCarreraSelect oculto" >{consola.id_carrera}</td>

                                                                            <td class="descriCarreraSelect" >{consola.descripcion_carrera}</td>
                                                                            <td class="selectCarrera">
                                                                                <input disabled={(this.state.disablmodmat) ? "disabled" : ""} name={"checkcarr" + consola.id_carrera} id={"checkcarr" + consola.id_carrera} type="checkbox" class="checkbox-carrtipotituing" />
                                                                                {(() => {
                                                                                    $('#checkcarr' + consola.id_carrera).checked = false;
                                                                                })()}
                                                                            </td>
                                                                        </tr>
                                                                    ))}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </FormGroup>
                                                </ModalBody>
                                                <ModalFooter>
                                                    <button class="btn btn-outline-primary" onClick={() => this.ingresarDatosTipoTitulacion()} >Aceptar</button>
                                                    <button class="btn btn-outline-primary" onClick={this.ocultarModalIngresarTipoTitul}>Salir</button>
                                                </ModalFooter>
                                            </Modal>

                                        </div>
                                    </div>
                                    <div class="table-responsive">
                                        <ReactPaginate
                                            nextLabel="next >"
                                            onPageChange={this.handlePageClick}
                                            pageRangeDisplayed={5}
                                            pageCount={this.state.pageCount}
                                            marginPagesDisplayed={2}
                                            previousLabel="< previous"
                                            pageClassName="page-item"
                                            pageLinkClassName="page-link"
                                            previousClassName="page-item"
                                            previousLinkClassName="page-link"
                                            nextClassName="page-item"
                                            nextLinkClassName="page-link"
                                            breakLabel="..."
                                            breakClassName="page-item"
                                            breakLinkClassName="page-link"
                                            containerClassName="pagination"
                                            activeClassName="active"
                                            renderOnZeroPageCount={null}
                                        />
                                    </div>
                                    <div className="text-center">
                                        <button id="agregar" type="button"
                                            className="btn  back-istmas centrar"
                                            style={{ margin: "5px", width: "170px" }}
                                            onClick={e =>
                                                this.mostrarModalIngresoTiposTitulacion(e)
                                            }
                                        >
                                            <b>Agregar</b>
                                        </button>
                                        <button id="agregar" type="button"
                                            className="btn  back-istmas centrar"
                                            style={{ margin: "5px", width: "170px" }}
                                            onClick={e =>
                                                this.mostrarModalDatosTipoTitulacion(e)
                                            }
                                        >
                                            <b>Modificar</b>
                                        </button>

                                    </div>


                                </div>
                            </div>
                        </div>

                        <div className="row  ">
                            <div className="mb-3 ">
                                <Modal classname="modal-background" isOpen={this.state.modalModTipoTitulacion}>
                                    <ModalHeader>
                                        <h5></h5>
                                        Modificar los datos del tipo de titulación
                                    </ModalHeader>
                                    <ModalBody>
                                        <FormGroup>
                                            <label className="form-label">Seleccione el tipo de titulación</label>
                                            <select className="form-select" id="idtipomod" name="idtipomod" value={this.state.value} onChange={this.mostrarDatosTipoTitulacion} aria-label="Default select example">
                                                <option value="undefined" selected="true" disabled>Seleccione el periódo</option>


                                                {this.state.listatipostitultodos.map(person => <option key={person.id_tipo_titulacion} value={person.id_tipo_titulacion} >{person.nom_tipo_titulacion}</option>)}

                                            </select>
                                            <label className="form-label">Nombre</label>
                                            <input type="text" id="nombretipomod" name="nombretipomod" className="form-control" onChange={this.handleChange} required />
                                            <label className="form-label">Descripción</label>
                                            <input type="text" id="descriptipomod" name="descriptipomod" className="form-control" onChange={this.handleChange} required />
                                            <label className="form-label">Estado</label>
                                            <select className="form-select" id="idestadomod" name="idestadomod" value={this.state.value} onChange={this.handleChange} aria-label="Default select example" disabled={(this.state.disabledformpagmodd) ? "disabled" : ""} >
                                                <option value="0" >Deshabilitado</option>
                                                <option value="1" >Habilitado</option>
                                            </select>
                                            <div className="mb-3">
                                                <label className="form-label">Seleccione el rol</label>
                                                <div class="table-responsive">
                                                    <table id="tablacarrtipomod" className="table  text-left" >
                                                        <thead >
                                                            <tr>
                                                                <th scope="col">Carrera</th>
                                                                <th scope="col">Seleccionar</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {this.state.listCarrTipoTitulMod.map(consola => (
                                                                <tr key={consola.id_carrera}>
                                                                    <td class="idCarreraMod oculto">{consola.id_carrera}</td>
                                                                    <td class="decripCarreraMod" >{consola.descripcion_carr}</td>
                                                                    <td class="selectcarreraMod">
                                                                        <input name={"checkmod" + consola.id_carrera} id={"checkmod" + consola.id_carrera} type="checkbox" class="checkbox-carrtipotitumod" />
                                                                        {(() => {

                                                                            if (this.state.modalModTipoTitulacion == true) {
                                                                                if (consola.estado == 1) {
                                                                                    $(function () {
                                                                                        $('#checkmod' + consola.id_carrera)[0].checked = true;
                                                                                    })
                                                                                }
                                                                                else {
                                                                                    if (consola.estado == 0) {
                                                                                        $(function () {
                                                                                            $('#checkmod' + consola.id_carrera)[0].checked = false;
                                                                                        })
                                                                                    }

                                                                                }
                                                                            }
                                                                        })()}

                                                                    </td>
                                                                </tr>
                                                            ))}

                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </FormGroup>
                                    </ModalBody>
                                    <ModalFooter>
                                        <button class="btn btn-outline-primary" onClick={() => this.modificarDatosTipoTitulacion(this.state.form2)} > Modificar</button>
                                        <button class="btn btn-outline-primary" onClick={this.ocultarModalModificartDatosTipoTitulacion}>Salir</button>
                                    </ModalFooter>
                                </Modal>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

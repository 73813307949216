import axios from 'axios';
import ReactPaginate from 'react-paginate'
import $ from 'jquery';
import React, { Component, useState } from 'react'
import Cookies from 'universal-cookie';
import "../../services/ApiRest";
import swal from 'sweetalert';
import { ApiUrl } from '../../services/ApiRest';
import { Table, Button, Container, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, ListGroupItemText, Alert } from 'reactstrap';

const urlListarTiposTitulacionHab = ApiUrl + "listartipotitulhabil" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const urlListarCalifiTiposTitulacion = ApiUrl + "listarcalifitipotitul" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const urlListaRolesAdm = ApiUrl + "listarolesadm" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const urlIngresarTipoCalificacion = ApiUrl + "ingtipocalificacion" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const urlListarTipoTitulacionCalif = ApiUrl + "listtipotitulasigcalif" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const urlModificarTipoCalificacion = ApiUrl + "modtipocalificacion" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const urlEliminarTipoCalificacion = ApiUrl + "elimtipocalificacion" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";


const cookie = new Cookies();
let listaauxiliar = [];
const itemsPerPage = 10;
export default class ListTiposCalificaciones extends Component {

    constructor(props) {
        super(props)
        this.state = {
            listTiposTitulacion: [],
            listroles: [],
            listTipoTitulacionCalif: [],

            //variables para paginacion
            currentItems: null,
            pageCount: 0,
            itemOffset: 0,
            count: 0,
        }
        this.handleChange = this.handleChange.bind(this);
        this.escFunction = this.escFunction.bind(this);
    }
    escFunction(event) {
        if (event.key === "Escape") {
            this.setState({ modalIngresarTipoCalificacion: false });
            this.setState({ modalModTipoCalificacion: false });
        }
    }
    handleChange = async (e) => {
        await this.setState({ [e.target.name]: e.target.value })
        this.setState({ estado: "" });

    }

    componentDidMount() {
        document.addEventListener("keydown", this.escFunction, true);
        //Listar los tipos de titulación habilitados
        axios.get(urlListarTiposTitulacionHab)
            .then(response => {
                const listTiposTitulacion = response.data.data;
                this.setState({ listTiposTitulacion });
            })
        axios.get(urlListaRolesAdm)
            .then(response => {
                const listroles = response.data.data;

                this.setState({ listroles });
            })
    }


    //BUSQUEDA......
    filter(event) {
        console.log(event.target.value);
        //obtener datos de buscar
        var text = event.target.value
        //obtener datos del array
        const data = this.state.estud
        var newData = data.filter(function (item) {
            //variable del objeto
            const itemDataNom = item.nombre_calif_doc_titu.toUpperCase();
            const itemDataDescrip = item.descripcion_rol.toUpperCase();
            const campo = itemDataNom + " " + itemDataDescrip;
            //variable del buscador
            const textData = text.toUpperCase()
            //return itemData.indexOf(textData)> -1
            return campo.indexOf(textData) > -1

        })

        if (text == "") {
            newData = this.state.EstudianteBackup;
        }
        this.setState({
            currentItems: newData,
            textBuscar: text,
        })

    }
    //FUNCION DE PAGINACION POR  MEDIO DE UN CLIC
    handlePageClick = (event) => {

        const newOffset = (event.selected * itemsPerPage) % listaauxiliar.length;
        this.setState((state) => {
            return { itemOffset: newOffset }
        });
        const endOffset = newOffset + itemsPerPage;
        this.setState({ currentItems: listaauxiliar.slice(newOffset, endOffset) });
        this.setState({ EstudianteBackup: listaauxiliar.slice(newOffset, endOffset) });
        this.setState({ pageCount: Math.ceil(listaauxiliar.length / itemsPerPage) });
        this.setState((state) => {
            // Importante: lee state en vez de this.state al actualizar.
            return { itemOffset: newOffset }
        });
    }
    //FINDE FUNCION DE PAGINACION
    
    handleChangeTiposTitulacion = async (e) => {
        await this.setState({ [e.target.name]: e.target.value });
        axios
            .post(urlListarCalifiTiposTitulacion, {
                idTipoTitulacionCalif: this.state.idTipoTitutacion
            })
            .then(respuesta => {
                if (respuesta.data.success == true) {
                    listaauxiliar = respuesta.data.data;
                    const estud = respuesta.data.data;
                    this.setState((state) => {
                        return { estud: estud }
                    }
                    );
                    const EstudianteBackup = estud;
                    this.setState((state) => {
                        return { EstudianteBackup: EstudianteBackup }
                    });
                    //PAGINACION
                    this.setState({ itemOffset: 0 });
                    const endOffset = this.state.itemOffset + itemsPerPage;
                    this.setState({ currentItems: listaauxiliar.slice(this.state.itemOffset, endOffset) });
                    this.setState({ EstudianteBackup: listaauxiliar.slice(this.state.itemOffset, endOffset) });
                    this.setState({ pageCount: Math.ceil(listaauxiliar.length / itemsPerPage) });
                    this.setState((state) => {
                        return { itemOffset: state.itemOffset + endOffset }
                    });
                    //FIN  D DE LA PAGINACION
                } else {
                    this.setState({ error: "No existe estudiantes" });
                    swal({
                        text: "¡No existe información!",
                        icon: "info",
                        button: "Aceptar",
                    });
                    this.setState({ currentItems: [] });
                }
            })
    }
    //Modal de Ingreso
    mostrarModalIngresoTiposCalif = (registro) => {
        this.setState({ modalIngresarTipoCalificacion: true });
    }
    //Ocultar modal de ingreso
    ocultarModalIngresarTipoCalif = () => {
        this.setState({ modalIngresarTipoCalificacion: false });
    }

    ocultarModalModiftDatosTipoCalif = () => {
        this.setState({ modalModTipoCalificacion: false });
    }

    ingresarTipoCalificacion = () => {
        let tipotitulselect = [];
        $("input:checkbox[class=checkbox-califtipotitulacion]:checked").each(function () {
            var tipotitulselecc = $(this).parent().parent().find('td').eq(0).text();
            let fila = {
                idTipoTitu: tipotitulselecc
            };
            tipotitulselect.push(fila);
        });
        var gsontipotituselect = JSON.stringify(tipotitulselect);
        axios
            .post(urlIngresarTipoCalificacion, {

                nomCalificacion: this.state.nombretipocalif,
                estadotc: 1,
                idRol: this.state.idroltipocalifi,
                listtipostitulacion: gsontipotituselect,
            })
            .then(response => {
                if (response.data.success === true) {
                    swal({
                        text: "Datos procesados correctamente",
                        icon: "success",
                        button: "Aceptar",
                    });
                    axios
                        .post(urlListarCalifiTiposTitulacion, {
                            idTipoTitulacionCalif: this.state.idTipoTitutacion
                        })
                        .then(respuesta => {
                            if (respuesta.data.success == true) {
                                listaauxiliar = respuesta.data.data;
                                const estud = respuesta.data.data;
                                this.setState((state) => {
                                    return { estud: estud }
                                }
                                );
                                const EstudianteBackup = estud;
                                this.setState((state) => {
                                    return { EstudianteBackup: EstudianteBackup }
                                });
                                //PAGINACION
                                this.setState({ itemOffset: 0 });
                                const endOffset = this.state.itemOffset + itemsPerPage;
                                this.setState({ currentItems: listaauxiliar.slice(this.state.itemOffset, endOffset) });
                                this.setState({ EstudianteBackup: listaauxiliar.slice(this.state.itemOffset, endOffset) });
                                this.setState({ pageCount: Math.ceil(listaauxiliar.length / itemsPerPage) });
                                this.setState((state) => {
                                    return { itemOffset: state.itemOffset + endOffset }
                                });
                                //FIN  D DE LA PAGINACION
                            } else {
                                this.setState({ error: "No existe estudiantes" });
                                swal({
                                    text: "¡No existe información!",
                                    icon: "info",
                                    button: "Aceptar",
                                });
                                this.setState({ currentItems: [] });
                            }
                        })

                    this.setState({ modalIngresarTipoCalificacion: false });
                } else {
                    swal({
                        text: "Datos no actualizados",
                        icon: "error",
                        button: "Aceptar",
                    });
                    this.setState({ modalIngresarEntidad: false });
                }
            })
            .catch(error => {

                this.setState({ error: "No existe conexión con el servidor" });
                swal({
                    text: "No existe conexión con el servidor",
                    icon: "error",
                    button: "Aceptar",

                });
            });
    }

    //Modal de mostrar datos de un tipo de calificación
    mostrarModalDatosTipoCalificacion = (consola) => {
        $(function () {
            $("#idtipocalifmod").val(consola.id_calif_doc_titu);
            $("#nombretipocalifmod").val(consola.nombre_calif_doc_titu);
            $("#idroltipocalifimod").val(consola.id_rol);
        });

        this.setState({ idtipocalifmod: consola.id_calif_doc_titu });
        this.setState({ nombretipocalifmod: consola.nombre_calif_doc_titu });
        this.setState({ idroltipocalifimod: consola.id_rol });
        //Lista los tipos de titulación asignadas a una calificación

        axios.post(urlListarTipoTitulacionCalif, {
            idTipoCalif: consola.id_calif_doc_titu
        })
            .then(res => {
                var listTipoTitulacionCalif = res.data.data;
                this.setState({ listTipoTitulacionCalif });
            })
        this.setState({ modalModTipoCalificacion: true });
    }
    //modificar
    modificarTipoCalificacion = () => {

        //Crear la lista de roles seleccionados
        let tipotitulmodselect = [];
        // $("input[type=checkbox]:checked").each(function () {
        $("input:checkbox[class=checkbox-califtipotitulacionmod]:checked").each(function () {

            var tiposeleccionado = $(this).parent().parent().find('td').eq(0).text();
            let fila = {
                idTipoMod: tiposeleccionado
            };
            tipotitulmodselect.push(fila);
        });

        var gsontipootitulselect = JSON.stringify(tipotitulmodselect);

        axios
            .post(urlModificarTipoCalificacion, {
                idtipoCalificacion: this.state.idtipocalifmod,
                nombretcalif: this.state.nombretipocalifmod,
                idroltcalif: this.state.idroltipocalifimod,
                listtipotitumod: gsontipootitulselect,
            })
            .then(response => {
                if (response.data.success === true) {
                    swal({
                        text: "Datos procesados correctamente",
                        icon: "success",
                        button: "Aceptar",
                    });
                    axios
                        .post(urlListarCalifiTiposTitulacion, {
                            idTipoTitulacionCalif: this.state.idTipoTitutacion
                        })
                        .then(respuesta => {
                            if (respuesta.data.success == true) {
                                listaauxiliar = respuesta.data.data;
                                const estud = respuesta.data.data;
                                this.setState((state) => {
                                    return { estud: estud }
                                }
                                );
                                const EstudianteBackup = estud;
                                this.setState((state) => {
                                    return { EstudianteBackup: EstudianteBackup }
                                });
                                //PAGINACION
                                this.setState({ itemOffset: 0 });
                                const endOffset = this.state.itemOffset + itemsPerPage;
                                this.setState({ currentItems: listaauxiliar.slice(this.state.itemOffset, endOffset) });
                                this.setState({ EstudianteBackup: listaauxiliar.slice(this.state.itemOffset, endOffset) });
                                this.setState({ pageCount: Math.ceil(listaauxiliar.length / itemsPerPage) });
                                this.setState((state) => {
                                    return { itemOffset: state.itemOffset + endOffset }
                                });
                                //FIN  D DE LA PAGINACION
                            } else {
                                this.setState({ error: "No existe estudiantes" });
                                swal({
                                    text: "¡No existe información!",
                                    icon: "info",
                                    button: "Aceptar",
                                });
                                this.setState({ currentItems: [] });
                            }
                        })

                    this.setState({ modalModTipoCalificacion: false });
                } else {
                    swal({
                        text: "Datos no actualizados",
                        icon: "error",
                        button: "Aceptar",
                    });
                    this.setState({ modalModTipoCalificacion: false });
                }
            })
            .catch(error => {

                this.setState({ error: "No existe conexión con el servidor" });
                swal({
                    text: "No existe conexión con el servidor",
                    icon: "error",
                    button: "Aceptar",

                });
            });
    }
    //Eliminar el tipo de titulación
    eliminarTipoCalificacion = (datos) => {

        var combmsmalert = "¿Está seguro que desea eliminar?";
        swal({
            title: "Advertencia",
            text: combmsmalert,
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {

                    axios
                        .post(urlEliminarTipoCalificacion, {
                            idTipoCalificacion: datos.id_calif_doc_titu,
                        })
                        .then(response => {
                            if (response.data.success === true) {
                                swal({
                                    text: "Datos eliminados correctamente",
                                    icon: "success",
                                    button: "Aceptar",
                                });
                                //Inicio Reporte
                                axios
                                    .post(urlListarCalifiTiposTitulacion, {
                                        idTipoTitulacionCalif: this.state.idTipoTitutacion
                                    })
                                    .then(respuesta => {
                                        if (respuesta.data.success == true) {
                                            listaauxiliar = respuesta.data.data;
                                            const estud = respuesta.data.data;
                                            this.setState((state) => {
                                                return { estud: estud }
                                            }
                                            );
                                            const EstudianteBackup = estud;
                                            this.setState((state) => {
                                                return { EstudianteBackup: EstudianteBackup }
                                            });
                                            //PAGINACION
                                            this.setState({ itemOffset: 0 });
                                            const endOffset = this.state.itemOffset + itemsPerPage;
                                            this.setState({ currentItems: listaauxiliar.slice(this.state.itemOffset, endOffset) });
                                            this.setState({ EstudianteBackup: listaauxiliar.slice(this.state.itemOffset, endOffset) });
                                            this.setState({ pageCount: Math.ceil(listaauxiliar.length / itemsPerPage) });
                                            this.setState((state) => {
                                                return { itemOffset: state.itemOffset + endOffset }
                                            });
                                            //FIN  D DE LA PAGINACION
                                        } else {
                                            this.setState({ error: "No existe estudiantes" });
                                            swal({
                                                text: "¡No existe información!",
                                                icon: "info",
                                                button: "Aceptar",
                                            });
                                            this.setState({ currentItems: [] });
                                        }
                                    })
                                //Fin de Reporte
                            } else {
                                swal({
                                    text: "Datos no eliminados",
                                    icon: "error",
                                    button: "Aceptar",
                                });
                                // this.setState({ modalRegistroPagoMatricula: false });
                            }
                        })
                        .catch(error => {
                            this.setState({ error: "No existe conexión con el servidor" });
                            swal({
                                text: "No existe conexión con el servidor",
                                icon: "error",
                                button: "Aceptar",
                            });
                        });
                } else {
                    swal({
                        text: "¡Haz denegado la acción!",
                        icon: "warning",
                        button: "Aceptar",
                    });
                }
            });

    }
    render() {
        return (

            <div className="row animate__animated animate__fadeIn">
                <ol className="breadcrumb mb-4">
                    <li className="breadcrumb-item active">{ }</li>
                </ol>
                <div className="card shadow p-3 mb-5 bg-white rounded">
                    <div className="card-header back-istmas">
                        <i className="far fa-sticky-note"></i>
                        <b>Tipos de Calificaciones</b>
                    </div>
                    <div className="card-body">
                        <div className="row" >
                            <div className="col-12 col-sm-12 col-lg-5 col-xl-5 centrar" >

                            </div>
                        </div>
                        <div className="row ">
                            <div className="col-12 col-sm-12 col-lg-5 col-xl-5 centrar" >
                                <div className="mb-3">
                                    <label className="form-label"><b> <i className="fa-magnifying-glass"></i> Buscar:</b></label>
                                    <input type="text" class="form-control col-md-12" value={this.state.text} placeholder="Buscar..." onChange={(text) => this.filter(text)} />

                                </div>
                            </div>
                            <div className="col-12 col-sm-12  col-lg-5 col-xl-5 centrar" >
                                <div className="mb-3">

                                    <label className="form-label"><b>Tipo de Titulación</b></label>
                                    <select className="form-select" id="idTipoTitutacion" name="idTipoTitutacion" value={this.state.value} onChange={this.handleChangeTiposTitulacion} aria-label="Default select example">
                                        <option value="undefined" selected="true" disabled>Seleccione un tipo de titulación</option>
                                        {this.state.listTiposTitulacion.map(person => <option key={person.id_tipo_titulacion} value={person.id_tipo_titulacion} > {person.nom_tipo_titulacion}</option>)}
                                        <option value="todos">Todos</option>
                                    </select>

                                </div>
                            </div>
                        </div>
                        <div className="row ">
                            <div className="col-12 col-sm-12 col-lg-6 col-xl-6 centrar" >
                                <div className="card text-center ">
                                    <div className="card-header ">
                                        CALIFICACIONES
                                    </div>
                                    <div className="card-body centrar " style={{ width: "100%" }}>
                                        <div class="table-responsive">
                                            <table className="table  text-left"  >
                                                <thead >
                                                    <tr >
                                                        <th scope="col">NOMBRE</th>
                                                        <th scope="col">ROL</th>
                                                        <th scope="col">MODIFICAR</th>
                                                        <th scope="col">ELIMINAR</th>
                                                    </tr>
                                                </thead>
                                                <tbody>

                                                    {this.state.currentItems &&
                                                        this.state.currentItems.map((consola) => (
                                                            <tr class="list-afiliados" key={consola.id_calif_doc_titu}  >
                                                                <td class="oculto"> {consola.id_calif_doc_titu}</td>
                                                                <td >{consola.nombre_calif_doc_titu}</td>

                                                                <td >
                                                                    {consola.descripcion_rol}
                                                                </td>
                                                                <td>
                                                                    <button onClick={() => this.mostrarModalDatosTipoCalificacion(consola)}  > <i className="fas fa-clipboard-list" ></i></button>
                                                                </td>
                                                                <td class="eliminar">
                                                                    <button onClick={() => this.eliminarTipoCalificacion(consola)} > <i class="fa fa-trash"></i></button>

                                                                </td>


                                                            </tr>
                                                        ))}
                                                </tbody>
                                            </table>
                                            <Modal size='xs' isOpen={this.state.modalIngresarTipoCalificacion}>
                                                <ModalHeader>

                                                    Ingresar un tipo de calificación
                                                </ModalHeader>
                                                <ModalBody class="colormodal" >
                                                    <FormGroup>

                                                        <label className="form-label"><b>Nombre</b></label>
                                                        <input type="text" id="nombretipocalif" name="nombretipocalif" className="form-control" onChange={this.handleChange} required />
                                                        <label className="form-label"><b>Roles</b></label>
                                                        <select className="form-select" id="idroltipocalifi" name="idroltipocalifi" value={this.state.value} onChange={this.handleChange} aria-label="Default select example">
                                                            <option value="undefined" selected="true" disabled>Seleccione una carrera</option>
                                                            {this.state.listroles.map(person => <option key={person.id_rol} value={person.id_rol} >{person.descripcion_rol}</option>)}

                                                        </select>
                                                        <br />
                                                        Seleccione el tipo de titulación

                                                        <div class="container">
                                                            <div class="row">

                                                                <div class="table-responsive">
                                                                    <table id="tablacarreras" className="table  text-left" >
                                                                        <thead >
                                                                            <tr>
                                                                                <th scope="col">Tipo de titulación</th>
                                                                                <th scope="col">Seleccionar</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            {this.state.listTiposTitulacion.map(consola => (
                                                                                <tr key={consola.id_tipo_titulacion}>
                                                                                    <td class="oculto" >{consola.id_tipo_titulacion}</td>

                                                                                    <td class="" >{consola.nom_tipo_titulacion}</td>
                                                                                    <td class="">
                                                                                        <input disabled={(this.state.disablmodmat) ? "disabled" : ""} name={"checktipot" + consola.id_tipo_titulacion} id={"checktipot" + consola.id_tipo_titulacion} type="checkbox" class="checkbox-califtipotitulacion" />
                                                                                        {(() => {
                                                                                            $('#checktipot' + consola.id_tipo_titulacion).checked = false;
                                                                                        })()}
                                                                                    </td>
                                                                                </tr>
                                                                            ))}
                                                                        </tbody>
                                                                    </table>
                                                                </div>


                                                            </div>
                                                        </div>

                                                    </FormGroup>
                                                </ModalBody>
                                                <ModalFooter>
                                                    <button class="btn btn-outline-primary" onClick={() => this.ingresarTipoCalificacion()} >Aceptar</button>
                                                    <button class="btn btn-outline-primary" onClick={this.ocultarModalIngresarTipoCalif}>Salir</button>
                                                </ModalFooter>
                                            </Modal>

                                        </div>
                                    </div>
                                    <div class="table-responsive">
                                        <ReactPaginate
                                            nextLabel="next >"
                                            onPageChange={this.handlePageClick}
                                            pageRangeDisplayed={5}
                                            pageCount={this.state.pageCount}
                                            marginPagesDisplayed={2}
                                            previousLabel="< previous"
                                            pageClassName="page-item"
                                            pageLinkClassName="page-link"
                                            previousClassName="page-item"
                                            previousLinkClassName="page-link"
                                            nextClassName="page-item"
                                            nextLinkClassName="page-link"
                                            breakLabel="..."
                                            breakClassName="page-item"
                                            breakLinkClassName="page-link"
                                            containerClassName="pagination"
                                            activeClassName="active"
                                            renderOnZeroPageCount={null}
                                        />
                                    </div>
                                    <div className="text-center">
                                        <button id="agregar" type="button"
                                            className="btn  back-istmas centrar"
                                            style={{ margin: "5px", width: "170px" }}
                                            onClick={e =>
                                                this.mostrarModalIngresoTiposCalif(e)
                                            }
                                        >
                                            <b>Agregar</b>
                                        </button>


                                    </div>


                                </div>
                            </div>
                        </div>

                        <div className="row  ">
                            <div className="mb-3 ">


                                <Modal classname="modal-background" isOpen={this.state.modalModTipoCalificacion}>
                                    <ModalHeader>
                                        Modificar un tipo de calificación
                                    </ModalHeader>
                                    <ModalBody class="colormodal" >
                                        <FormGroup>
                                            <input type="hidden" id="idtipocalifmod" name="idtipocalifmod" className="form-control" onChange={this.handleChange} required />

                                            <label className="form-label"><b>Nombre</b></label>
                                            <input type="text" id="nombretipocalifmod" name="nombretipocalifmod" className="form-control" onChange={this.handleChange} required />
                                            <label className="form-label"><b>Roles</b></label>
                                            <select className="form-select" id="idroltipocalifimod" name="idroltipocalifimod" value={this.state.value} onChange={this.handleChange} aria-label="Default select example">
                                                <option value="undefined" selected="true" disabled>Seleccione una carrera</option>
                                                {this.state.listroles.map(person => <option key={person.id_rol} value={person.id_rol} >{person.descripcion_rol}</option>)}

                                            </select>
                                            <br />
                                            Seleccione el tipo de titulación

                                            <div class="container">
                                                <div class="row">

                                                    <div class="table-responsive">
                                                        <table id="tablacarreras" className="table  text-left" >
                                                            <thead >
                                                                <tr>
                                                                    <th scope="col">Tipo de titulación</th>
                                                                    <th scope="col">Seleccionar</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {this.state.listTipoTitulacionCalif.map(consola => (
                                                                    <tr key={consola.id_tipo_titulacion}>
                                                                        <td class="oculto" >{consola.id_tipo_titulacion}</td>

                                                                        <td class="" >{consola.nom_tipo_titulacion}</td>
                                                                        <td class="selectTipoTitulMod">
                                                                            <input name={"checktipotitulcalifmod" + consola.id_tipo_titulacion} id={"checktipotitulcalifmod" + consola.id_tipo_titulacion} type="checkbox" class="checkbox-califtipotitulacionmod" />
                                                                            {(() => {

                                                                                if (this.state.modalModTipoCalificacion == true) {
                                                                                    if (consola.estado == 1) {
                                                                                        $(function () {
                                                                                            $('#checktipotitulcalifmod' + consola.id_tipo_titulacion)[0].checked = true;
                                                                                        })
                                                                                    }
                                                                                    else {
                                                                                        if (consola.estado == 0) {
                                                                                            $(function () {
                                                                                                $('#checktipotitulcalifmod' + consola.id_tipo_titulacion)[0].checked = false;
                                                                                            })
                                                                                        }

                                                                                    }
                                                                                }
                                                                            })()}

                                                                        </td>
                                                                    </tr>
                                                                ))}

                                                            </tbody>
                                                        </table>
                                                    </div>


                                                </div>
                                            </div>
                                        </FormGroup>
                                    </ModalBody>
                                    <ModalFooter>
                                        <button class="btn btn-outline-primary" onClick={this.modificarTipoCalificacion} > Modificar</button>
                                        <button class="btn btn-outline-primary" onClick={this.ocultarModalModiftDatosTipoCalif}>Salir</button>
                                    </ModalFooter>
                                </Modal>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
